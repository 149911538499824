import React, { useEffect, useState } from "react";
import breakpoints from "assets/theme/base/breakpoints";
import {setURLParam} from "util/UtilHelper";
import { getDownlinkRequest, postUserPreference } from "util/APIHelper";
import Grid from "@mui/material/Grid";
import {Card} from "@mui/material";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import { columnDefs, downlinkTableProcess } from "layouts/downlink/tableUtil";
import WebSocketService from "model/WebSocketService";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import useCooldown from "util/hooks/useCooldown";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function Downlink() {
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const params = new URLSearchParams(location.search);
  const toggle = params.get("toggle");

  const [downlinks, setDownlinks] = useState([])
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("downlink_status_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });
  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("downlink_status_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "downlink_status_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "DownlinkTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  useEffect(() => {
    if (toggle === "queued") {
      setTabValue(1)
    } else if (toggle === "completed") {
      setTabValue(2)
    } else {
      setTabValue(0)
    }
  }, [toggle]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);
    const newToggleValue = newValue === 1 ? "queued" : newValue === 2 ? "completed" : "all";
    setURLParam("toggle", newToggleValue)
  };

  useEffect(() => {
    getData()
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true);
    }
    try {
      if (selectedSites.value.length>0) {
        const site = selectedSites.value.map(e => e.id)
        const response = await getDownlinkRequest('2000-01-01T13:00:00Z', '2030-01-01T13:00:00Z', site.join(","), site.includes(-1))
        setDownlinks(downlinkTableProcess(response.content??[]));
      } else {
        setDownlinks([]);
      }
    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
    if (!isMounted) {
      setIsMounted(true)
    }
  }
  const throttledGetData = useCooldown(() => {
    getData(true);
  }, 1000);

  const toggleComponent = () => (
    <Grid item xs={12} md={6} lg={5} sx={{ mr: "auto" }}>
      <Card>
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
            <Tab label="All" icon={<Icon fontSize="small" > density_small </Icon>} />
            <Tab label="Queued" icon={<Icon fontSize="small"> pending </Icon>} />
            <Tab label="Completed" icon={<Icon fontSize="small"> check_circle </Icon>} />
          </Tabs>
        </AppBar>
      </Card>
    </Grid>
  )

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={2}>
        {toggleComponent()}
        <Grid item xs={12}>
          <MRTable
            data={downlinks.filter(row => {
              switch (`${tabValue}`) {
                case '1': return ["Queued", "Partially Completed"].includes(row.status);
                case '2': return ["Success", "Fail"].includes(row.status);
                default: return true;
              }
            })}
            onRefresh={getData}
            columns={columnDefs}
            isLoading={loading}
            isRefetching={wsLoading}
            onColumnStateChanged={columnStateCallback}
            initialColumnOrder={columnState.order}
            initialColumnPinning={columnState.pinning}
            initialColumnVisibility={columnState.visibility}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default Downlink;
