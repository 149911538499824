import PropTypes from "prop-types";
import MDBox from "components/MDBase/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";
import {useEffect, useState} from "react";
import {v4 as uuidv4} from "uuid";
import MDTypography from "components/MDBase/MDTypography";
import { FixedSizeList as List } from 'react-window';
import {debounce} from "lodash";
import {ListItemButton} from "@mui/material";
import MDBadge from "components/MDBase/MDBadge";

function UploadedMeterList({uploadedMeters, onClick, endpoints}) {
  const [search, setSearch] = useState("");

  // Searching
  const [filteredMeters, setFilteredMeters] = useState(uploadedMeters);

  const debouncedHandleSearch = debounce((value) => {
    const searchTerm = value.toLowerCase();
    const newFilteredMeters = uploadedMeters.filter((item) => (
      item.meterSerialNumber.toLowerCase().includes(searchTerm) ||
      item.endpoint.toLowerCase().includes(searchTerm)
    ));
    setFilteredMeters(newFilteredMeters);
  }, 300);

  const handleSearch = (value) => {
    setSearch(value);
    debouncedHandleSearch(value);
  };

  useEffect(() => {
    setFilteredMeters(uploadedMeters)
  }, [uploadedMeters]);

  const Row = ({ index, style, data }) => {
    let item = data[index];
    item.index=index;
    return (
      <ListItemButton
        onClick={()=>{onClick(item)}}
        key={uuidv4()}
        style={style}
        sx={{ borderBottom: "1px solid lightgray" }}
      >
        <Grid container>
          <Grid item xs={12}>
            <MDTypography
              sx={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
              fontWeight="bold"
              verticalAlign="bottom"
              variant="body2"
              color="text"
            >
              {item.meterSerialNumber}
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <MDTypography verticalAlign="top" variant="caption" color="text">
              {item.endpoint}
            </MDTypography>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex">
              {
                endpoints.some(x => x.endpoint === item.endpoint)?(
                  <MDBadge sx={{marginRight: "0.5rem"}} size="xs" variant="contained" color="info" badgeContent="edit" container />
                ):(
                  <MDBadge sx={{marginRight: "0.5rem"}} size="xs" variant="contained" color="success" badgeContent="new" container />
                )
              }
              {
                item.status === 0 ? (
                  <MDBadge size="xs" variant="contained" color="error" badgeContent="inactive" container />
                ) : null
              }
            </MDBox>
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };
  // endpoints.some(x => x.endpoint:)

  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        meterSerialNumber: PropTypes.string.isRequired,
        endpoint: PropTypes.string.isRequired,
        status: PropTypes.number.isRequired,
        index: PropTypes.number.isRequired
      })
    ).isRequired
  };


  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MDInput
          placeholder="Search..."
          value={search}
          size="small"
          fullWidth
          onChange={({ currentTarget }) => {
            handleSearch(currentTarget.value);
          }}
        />

        <MDTypography variant="body2" color="text" fontWeight="light">
          {uploadedMeters.length} Selected Meter{uploadedMeters.length>1?"s":""}
        </MDTypography>
      </Grid>
      <Grid item xs={12}>
        <MDBox sx={{ maxHeight: '350px', overflowY: 'auto' }}>
          <List
            height={350} // Adjust as needed
            width="100%"
            itemSize={107}
            itemCount={filteredMeters.length}
            itemData={filteredMeters}
          >
            {Row}
          </List>
        </MDBox>
      </Grid>
    </Grid>
  );
}

UploadedMeterList.propTypes = {
  onClick: PropTypes.func.isRequired,
  uploadedMeters: PropTypes.array.isRequired,
  endpoints: PropTypes.array.isRequired
}

export default UploadedMeterList;