import MDBox from "components/MDBase/MDBox";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import MDTypography from "components/MDBase/MDTypography";
import MDButton from "components/MDBase/MDButton";
import { allSites } from "signals/group_signals";
import ContentCollapse from "components/ContentCollapse";
import SiteColor from "./components/siteColor";


function GroupSiteTab({handleSubmit, handleDelete, preferences}) {
  const [isLoading, setLoading] = useState(false);
  const [gCollapse, setGCollapse] = useState(false);
  const [sCollapse, setSCollapse] = useState(false);
  const [groups, setGroups] = useState([]);
  const [sites, setSites] = useState([]);

  useEffect(() => {
    const tempSites = allSites.value.filter(obj=>obj.id !== -1).map((item)=>({
      id: item.id,
      description:item.description,
      color: "#1A73E8",
      defaultColor: "#1A73E8"
    }));
    const tempGroups = [];
    allSites.value.filter(obj=>obj.id !== -1).forEach(site => {
      if (!tempGroups.some(obj => obj.id === site.groupId)) {
        tempGroups.push({
          id: site.groupId,
          description: site.groupDescription,
          color: "#1A73E8",
          defaultColor: "#1A73E8"
        });
      }
    });
    setSites(tempSites);
    setGroups(tempGroups);
  }, []);

  useEffect(() => {
    if (preferences.length > 0) {
      try {
        const json = JSON.parse(preferences[0].value);
        const tempSites = allSites.value.filter(obj=>obj.id !== -1).map((item)=>({
          id: item.id,
          description:item.description,
          color: json.sites.find(site => site.id === item.id)?.color || "#1A73E8",
          defaultColor: json.sites.find(site => site.id === item.id)?.color || "#1A73E8"
        }));
        const tempGroups = [];
        allSites.value.filter(obj=>obj.id !== -1).forEach(site => {
          if (!tempGroups.some(obj => obj.id === site.groupId)) {
            tempGroups.push({
              id: site.groupId,
              description: site.groupDescription,
              color: json.groups.find(group => group.id === site.groupId)?.color || "#1A73E8",
              defaultColor: json.groups.find(group => group.id === site.groupId)?.color || "#1A73E8",
            });
          }
        });

        setSites(tempSites);
        setGroups(tempGroups);
      } catch (e) {
        console.error(e);
      }
    } else {
      const tempSites = allSites.value.filter(obj=>obj.id !== -1).map((item)=>({
        id: item.id,
        description:item.description,
        color: "#1A73E8",
        defaultColor: "#1A73E8"
      }));
      const tempGroups = [];
      allSites.value.filter(obj=>obj.id !== -1).forEach(site => {
        if (!tempGroups.some(obj => obj.id === site.groupId)) {
          tempGroups.push({
            id: site.groupId,
            description: site.groupDescription,
            color: "#1A73E8",
            defaultColor: "#1A73E8"
          });
        }
      });
      setSites(tempSites);
      setGroups(tempGroups);
    }
  }, [preferences]);

  const updateColors = async () => {
    setLoading(true);
    const pref = {
      groups: groups.map((obj) => ({
        id: obj.id,
        color: obj.color,
        description: obj.description
      })),
      sites: sites.map((obj) => ({
        id: obj.id,
        color: obj.color,
        description: obj.description
      }))
    }

    await handleSubmit("group_site_color", JSON.stringify(pref));
    setLoading(false);
  }

  const resetColors = async () => {
    setLoading(true);
    await handleDelete("group_site_color");
    setLoading(false);
  }

  const onChange = (isGroup, id, color) => {
    let temp = [];
    if (isGroup) {
      temp = groups;
    } else {
      temp = sites
    }

    temp.find((obj) => obj.id === id).color = color;

    if (isGroup) {
      setGroups(temp);
    } else {
      setSites(temp);
    }
  }

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid size={{xs:12}}>
          <MDTypography variant="subtitle2" textAlign="center">
            Colors set here will be used in the <b>Analysis</b> {">"} <b>KPI</b> page.
          </MDTypography>
        </Grid>

        <Grid size={{xs:12}}>
          <ContentCollapse
            title={"Group Colors"}
            open={gCollapse}
            onClick={() => {setGCollapse(!gCollapse)}}
          >
            <MDBox sx={{padding:"0 1rem 1rem 1rem"}}>
              <Grid container spacing={1}>
                {
                  groups.map((obj, index)=>(
                    <Grid key={index} size={{xs:12}}>
                      <SiteColor
                        site={obj}
                        onChange={onChange}
                        isGroup
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </MDBox>
          </ContentCollapse>
        </Grid>

        <Grid size={{xs:12}}>
          <ContentCollapse
            title={"Site Colors"}
            open={sCollapse}
            onClick={() => {setSCollapse(!sCollapse)}}
          >
            <MDBox sx={{padding:"0 1rem 1rem 1rem"}}>
              <Grid container spacing={1}>
                {
                  sites.map((obj, index)=>(
                    <Grid key={index} size={{xs:12}}>
                      <SiteColor
                        site={obj}
                        onChange={onChange}
                      />
                    </Grid>
                  ))
                }
              </Grid>
            </MDBox>
          </ContentCollapse>
        </Grid>

        <Grid size={{xs:12}}>
          <MDBox display="flex" alignItems="end" justifyContent="end" sx={{width:"100%", gap:2}}>
            <MDButton disabled={isLoading} onClick={resetColors} variant="gradient" color="error" size="small" ml="auto">
              reset colors
            </MDButton>
            <MDButton disabled={isLoading} onClick={updateColors} variant="gradient" color="info" size="small" ml="auto">
              update colors
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  )
}

GroupSiteTab.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  preferences: PropTypes.array.isRequired
}

export default GroupSiteTab;