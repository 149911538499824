import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { readingConsumptionBaseData, readingConsumptionOptions } from "./configs";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { downloadChart, formatDate } from "util/UtilHelper";
import { downloadExcel } from "util/ExcelUtil";
import { CircularProgress } from "@mui/material";
import { Line } from "react-chartjs-2";
import MiniStatisticsCard from "components/MDComponents/Cards/StatisticsCards/MiniStatisticsCard";
import { getMeterReadingAnalysis } from "util/APIHelper";

const ReadingConsumptionGraph = React.forwardRef(({ endpoint, startDate, endDate }, ref) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(readingConsumptionBaseData);
  const [maxConsumption, setMaxConsumption] = useState(null);
  const [avgConsumption, setAvgConsumption] = useState(null);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeterReadingAnalysis(
        formatDate("YYYY-MM-DD",startDate,true),
        formatDate("YYYY-MM-DD",endDate,true),
        endpoint
      );
      if (response.success) {
        const consumptionData = []
        const readingData = []

        response.content.forEach((item)=>{
          consumptionData.push({
            x: item["timestamp_utc"],
            y: item["meter_consumption"]/1000
          });

          readingData.push({
            x: item["timestamp_utc"],
            y: item["meter_reading"]/1000
          })
        })

        let currentData = structuredClone(readingConsumptionBaseData);
        currentData.datasets[0].data = consumptionData;
        currentData.datasets[1].data = readingData;
        setData(currentData);

        if (consumptionData.length>0) {
          setMaxConsumption((Math.max(...consumptionData.map(obj => obj.y))).toFixed(4));
          setAvgConsumption((consumptionData.reduce((acc, obj) => acc + obj.y, 0) / consumptionData.length).toFixed(4));
        } else {
          setMaxConsumption(null);
          setAvgConsumption(null);
        }
      } else {
        setData(readingConsumptionBaseData);
        setMaxConsumption(null);
        setAvgConsumption(null);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const options = readingConsumptionOptions;
  const chartRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => { setAnchorEl(event.currentTarget); };
  const handleMenuClose = () => { setAnchorEl(null); };

  React.useImperativeHandle(ref, () => ({
    getData
  }));
  return (
    <Grid container spacing={1}>
      <Grid size={{xs:12, md: 8, xl:9}} order={{ xs: 2, md: 1 }}>
        <Card>
          <MDBox display="flex" px={2} pt={2}>
            <MDBox mr="auto">
              <MDTypography variant="h6">Reading & Consumption</MDTypography>
              <MDBox mb={2}>
                <MDTypography component="div" variant="button" color="text">
                  Analysis Graph
                </MDTypography>
              </MDBox>
            </MDBox>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={getData}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem", marginRight:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">refresh</Icon>
            </IconButton>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={handleMenuOpen}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => { downloadChart(chartRef, 'Reading & Consumption graph'); handleMenuClose(); }}>Download Image</MenuItem>
              <MenuItem
                onClick={() => {
                  const { datasets } = data
                  const records = [];

                  for (let i=0; i<datasets[0].data.length; i++) {
                    records.push({
                      "Timestamp": datasets[0].data[i].x,
                      "Reading": datasets[1].data[i].y,
                      "Consumption": datasets[0].data[i].y,
                    })
                  }
                  const contents = {
                    "Data": records
                  }
                  downloadExcel("Reading & Consumption data", contents);
                }}
              >
                Download Excel
              </MenuItem>
            </Menu>
          </MDBox>
          {
            isLoading ? (
              <MDBox height="30rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <CircularProgress color="info" />
              </MDBox>
            ):(
              <MDBox height="30rem" px={1} pb={1}>
                <Line ref={chartRef} data={data} options={options} redraw />
              </MDBox>
            )
          }
        </Card>
      </Grid>
      <Grid size={{xs:12, md: 4, xl:3}} order={{ xs: 1, md: 2 }}>
        <MDBox>
          <Grid container spacing={1}>
            <Grid size={{ xs:6, md:12}}>
              <MiniStatisticsCard
                title={{ text: "Maximum Consumption" }}
                count={`${(isLoading || maxConsumption===null || maxConsumption===undefined)?'':maxConsumption} m³`}
                icon={{ color: "info", component: "gas_meter" }}
                direction="left"
              />
            </Grid>
            <Grid size={{ xs:6, md:12}}>
              <MiniStatisticsCard
                title={{ text: "Average Consumption" }}
                count={`${(isLoading || avgConsumption===null ||avgConsumption===undefined)?'':avgConsumption} m³`}
                icon={{ color: "info", component: "water_drop" }}
                direction="left"
              />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  )
});

ReadingConsumptionGraph.propTypes = {
  endpoint: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default ReadingConsumptionGraph;