import typography from "assets/theme/base/typography";
import AlarmIcon from "components/AlarmIcon";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import { createRoot } from "react-dom/client";
import { formatDate } from "util/UtilHelper";

function tooltip() {
  switch (localStorage.getItem("datetime-format")) {
    case "DD/MM/YYYY": return 'dd/MM/yyyy HH:mm';
    case "MM/DD/YYYY": return 'MM/dd/yyyy HH:mm';
    case "MM-DD-YYYY": return 'MM-dd-yyyy HH:mm';
    default: return 'dd-MM-yyyy HH:mm';
  }
}

export const batteryOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },

  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Time',
        color: "#9ca2b7",
      },
      time: {
        unit: 'hour',
        tooltipFormat: tooltip(),
      },
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "rgba(193,196,206,0.3)",
      },
      ticks: {
        display: true,
        color: "#9ca2b7",
        padding: 20,
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y1: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Battery Level (%)',
        color: "#9ca2b7",
      },
      min: 0,
      max: 100,
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y2: {
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Battery Voltage (V)',
        color: "#9ca2b7",
      },
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#9ca2b7",
      }
    },
  },
}

export const batteryBaseData = {
  datasets: [
    {
      label: 'Battery Level (%)',
      data: [],
      borderColor: '#4CAF50',
      backgroundColor: 'rgba(76,175,80,0.2)',
      yAxisID: 'y1',

    },
    {
      label: 'Battery Voltage (V)',
      data: [],
      borderColor: '#2196F3',
      backgroundColor: 'rgba(33,150,243,0.2)',
      yAxisID: 'y2',
    },
  ],
}

export const temperatureGraphOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'index',
    intersect: false,
  },

  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Time',
        color: "#9ca2b7",
      },
      time: {
        unit: 'hour',
        tooltipFormat: tooltip(),
      },
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "rgba(193,196,206,0.3)",
      },
      ticks: {
        display: true,
        color: "#9ca2b7",
        padding: 20,
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y: {
      type: 'linear',
      title: {
        display: true,
        text: 'Temperature (°C)',
        color: "#9ca2b7",
      },
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: false
    },
  },
}

export const temperatureGraphBaseData = {
  datasets: [
    {
      label: 'Temperature (°C)',
      data: [],
      borderColor: '#2196F3',
      backgroundColor: 'rgba(33,150,243,0.2)',
      tension:0.4,
      pointRadius:0
    },
  ],
}

export const temperaturePieOptions = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: 'bottom',
      labels: {
        color: "#9ca2b7",
      }
    },
    tooltip: {
      enabled: true,
      callbacks: {
        label: function(tooltipItem) {
          const value = tooltipItem.raw || 0;
          return ` ${value} %`; // Customize tooltip content
        },
      },
    },
  },
}

export const temperaturePieBaseData = {
  labels: ["< 20 °C", "20 °C - 30 °C", "> 30 °C"],
  datasets: [
    {
      label: "",
      backgroundColor: ["#1A73E8", "#4CAF50", "#fb8c00"],
      data: [0,0,0],
    }
  ]
}

export const readingConsumptionOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },

  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Time',
        color: "#9ca2b7",
      },
      time: {
        unit: 'hour',
        tooltipFormat: tooltip(),
      },
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "rgba(193,196,206,0.3)",
      },
      ticks: {
        display: true,
        color: "#9ca2b7",
        padding: 20,
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y1: {
      type: 'linear',
      position: 'left',
      title: {
        display: true,
        text: 'Consumption (m³)',
        color: "#9ca2b7",
      },
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y2: {
      type: 'linear',
      position: 'right',
      title: {
        display: true,
        text: 'Reading (m³)',
        color: "#9ca2b7",
      },
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#9ca2b7",
      }
    },
  },
}

export const readingConsumptionBaseData = {
  datasets: [
    {
      label: 'Consumption (m³)',
      data: [],
      borderColor: '#4CAF50',
      backgroundColor: 'rgba(76,175,80,0.2)',
      yAxisID: 'y1',

    },
    {
      label: 'Reading (m³)',
      data: [],
      borderColor: '#2196F3',
      backgroundColor: 'rgba(33,150,243,0.2)',
      yAxisID: 'y2',
    },
  ],
}

export const signalOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },
  plugins: {
    legend: {
      display: true,
      labels: {
        color: "#9ca2b7",
      }
    },
  },

  scales: {
    x: {
      type: 'time',
      title: {
        display: true,
        text: 'Time',
        color: "#9ca2b7",
      },
      time: {
        unit: 'hour',
        tooltipFormat: tooltip(),
      },
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "rgba(193,196,206,0.3)",
      },
      ticks: {
        display: true,
        color: "#9ca2b7",
        padding: 20,
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y: {
      type: 'linear',
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
  }
}

export const signalBaseData = {
  datasets: [
    {
      label: 'RSRP (dBm)',
      data: [],
      borderColor: '#fb8c00',
      backgroundColor: 'rgba(251,140,0,0.2)',
    },
    {
      label: 'RSSI (dBm)',
      data: [],
      borderColor: '#2196F3',
      backgroundColor: 'rgba(33,150,243,0.2)',

    },
    {
      label: 'RSRQ (dB)',
      data: [],
      borderColor: '#4CAF50',
      backgroundColor: 'rgba(76,175,80,0.2)',

    },
    {
      label: 'SINR (dB)',
      data: [],
      borderColor: '#F44335',
      backgroundColor: 'rgba(244,67,53,0.2)',
    },
  ],
}

export const alarmOptions = {
  responsive: true,
  maintainAspectRatio: false,
  interaction: {
    mode: 'nearest',
    intersect: false,
    axis: 'x'
  },
  plugins: {
    legend: {
      display: false,
    },
    tooltip: {
      enabled: false,  // Disable default tooltips
      external: function(context) {
        // Get or create tooltip element
        let tooltipEl = document.getElementById('chartjs-tooltip');

        if (!tooltipEl) {
          tooltipEl = document.createElement('div');
          tooltipEl.id = 'chartjs-tooltip';
          tooltipEl.innerHTML = '<div></div>';
          document.body.appendChild(tooltipEl);
        }

        // Hide if no tooltip is visible
        const tooltipModel = context.tooltip;
        if (tooltipModel.opacity === 0) {
          tooltipEl.style.opacity = 0;
          return;
        }

        // Set position based on screen width
        const position = context.chart.canvas.getBoundingClientRect();
        const caretX = tooltipModel.caretX;
        const tooltipWidth = tooltipEl.offsetWidth;
        const isRightHalf = caretX > window.innerWidth / 2;

        // Adjust position for right half of the screen
        tooltipEl.style.opacity = 1;
        tooltipEl.style.position = 'absolute';
        tooltipEl.style.left = isRightHalf
          ? position.left + window.pageXOffset + caretX - tooltipWidth + 'px'  // Shift left
          : position.left + window.pageXOffset + caretX + 'px';  // Default right position
        tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
        tooltipEl.style.pointerEvents = 'none';

        // Render tooltip content using React
        if (tooltipModel.body) {
          const { dataPoints } = tooltipModel;
          const alarms = dataPoints[0].raw.alarms || [];
          const timestamp = dataPoints[0].raw.x;
          const alarmCount = dataPoints[0].raw.y;

          let root = tooltipEl._root;
          if (!root) {
            root = createRoot(tooltipEl.querySelector('div'));
            tooltipEl._root = root;
          }

          root.render(
            <div>
              <Card sx={{backgroundColor: "rgba(0,0,0,0.9)", padding:"0.3rem"}}>
                <Typography color="white" variant="subtitle2">{formatDate(localStorage.getItem("datetime-format"), new Date(timestamp))}</Typography>
                <Typography color="white" variant="body2" mb={1}>Alarm Count: {alarmCount}</Typography>
                <div style={{display:"flex", gap:"0.3rem"}}>
                  {alarms.length > 0 ? (
                    alarms.map((alarm, index) => (
                      <AlarmIcon key={index} alarm={alarm} size="1.5rem" triggered />
                    ))
                  ) : (
                    <div>No alarms</div>
                  )}
                </div>
              </Card>
            </div>
          );
        }
      }
    }
  },

  scales: {
    x: {
      type: "time",
      title: {
        display: true,
        text: "Time",
        color: "#9ca2b7",
      },
      time: {
        unit: "hour",
        tooltipFormat: tooltip(),
      },
      grid: {
        drawBorder: false,
        display: true,
        drawOnChartArea: true,
        drawTicks: true,
        borderDash: [5, 5],
        color: "rgba(193,196,206,0.3)",
      },
      ticks: {
        display: true,
        color: "#9ca2b7",
        padding: 20,
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
      },
    },
    y: {
      type: 'linear',
      grid: {
        display: false,
      },
      ticks: {
        display: true,
        padding: 10,
        color: "#9ca2b7",
        font: {
          size: 11,
          family: typography.fontFamily,
          style: "normal",
          lineHeight: 2,
        },
        callback: function(value) {
          if (Number.isInteger(value)) {
            return value;
          }
          return null;
        }
      },
    },
  }
};


export const alarmBaseData = {
  datasets: [
    {
      label: 'Alarm Count',
      data: [],
      borderColor: '#F44335',
      backgroundColor: 'rgba(244,67,53,0.2)',
      pointRadius: 10
    },
  ],
}