import Card from "@mui/material/Card";
import {CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";
import Dropzone from "components/Dropzone";
import {useEffect, useState} from "react";
import MDButton from "components/MDBase/MDButton";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";
import MDBox from "components/MDBase/MDBox";
import {uploadFirmwareFile} from "util/APIHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";

function UploadFirmwareFileModal({open, handleClose}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const [file, setFile] = useState(null);
  const [description, setDescription] = useState("");
  const [submitting, setSubmitting] = useState(false);
  const handleDescriptionChange = (event) => setDescription(event.target.value);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  useEffect(() => {
    if (!open) {
      setFile(null);
      setDescription("")
    }
  }, [open]);

  // Remove file function
  const removeFile = () => {
    setFile(null);
  };

  // Handle upload file to cloudinary
  const handleSubmit = async () => {
    if (!file) {
      handleSB({
        color:"error",
        icon: "warning",
        title: "Error",
        content: "Please select a file",
        dateTime: ""
      })
      return
    }

    if (!description) {
      handleSB({
        color:"error",
        icon: "warning",
        title: "Error",
        content: "Please provide a description",
        dateTime: ""
      })
      return
    }
    setSubmitting(true);
    try {
      const response = await uploadFirmwareFile(description, file);
      if (response.success && response.content) {
        handleSB({
          color:"success",
          icon: "check",
          title: "Success",
          content: response.content,
          dateTime: ""
        })
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: response.message,
          dateTime: ""
        })
      }
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false);
  };

  const handleDrop = (acceptedFiles) => {
    if (acceptedFiles?.length) {
      const selectedFile = acceptedFiles[0]; // Since only one file is accepted, pick the first
      selectedFile.preview = URL.createObjectURL(selectedFile);
      setFile(selectedFile);
    }
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          Upload Firmware
        </MDTypography>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Dropzone handleDrop={handleDrop} file={file}/>
          </Grid>
          <Grid item xs={12}>
            {
              file && (
                <MDBox sx={{display:"flex", justifyContent:"flex-end"}}>
                  <MDButton variant="outlined" color="error" onClick={removeFile} size="small">
                    Remove File
                  </MDButton>
                </MDBox>
              )
            }
          </Grid>
          <Grid item xs={12}>
            <MDInput
              label="Description"
              name="description"
              onChange={handleDescriptionChange}
              multiline

              maxRows={3}
              fullWidth
              value={description} />
          </Grid>
        </Grid>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          <MDButton variant="outlined" color="secondary" sx={{marginRight:"1rem"}} onClick={handleClose}>Cancel</MDButton>
          <MDButton sx={{width:"9rem"}} disabled={submitting} variant="gradient" color="info" onClick={handleSubmit}>
            {
              submitting?(
                <CircularProgress color="white" size="1rem"/>
              ):"Upload"
            }
          </MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

UploadFirmwareFileModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}
export default UploadFirmwareFileModal