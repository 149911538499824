import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React from "react";
import Grid from "@mui/material/Grid";
import ConsumptionAnalysisGraph from "layouts/analysis/consumption/components/ConsumptionAnalysisGraph";

function AnalysisConsumption() {
  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} item><ConsumptionAnalysisGraph timeframe="day" type="total" /></Grid>
        <Grid xs={12} md={6} item><ConsumptionAnalysisGraph timeframe="day" type="average" /></Grid>
        <Grid xs={12} md={6} item><ConsumptionAnalysisGraph timeframe="week" type="total" /></Grid>
        <Grid xs={12} md={6} item><ConsumptionAnalysisGraph timeframe="week" type="average" /></Grid>
        <Grid xs={12} md={6} item><ConsumptionAnalysisGraph timeframe="month" type="total" /></Grid>
        <Grid xs={12} md={6} item><ConsumptionAnalysisGraph timeframe="month" type="average" /></Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AnalysisConsumption;