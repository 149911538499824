import {Card} from "@mui/material";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import React from "react";
import PropTypes from "prop-types";
import AlarmIcon from "components/AlarmIcon";

function DashboardAlarmTallyWidget({data, alarm}) {
  const getTitle = () => {
    switch (alarm) {
      case 'leakageDetection': return "Leak";
      case 'noFlowDetection': return "No Flow";
      case 'burstDetection': return "Burst";
      case 'backFlowDetection': return "Back Flow";
      case 'batteryLow': return "Battery Low";
      case 'faultySensor': return "Faulty Sensor";
      case 'wireCutDetection': return "Wire Cut";
      case 'tiltDetection': return "Tilt";
      case 'magnetTamper': return "Magnet Tamper";
      case 'nonRealtimeBackFlowDetection': return "Non-Realtime Back Flow";
      case 'rebootDetection': return "Reboot";
      default: return "";
    }
  }

  const getCounter = () => {
    switch (alarm) {
      case 'leakageDetection': return data?.leakageDetection;
      case 'noFlowDetection': return data?.noFlowDetection;
      case 'burstDetection': return data?.burstDetection;
      case 'backFlowDetection': return data?.backFlowDetection;
      case 'batteryLow': return data?.batteryLow;
      case 'faultySensor': return data?.faultySensor;
      case 'wireCutDetection': return data?.wireCutDetection;
      case 'tiltDetection': return data?.tiltDetection;
      case 'magnetTamper': return data?.magnetTamper;
      case 'nonRealtimeBackFlowDetection': return data?.nonRealtimeBackFlowDetection;
      case 'rebootDetection': return data?.rebootDetection;
      default: return 0;
    }
  }

  return (
    <Tooltip title={getTitle()}>
      <Card style={{ height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "center" }}>
        <MDBox>
          <AlarmIcon alarm={alarm} triggered={getCounter()>0} noTooltip/>
        </MDBox>
        <MDTypography variant="h4" mt={2}>
          {getCounter()??0}
        </MDTypography>
      </Card>
    </Tooltip>
  );
}

DashboardAlarmTallyWidget.in

DashboardAlarmTallyWidget.propTypes = {
  data: PropTypes.object,
  alarm: PropTypes.string.isRequired
}
export default DashboardAlarmTallyWidget;