import PropTypes from "prop-types";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import { IconButton } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { SettingsBackupRestoreOutlined } from "@mui/icons-material";
import MDInput from "components/MDBase/MDInput";
import React, { useEffect, useState } from "react";

const SiteColor = ({onChange, site, isGroup=false}) => {
  const [color, setColor] = useState(site.color)

  useEffect(() => {
    // console.log(site);
  }, []);

  return (
    <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{backgroundColor:`rgba(0,0,0,0.1)`, borderRadius:"1rem"}} p={1}>
      <MDBox display="flex" alignItems="center" ml={1} sx={{gap:2}}>
        <MDTypography variant="body2">
          {site.description}
        </MDTypography>
      </MDBox>
      <MDBox alignItems="center">
        <IconButton
          onClick={()=>{
            setColor(site.defaultColor);
            onChange(isGroup, site.id, site.defaultColor);
          }}
          size="small"
          sx={{
            color: "text",
            p: 0, mr: 2,
            "&:hover": {
              color: "error.main",
            },
          }}
        >
          <Tooltip title="Reset Color">
            <SettingsBackupRestoreOutlined fontSize="small" />
          </Tooltip>
        </IconButton>
        <MDInput
          value={color}
          sx={{width:100}}
          type="color"
          onChange={(e)=> {
            setColor(e.target.value);
            onChange(isGroup, site.id, e.target.value);
          }}
        />
      </MDBox>
    </MDBox>
  )
}

SiteColor.propTypes = {
  site: PropTypes.object.isRequired,
  onChange: PropTypes.func.isRequired,
  isGroup: PropTypes.bool
}
export default SiteColor;