import PropTypes from "prop-types";
import MDBox from "components/MDBase/MDBox";
import GroupKPIWidgetIndividual from "layouts/analysis/kpi/components/GroupsKPIWidgets/GroupKPIWidgetIndividual";

const GroupsKPIWidgets = ({ selectedMeters, endpointIntervals, data, groups}) => {

  const groupWidgets = (group) => {
    return group.sites.map((site, index) => {
      const list = selectedMeters.filter((meter) => meter.site_id === site.id ).map((_)=>_.endpoint)
      const filteredData = data.map((dates) => {
        return {
          ...dates,
          data: dates.data.filter((date_data) => list.includes(date_data.endpoint))
        }
      });
      const filteredEndpointIntervals = endpointIntervals.filter((intervals) => list.includes(intervals.endpoint))
      return <GroupKPIWidgetIndividual key={index} data={filteredData} endpointIntervals={filteredEndpointIntervals} columnValue={site.name} column={group.name}/>
    })
  }

  return (
    <MDBox
      display="flex"
      sx={{
        overflowX: 'auto',
        overflowY: 'hidden',
        whiteSpace: 'nowrap',
        '& > *': { // Selecting direct children of MDBox
          marginRight: '1.5rem', // Adjust the spacing as per your requirement
        },
        '& > *:last-child': { // Selecting the last child to remove right margin
          marginRight: 0,
        },
        paddingBottom: '1rem'
      }}
    >
      {groups.map((group)=>groupWidgets(group))}
    </MDBox>
  )
}

GroupsKPIWidgets.defaultProps = {
  isLoading: false
}

GroupsKPIWidgets.propTypes = {
  selectedMeters: PropTypes.array.isRequired,
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
}

export default GroupsKPIWidgets;