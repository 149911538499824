import React, { useEffect, useState, useMemo } from "react";
import PropTypes from "prop-types";
import MDBox from "components/MDBase/MDBox";
import { CircularProgress } from "@mui/material";
import { deleteUserPreference, getUserPreference, postUserPreference } from "util/APIHelper";
import MapTab from "./tabs/mapTab";
import TableTab from "./tabs/tableTab";
import AlarmTab from "./tabs/alarmTab";
import GroupSiteTab from "./tabs/groupSiteTab";

function PreferenceSection({ email, handleSB, tab }) {
  const [preferences, setPreferences] = useState([]);
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (email) {
      getData();
    }
  }, [email]);

  const getData = async () => {
    setLoading(true);
    const userPref = await getUserPreference(email, "all");
    if (userPref.success) {
      const preferenceList = [];
      Object.keys(userPref.content).forEach((key) => {
        preferenceList.push({ item: key, value: userPref.content[key] });
      });
      setPreferences(preferenceList);
    }
    setLoading(false);
  };

  const handleDelete = async (itemToDelete) => {
    try {
      const response = await deleteUserPreference(email, itemToDelete)
      if (response.success) {
        const updatedPreferences = preferences.filter(
          (preference) => preference.item !== itemToDelete
        );
        setPreferences(updatedPreferences);
        sessionStorage.removeItem(itemToDelete);
      }

      handleSB({
        color: response.success ? "success" : "error",
        icon: response.success ? "check" : "warning",
        title: response.success ? "Success" : "Error",
        content: response.success ? response.content : response.message
      });
    } catch (e) {
      console.error(e)
    }
  };

  const handleSubmit = async (item, value) => {
    try {
      const response = await postUserPreference(email, [
        {
          type: item,
          value: value
        }
      ])

      if (response.success) {
        const updatedPreferences = structuredClone(preferences);
        const index = updatedPreferences.findIndex((obj)=>obj.item===item);
        if (index !== -1) {
          updatedPreferences[index].value = value
        } else {
          updatedPreferences.push({
            item: item,
            value: value
          })
        }
        setPreferences(updatedPreferences);
        sessionStorage.setItem(item, value);
      }

      handleSB({
        color: response.success ? "success" : "error",
        icon: response.success ? "check" : "warning",
        title: response.success ? "Success" : "Error",
        content: response.success ? "Preference Set" : "Failed Setting Preference"
      });
    } catch (e) {
      console.error(e)
    }
  }

  const memoizedTabs = useMemo(() => {
    if (tab === 0) {
      return <MapTab
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        preferences={preferences.filter((item) => item.item.toString().startsWith("dashboard"))}
      />
    }
    else if (tab === 1) {
      return <TableTab
        handleDelete={handleDelete}
        preferences={preferences.filter((item) => item.item.toString().endsWith("column_state"))}
      />
    }
    else if (tab === 2) {
      return <GroupSiteTab
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        preferences={preferences.filter((item) => item.item.toString() === "group_site_color")}
      />
    }
    else if (tab === 3) {
      return <AlarmTab
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        preferences={preferences.filter((item) => item.item.toString() === "alarm_color")}
      />
    }
  }, [tab, preferences]);

  return (
    <MDBox sx={{ padding: "0 0.5rem 1rem 0.5rem" }}>
      {isLoading ? (
        <MDBox display="flex" justifyContent="center">
          <CircularProgress color="white" size="1rem" />
        </MDBox>
      ) : memoizedTabs}
    </MDBox>
  );
}

PreferenceSection.propTypes = {
  email: PropTypes.string.isRequired,
  handleSB: PropTypes.func.isRequired,
  tab: PropTypes.number.isRequired
};

export default PreferenceSection;
