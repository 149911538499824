import React, { useState } from "react";
import PropTypes from "prop-types";
import { Box, CircularProgress } from "@mui/material";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDButton from "components/MDBase/MDButton";

const TablePreference = ({ item, tableName, onDelete }) => {
  const [isDeleting, setIsDeleting] = useState(false);

  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await onDelete(item);
    } finally {
      setIsDeleting(false);
    }
  };
  return (
    <MDBox
      sx={{
        display: "flex",
        flexDirection: "column",
        mb: 1,
        p: 1,
        borderRadius: "4px",
        "&:hover": {
          backgroundColor: "rgba(255, 255, 255, 0.05)",
        },
      }}
    >
      <Box sx={{ display: "flex", justifyContent: "space-between", alignItems: "center", mb: 0.5 }}>
        <Box>
          <MDTypography
            variant="body2"
            fontWeight="medium"
            sx={{
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              flex: 1, // Allow the value to take remaining space
            }}
          >
            {tableName} Table
          </MDTypography>
          <MDTypography
            variant="caption"
            color="text"
          >
            Column State
          </MDTypography>
        </Box>
        {isDeleting ? (
          <CircularProgress
            color="error"
            size="1rem"
          />
        ) : (
          <MDButton size="small" variant="gradient" color="error" onClick={() => handleDelete(item)}>
            Reset
          </MDButton>
        )}
      </Box>
    </MDBox>
  );
};

TablePreference.propTypes = {
  item: PropTypes.string.isRequired,
  tableName: PropTypes.string.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default TablePreference;