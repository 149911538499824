// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";

function SuccessRateCell({success_rate, ...props}) {
  let color;

  if (success_rate>=80) { color="success"; }
  else if (success_rate>=50) { color="warning"; }
  else if (success_rate>=0) { color="error"; }
  else { color="text"; }

  return (
    <MDTypography {...props} variant="body" color={color}>
      {`${success_rate}%`}
    </MDTypography>
  );
}

// Typechecking props for the StatusCell
SuccessRateCell.propTypes = {
  success_rate: PropTypes.string.isRequired,
};

export default SuccessRateCell;
