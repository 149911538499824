import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import StatisticsCard from "components/Cards/StatisticsCard";
import { epochToTimestamp, formatDate } from "util/UtilHelper";
import {Battery30, Battery50, Battery80, BatteryFull} from "@mui/icons-material";

function BatteryLevelCard({batteryLevel, timestamp, loading}) {
  const [color, setColor] = useState("success");
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    let i = <Battery30 fontSize="medium" />;
    let c = "error";

    if (batteryLevel>=90) {
      i = <BatteryFull fontSize="medium" />
      c = "success"
    }
    else if (batteryLevel>=50) {
      i = <Battery80 fontSize="medium" />
      c = "info"
    }
    else if (batteryLevel>=30) {
      i = <Battery50 fontsize="medium" />
      c = "warning"
    }

    setIcon(i)
    setColor(c)
  }, [batteryLevel, timestamp, loading]);

  return (
    <StatisticsCard
      color={color}
      icon="battery_full"
      iconWidget={icon}
      title="Battery Level"
      count={`${batteryLevel} %`}
      percentage={{
        label: (timestamp!==0)?
          formatDate(
            localStorage.getItem("datetime-format"),
            new Date(epochToTimestamp(timestamp))
          ):"",
      }}
      isLoading={loading}
    />
  );
}

BatteryLevelCard.defaultProps = {
  loading: false
}

BatteryLevelCard.propTypes = {
  batteryLevel: PropTypes.number.isRequired,
  timestamp: PropTypes.number.isRequired,
  loading: PropTypes.bool
}

export default BatteryLevelCard;