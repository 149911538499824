import PropTypes from "prop-types";
import {Card} from "@mui/material";
import React, { useEffect, useState } from "react";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MRTable from "components/MaterialReactTable";
import { columnDefs, monthlyConsumptionTableProcess } from "./tableUtil";
import { postUserPreference } from "util/APIHelper";
import { useMaterialUIController } from "context/md";

const MonthlyConsumptionLogTable = ({data, isLoading=false}) => {
  const [init, setInit] = useState(false);
  const [records, setRecords] = useState([]);
  const [controller] = useMaterialUIController();
  const { user } = controller;
  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("reports_consumption_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });
  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("reports_consumption_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email, [
        {
          type: "reports_consumption_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    processRecords()
  }, [data]);

  const processRecords = () => {
    setRecords(monthlyConsumptionTableProcess(data));
    if (init) {
      setTimeout(()=>{
        window.scrollTo({ top: document.documentElement.scrollHeight, behavior: 'smooth' })
      },300)
    }
    setInit(true)
  }

  return (
    <Card>
      <MDBox p={3} lineHeight={1}>
        <MDTypography variant="h5" fontWeight="medium">
          Monthly Consumption Logs
        </MDTypography>
      </MDBox>
      <MRTable
        data={records}
        columns={columnDefs}
        isLoading={isLoading}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
      />
    </Card>
  )
}

MonthlyConsumptionLogTable.propTypes = {
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}

export default MonthlyConsumptionLogTable;