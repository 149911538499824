import {Card, Skeleton} from "@mui/material";
import {useEffect, useState} from "react";
import {getAnomalyData} from "util/APIHelper";
import Grid from "@mui/material/Grid";
import MDTypography from "components/MDBase/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import {useNavigate} from "react-router-dom";
import { epochToTimestamp, formatDate } from "util/UtilHelper";
import AnomalyMessageCell from "components/TableCells/AnomalyTable/AnomalyMessageCell";
import WebSocketService from "model/WebSocketService";
import useCooldown from "util/hooks/useCooldown";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function DashboardLatestAnomalyWidget() {
  const navigate = useNavigate();
  const [data, setData] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "AnomalyTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, []);

  useEffect(() => {
    getData();
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      throttledGetData()
    }
  });

  const getData = async () => {
    setLoading(true);
    try {
      if (selectedSites.value.length>0) {
        const sites = selectedSites.value.map(e => e.id);
        const response = await getAnomalyData(1, sites.join(","), sites.includes(-1))
        setData(response.content[0]??{});
      } else {
        setData({});
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
    if (!isMounted) {
      setIsMounted(true)
    }
  }
  const throttledGetData = useCooldown(getData, 1000);

  const renderMeterSN = () => {
    if (isLoading) {
      return <Skeleton />
    } else {
      return (
        <Tooltip title={data.endpoint??""}>
          <MDTypography
            color="info"
            variant="body2"
            onClick={()=>{navigate(`/meter/details?endpoint=${data.endpoint}`)}}
            sx={{
              marginTop: "1rem",
              display: '-webkit-box',
              WebkitBoxOrient: 'vertical',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'pre-line',
              WebkitLineClamp: 1, // Set the number of lines to display
              cursor: "pointer"
            }}
          >
            {data.meterSerialNumber??(data.endpoint??"")}
          </MDTypography>
        </Tooltip>
      );
    }
  }

  const renderMessage = () => {
    if (isLoading) {
      return <Skeleton />
    } else {
      if (data.anomalyFunction === "Alarm") {
        return (
          <AnomalyMessageCell data={{anomaly_message: data.anomalyMessage, anomaly_function: data.anomalyFunction}} />
        );
      } else {
        return (
          <MDTypography variant="body2" color="text" noWrap>
            {data.anomalyFunction??""}
          </MDTypography>
        );
      }
    }
  }

  const renderTimestamp = () => {
    if (isLoading) {
      return <Skeleton />
    } else {
      return (
        <MDTypography variant="caption" fontWeight="light" color="text" noWrap>
          {
            formatDate(
              localStorage.getItem("datetime-format"),
              new Date(epochToTimestamp(data.createdUtc??0))
            )
          }
        </MDTypography>
      );
    }
  }

  return (
    <Card style={{ padding: "0.5rem 1rem 1rem 1rem", height: "100%", display: "flex", flexDirection: "column", alignItems: "center", justifyContent: "start" }}>
      <Grid container>
        <Grid item xs={12}>
          <MDTypography
            onClick={()=>{ navigate("/meter/anomaly") }}
            sx={{cursor: "pointer"}}
            variant="button"
            textTransform="capitalize"
            fontWeight="medium"
            color="text"
            noWrap
          >
            Latest Anomaly
          </MDTypography>
        </Grid>
        <Grid item xs={12}>{renderMessage()}</Grid>
        <Grid item xs={12}>{renderMeterSN()}</Grid>
        <Grid item xs={12}>{renderTimestamp()}</Grid>
      </Grid>
    </Card>
  );
}

export default DashboardLatestAnomalyWidget;