import PropTypes from "prop-types";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBase/MDBox";
import React, {useEffect, useState} from "react";
import {getCommands} from "util/APIHelper";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDBase/MDInput";
import QueueDownlinkCommandModal from "components/Modals/QueueDownlinkCommandModal";

function DownlinkQueueSubmit({selectedMeters}) {
  const [downlinkCommands, setDownlinkCommands] = useState([]);
  const [selectedDownlinkCommand, setSelectedDownlinkCommand] = useState(null);

  const [queueDownlinkCommandModal, setQueueDownlinkCommandModal] = useState(false);
  const handleOpenModal = () => {
    if (selectedMeters.length>0 && selectedDownlinkCommand) {
      setQueueDownlinkCommandModal(true);
    }
  }
  const handleCloseModal = () => setQueueDownlinkCommandModal(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getCommands();
      setDownlinkCommands(response.content);
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <MDBox sx={{margin:"0 0 1rem 0.5rem", display:"flex"}} >
      <Autocomplete
        options={downlinkCommands}
        value={selectedDownlinkCommand}
        onChange={(event, value) => {
          setSelectedDownlinkCommand(value);
        }}
        isOptionEqualToValue={(option, value) =>
          option.commandName === value.commandName
        }
        getOptionLabel={(option) => option.commandName}
        renderInput={(params) => <MDInput label="Downlink Command" {...params} />}
        sx={{
          width:"15rem",
          '& .MuiOutlinedInput-root': {
            height: '2.5rem'
          },
          marginRight:"1rem"
        }}
        size="small"
      />
      <MDButton onClick={handleOpenModal} variant="outlined" color="info">
        <Icon>queue</Icon>&nbsp; Queue Downlink Command
      </MDButton>

      <QueueDownlinkCommandModal
        selectedDownlinkCommand={selectedDownlinkCommand}
        handleClose={handleCloseModal}
        selectedMeters={selectedMeters}
        open={queueDownlinkCommandModal}
      />
    </MDBox>
  )
}

DownlinkQueueSubmit.propTypes = {
  selectedMeters: PropTypes.array.isRequired
}

export default DownlinkQueueSubmit;