import PropTypes from "prop-types";
import {Card, Skeleton} from "@mui/material";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDBadge from "components/MDBase/MDBadge";
import {useEffect, useState} from "react";
import {checkStatus} from "util/UtilHelper";

function OverallKPIWidget({endpointIntervals, data, isLoading}) {
  const [percentage, setPercentage] = useState("0")
  const [totalExpected, setTotalExpected] = useState(1)
  const [totalReceived, setTotalReceived] = useState(0)
  const [color, setColor] = useState("success");

  useEffect(() => {
    if (!isLoading) {
      calculate();
    }
  }, [isLoading]);
  const calculate = () => {
    if (endpointIntervals.length === 0) { return 0; }
    const totalExpected = (endpointIntervals.reduce((acc, obj) => acc + obj.expectedLogCount, 0)) * data.length;
    const totalReceived = data.reduce((acc, obj) =>
      acc + obj.data.reduce((innerAcc, innerObj) => innerAcc + innerObj.count, 0), 0);

    setTotalExpected(totalExpected)
    setTotalReceived(totalReceived)
    setPercentage(((totalReceived/totalExpected??1)*100).toFixed(2))
    setColor(checkStatus(((totalReceived/totalExpected??1)*100).toFixed(2)));
  }

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="body2" color="text">
          Overall KPI
        </MDTypography>
        <MDBox mt={2} mb={1} lineHeight={0}>
          {
            isLoading?(
              <Skeleton height="2.6rem" width="7rem" />
            ):(
              <MDTypography variant="h3" fontWeight="bold">
                {`${percentage} %`}
              </MDTypography>
            )
          }
        </MDBox>
        {
          isLoading?(
            <Skeleton height="2rem" width="10rem" />
          ):(
            <MDBadge variant="contained" color={color} badgeContent={`${totalReceived} / ${totalExpected} logs`} container />
          )
        }
      </MDBox>
    </Card>
  )
}

OverallKPIWidget.defaultProps = {
  isLoading: false
}

OverallKPIWidget.propTypes = {
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool
}

export default OverallKPIWidget;