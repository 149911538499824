import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {v4 as uuidv4} from "uuid";
import MDTypography from "components/MDBase/MDTypography";
import MDBox from "components/MDBase/MDBox";

function DownlinkParameterCell({parameters}) {
  let paramArray = parameters.split("\n").filter(str => str.trim() !== '');
  return (
    <MDBox width="20rem">
      <Grid container>
        {
          paramArray.map((param)=>(
            <Grid key={uuidv4()} xs={12}>
              <MDTypography variant="body" color="text">{param}</MDTypography>
            </Grid>
          ))
        }
      </Grid>
    </MDBox>
  )
}
DownlinkParameterCell.propTypes = {
  parameters: PropTypes.string.isRequired
}
export default DownlinkParameterCell;