import Card from "@mui/material/Card";
import {CircularProgress, Modal, Skeleton} from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import {useEffect, useState} from "react";
import {getUserRoles, postCreateUser} from "util/APIHelper";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";

import Autocomplete from "@mui/material/Autocomplete";
import {isValidEmail} from "util/UtilHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";

function AddUserModal({open, handleClose}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const [email, setEmail] = useState("");

  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(roles.length > 0 ? roles[0] : null);

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const handleEmailChange = (event) => setEmail(event.target.value);

  useEffect(() => {
    if(selectedRole===null && roles.length>0) {
      setSelectedRole(roles[0])
    }
  }, [roles]);

  useEffect(() => {
    getData();
  }, [open]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getUserRoles();
      setRoles(response.content.map((item) => ({
        roleId: item.roleId,
        roleDescription: item.roleDescription
      })));
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      if (isValidEmail(email)) {
        const response = await postCreateUser(email, selectedRole.roleId)
        if (response.success) {
          handleSB({
            color:"success",
            icon: "check",
            title: "Success",
            content: response.content
          })
        } else {
          handleSB({
            color:"error",
            icon: "warning",
            title: "Error",
            content: response.message
          })
        }
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: "Not a valid email"
        })
      }
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false);
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          Add User
        </MDTypography>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDInput type="email" label="Email" onChange={handleEmailChange} fullWidth />
          </Grid>
          <Grid item xs={12}>
            {
              loading? <Skeleton height="3.35rem" /> :
                <Autocomplete
                  options={roles}
                  value={selectedRole || roles[0]} // Set initial value
                  onChange={(event, value) => {
                    setSelectedRole(value); // Store the selected role object
                  }}
                  isOptionEqualToValue={(option, value) => option.roleDescription === value.roleDescription}
                  getOptionLabel={(option) => option.roleDescription} // Display roleDescription
                  renderInput={(params) => <MDInput label="User Role" {...params} />}
                  disableClearable
                />
            }
          </Grid>
        </Grid>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          <MDButton variant="outlined" color="secondary" sx={{marginRight:"1rem"}} onClick={handleClose}>Cancel</MDButton>
          <MDButton sx={{width:"9rem"}} disabled={submitting} variant="gradient" color="info" onClick={submit}>
            {
              submitting?(
                <CircularProgress color="white" size="1rem"/>
              ):"Create User"
            }
          </MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

AddUserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}
export default AddUserModal