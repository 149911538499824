// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import Alarm from "model/Alarm";
import {createObjectFromStrings} from "util/UtilHelper";
import Grid from "@mui/material/Grid";
import {v4 as uuidv4} from "uuid";
import AlarmIcon from "components/AlarmIcon";

function AnomalyMessageCell({ data }) {
  const [message, setMessage] = useState({message:""});
  useEffect(() => {
    if (data.anomaly_function==="Alarm") {
      const test = new Alarm(createObjectFromStrings(data.anomaly_message.split(",")))
      setMessage({
        message: <Grid container spacing={1}>
          {
            test.getActiveAlarms().map((alarm)=>{
              return (
                <Grid key={uuidv4()} item>
                  <AlarmIcon size="1.5rem" alarm={alarm} triggered/>
                </Grid>
              )
            })
          }
        </Grid>
      })
    } else {
      setMessage({
        message: data.anomaly_message
      })
    }
  }, []);
  return (
    <div>
      {message.message}
    </div>
  );
}

// Setting default value for the props of CustomerCell
AnomalyMessageCell.defaultProps = {
  data: {}
};

// Typechecking props for the CustomerCell
AnomalyMessageCell.propTypes = {
  data: PropTypes.object
};

export default AnomalyMessageCell;
