/* eslint-disable react/prop-types */
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import MDTypography from "components/MDBase/MDTypography";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";
import React from "react";

export const columnDefs = [
  { header: "Timestamp", accessorKey: "timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.timestampUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.timestampUtc, rowB.original.item.timestampUtc),
  },
  { header: "Reading (m³)", accessorKey: "reading", },
  { header: "Consumption (m³)", accessorKey: "consumption",
    Cell: ({ cell }) =>
      <MDTypography
        color={(cell.getValue()<0)?"error":"text"}
        variant="body"
      >
        {cell.getValue()}
      </MDTypography>
  },
  { header: "Temperature (°C)", accessorKey: "temperature", },
  { header: "Battery (%)", accessorKey: "battery",
    filterVariant: 'range-slider',
    muiFilterSliderProps: {
      max: 100,
      min: 0,
      step: 1
    },},
]

export const detailsReadingTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.timestampUtc))),
      reading: (singular.meterReading / 1000).toFixed(4),
      consumption: (singular.meterConsumption / 1000).toFixed(4),
      temperature: singular.temperature,
      battery: singular.batteryLevel
    }

  });
}