// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import {registrationDescription} from "util/LookupUtil";

function MeterStatusCell({ data }) {
  return (
    <MDTypography
      variant="body"
      color={(registrationDescription(data)==="Offline")?"error":null}
    >
      {registrationDescription(data)}
    </MDTypography>
  );
}

// Setting default value for the props of CustomerCell
MeterStatusCell.defaultProps = {
  data: ""
};

// Typechecking props for the CustomerCell
MeterStatusCell.propTypes = {
  data: PropTypes.string
};

export default MeterStatusCell;
