import SimpleGraph from "components/Charts/SimpleGraph";
import {Card} from "@mui/material";
import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {getDashboardConnectivityGraph} from "util/APIHelper";
import { capitalizeFirstLetter, formatDate, getPast7Days, getToday } from "util/UtilHelper";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function DashboardConnectivityGraphWidget({data, type}) {
  const [graphData, setGraphData] = useState({
    labels: [],
    datasets: [],
  });

  useEffect(() => {
    getData()
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async () => {
    try {
      let defaultGraphData = {
        labels: [],
        datasets: [
          {
            label: "",
            color: "info",
            data: [],
          },
        ],
      }

      if (selectedSites.value.length>0) {
        const sites = selectedSites.value.map(e => e.id)
        const response = await getDashboardConnectivityGraph(type, getToday(), sites.join(","), sites.includes(-1))
        defaultGraphData = {
          labels: getPast7Days(getToday()).map((item)=>
            formatDate(
              localStorage.getItem("datetime-format"),
              item,
              true
            )
          ),
          datasets: [
            {
              label: capitalizeFirstLetter(type),
              color: "info",
              data: response.content,
            },
          ],
        }
      }
      setGraphData(defaultGraphData);
    } catch (e) {
      console.error(e)
    }
    if (!isMounted) {
      setIsMounted(true)
    }
  }

  const getTitle = () => {
    switch (type) {
      case 'connected': return "Connected";
      case 'registered': return "Registered";
      case 'unregistered': return "Unregistered";
      default: return ""
    }
  }

  const getCount = () => {
    switch (type) {
      case 'registered': return data.registered?.length;
      case 'connected': return data.connected?.length;
      case 'unregistered': return data.unregistered?.length;
      default: return 0
    }
  }

  const getSubCount = () => {
    const count = graphData.datasets[0]?.data.reduce((ac, cv) => ac + cv, 0)??0
    let color = "secondary";
    if (type === "unregistered") {
      if (count>0) { color = "error"}
    } else {
      if (count>0) { color = "success"}
    }
    return {
      color: color,
      label: `+${count}`
    }
  }

  return (
    <Card>
      <SimpleGraph
        title={getTitle()}
        count={getCount()??0}
        percentage={getSubCount()}
        chart={graphData}
        link={`/meter?toggle=${type}`}
      />
    </Card>
  );
}
DashboardConnectivityGraphWidget.defaultProps = {
  data: {},
  type: ""
}
DashboardConnectivityGraphWidget.propTypes = {
  data: PropTypes.object,
  type: PropTypes.string
}
export default DashboardConnectivityGraphWidget;