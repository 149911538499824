import Card from "@mui/material/Card";
import {CircularProgress, Modal, Skeleton} from "@mui/material";
import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import {getUploadedMeterDetail, postCreateMeter} from "util/APIHelper";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBase/MDBox";
import MDButton from "components/MDBase/MDButton";
import MDInput from "components/MDBase/MDInput";
import Switch from "@mui/material/Switch";
import {toNumber} from "util/UtilHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";

function MeterConfigModal({data, open, handleClose, edit_data, edit_onSubmit}){
  const [isLoading, setLoading] = useState(false)
  const [submitting, setSubmitting] = useState(false)
  const [meterConfigData, setMeterConfigData] = useState({
    endpoint: '',
    meterSerialNumber: '',
    miuSerialNumber: '',
    latitude: 0,
    longitude: 0,
    iccid: '',
    rfuid: '',
    custom1: '',
    custom2: '',
    custom3: '',
    custom4: '',
    custom5: '',
    clientIdentity: '',
    clientKey: '',
    status: 1
  });

  const handleMeterConfigDataChange = (key, value) => setMeterConfigData(prevState => ({
    ...prevState,
    [key]: value
  }));
  const handleActiveSwitch = () => setMeterConfigData(prevState => ({
    ...prevState,
    status: (prevState.status === 1)?0:1
  }));

  useEffect(() => {
    setTabValue(0)
    if (open) {
      if (edit_data) {
        setMeterConfigData(prevState => ({
          ...prevState,
          ...edit_data
        }))
      } else {
        handleMeterConfigDataChange('endpoint', data.endpoint)
        getData()
      }
    }
  }, [open]);

  const getData = async () => {
    setLoading(true)
    try {
      const response = await getUploadedMeterDetail(data.endpoint);
      if (response.success) {
        setMeterConfigData(prevState => ({
          ...prevState,
          ...response.content
        }))
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };


  const [tabValue, setTabValue] = useState(0);
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);
  const toggleComponent = () => (
    <Grid item xs={12} sx={{ mr: "auto", mb: "1rem" }}>
      <Card>
        <AppBar position="static">
          <Tabs orientation="horizontal" value={tabValue} onChange={handleSetTabValue}>
            <Tab label="General" />
            <Tab label="Extras" />
          </Tabs>
        </AppBar>
      </Card>
    </Grid>
  )

  const renderTextField = (label, key, defaultValue) => {
    return isLoading?(
      <Skeleton height="2.75rem" />
    ):(
      <MDInput
        disabled={key==="endpoint" && !edit_data}
        value={defaultValue}
        fullWidth
        label={label}
        onChange={(event)=>{(key !== "endpoint" || edit_data) && handleMeterConfigDataChange(key, event.target.value)}}
      />
    )
  }

  const renderTab = () => {
    return (tabValue === 0)?(
      <Grid container spacing={1.5}>
        <Grid item xs={12}> {renderTextField("Endpoint", "endpoint", meterConfigData.endpoint)} </Grid>
        <Grid item xs={12}> {renderTextField("Meter Serial Number", "meterSerialNumber", meterConfigData.meterSerialNumber)} </Grid>
        <Grid item xs={12}> {renderTextField("MIU Serial Number", "miuSerialNumber", meterConfigData.miuSerialNumber)} </Grid>
        <Grid item xs={12}> {renderTextField("Latitude", "latitude", meterConfigData.latitude)} </Grid>
        <Grid item xs={12}> {renderTextField("Longitude", "longitude", meterConfigData.longitude)} </Grid>
        <Grid item xs={12}> {renderTextField("ICCID", "iccid", meterConfigData.iccid)} </Grid>
        <Grid item xs={12}> {renderTextField("RFUID", "rfuid", meterConfigData.rfuid)} </Grid>
      </Grid>
    ):(
      <Grid container spacing={1.5}>
        <Grid item xs={12}> {renderTextField("Client Identity", "clientIdentity", meterConfigData.clientIdentity)} </Grid>
        <Grid item xs={12}> {renderTextField("Client Key", "clientKey", meterConfigData.clientKey)} </Grid>
        <Grid item xs={12}> {renderTextField("Custom 1", "custom1", meterConfigData.custom1)} </Grid>
        <Grid item xs={12}> {renderTextField("Custom 2", "custom2", meterConfigData.custom2)} </Grid>
        <Grid item xs={12}> {renderTextField("Custom 3", "custom3", meterConfigData.custom3)} </Grid>
        <Grid item xs={12}> {renderTextField("Custom 4", "custom4", meterConfigData.custom4)} </Grid>
        <Grid item xs={12}> {renderTextField("Custom 5", "custom5", meterConfigData.custom5)} </Grid>
      </Grid>
    )
  }

  const handleSubmit = async () => {
    setSubmitting(true)
    if (edit_onSubmit) {
      edit_onSubmit(meterConfigData)
    } else {
      try {
        const response = await postCreateMeter([{
          ...meterConfigData,
          latitude: toNumber(meterConfigData.latitude),
          longitude: toNumber(meterConfigData.longitude)
        }])

        if (response.success) {
          handleSB({
            color:"success",
            icon: "check",
            title: "Success",
            content: "Meter successfully updated."
          })
        } else {
          handleSB({
            color:"error",
            icon: "warning",
            title: "Error",
            content: "Failed to update meter."
          })
        }
      } catch (e) {
        console.error(e)
      }
    }
    setSubmitting(false)
  }

  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;
  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          Edit Meter
        </MDTypography>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12}>{toggleComponent()}</Grid>
          <Grid item xs={12}>{renderTab()}</Grid>
        </Grid>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          <MDBox display="flex" alignItems="center">
            <Switch checked={meterConfigData.status === 1} onChange={handleActiveSwitch} />
            <MDTypography variant="caption">Active</MDTypography>
          </MDBox>
          <MDButton variant="outlined" color="secondary" sx={{marginRight:"1rem", marginLeft:"auto"}} onClick={handleClose}>Cancel</MDButton>
          <MDButton sx={{width:"6rem"}} disabled={submitting || isLoading} variant="gradient" color="info" onClick={handleSubmit}>
            {
              submitting?(
                <CircularProgress color="white" size="1rem"/>
              ):"Save"
            }
          </MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

MeterConfigModal.defaultProps = {
  edit_data: null,
  edit_onSubmit: null
}

MeterConfigModal.propTypes = {
  data: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  edit_data: PropTypes.object,
  edit_onSubmit: PropTypes.func
}
export default MeterConfigModal