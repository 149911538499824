import React, { useEffect, useState } from "react";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import MDBox from "components/MDBase/MDBox";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import MRTable from "components/MaterialReactTable";
import { useMaterialUIController } from "context/md";
import { getSiteListByGroupID, postUserPreference } from "util/APIHelper";
import { columnDefs, siteTableProcess } from "./tableUtil";
import { useNavigate } from "react-router-dom";
import MenuItem from "@mui/material/MenuItem";
import SiteAddEditModal from "components/Modals/SiteAddEditModal";
import SiteMeterModal from "components/Modals/SiteMeterModal";

function Sites() {
  const [sites, setSites] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const group = params.get("group")
  const [title, setTitle] = useState("Sites");

  const [siteInfo, setSiteInfo] = useState({});
  const [isEdit, setIsEdit] = useState(false);
  const [addSiteModal, setAddSiteModal] = useState(false);

  const handleOpenSiteModal = () => {
    setAddSiteModal(true);
  }
  const handleOpenEditSiteModal = (siteId, siteName) => {
    setSiteInfo({
      id: siteId,
      name: siteName
    });
    setIsEdit(true);
    setAddSiteModal(true);
  }
  const handleCloseSiteModal = () => {
    getData(true)
    setAddSiteModal(false);
    setIsEdit(false);
  };

  const [siteMeterModal, setSiteMeterModal] = useState(false);

  const handleOpenSiteMeterModal = (siteId, siteName) => {
    setSiteInfo({
      id: siteId,
      name: siteName
    });
    setSiteMeterModal(true);
  }
  const handleCloseSiteMeterModal = () => {
    getData(true)
    setSiteMeterModal(false);
  };

  useEffect(() => {
    if (group === null) {
      navigate("/groups")
    } else {
      getData()
    }
  }, []);

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("site_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("site_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "site_column_state",
          value: state
        }
      ])
    }
  }

  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      const response = await getSiteListByGroupID(group)
      const content = response.content?.[0]?.groupDescription;

      if (content) {
        setTitle(`${content} Sites`);
      }
      setSites(siteTableProcess(response.content));

    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title={title} hideSites />
      <MRTable
        columns={columnDefs}
        data={sites}
        isLoading={loading}
        isRefetching={wsLoading}
        onRefresh={getData}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
        customTopRightToolbar={()=> {
          return (user?.isAdminAndAbove() && group!=="0")?(
            <MDBox sx={{margin:"0 0.5rem 1rem 0"}} >
              <MDButton onClick={handleOpenSiteModal} variant="outlined" color="info" sx={{marginRight:"0rem"}}>
                <Icon>add</Icon>&nbsp; Add Site
              </MDButton>
            </MDBox>
          ):null
        }}
        rowActionMenuItems={(user?.isAdminAndAbove())?({row, closeMenu})=> {
          if (row.original.noAction) {
            return []
          } else return [
            <MenuItem key="edit" onClick={() => {
              handleOpenEditSiteModal(row.original.id, row.original.site_name);
              closeMenu();
            }}>
              <Icon fontSize="small" >edit</Icon>&nbsp; Edit Site
            </MenuItem>,

            <MenuItem key="add" onClick={() => {
              handleOpenSiteMeterModal(row.original.id, row.original.site_name);
              closeMenu();
            }}>
              <Icon fontSize="small" >add</Icon>&nbsp; Add/Remove Meters
            </MenuItem>,
          ]
        }:null}
      />
      <SiteAddEditModal handleClose={handleCloseSiteModal} open={addSiteModal} groupId={group} isEdit={isEdit} siteInfo={siteInfo} />
      <SiteMeterModal handleClose={handleCloseSiteMeterModal} open={siteMeterModal} siteInfo={siteInfo} />
    </DashboardLayout>
  );
}

export default Sites;
