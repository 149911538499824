import {useEffect, useState} from "react";
import {getAlarmAnalysisGraph} from "util/APIHelper";
import PropTypes from "prop-types";
import colors from "assets/colors"
import {Card} from "@mui/material";
import PieChart from "components/MDComponents/Charts/PieChart";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";
import { getAlarmColor, shortToLong } from "../../../../../components/AlarmIcon";
const {material} = colors;

function AlarmAnalysisPieChart({timeframe}) {
  const [data, setData] = useState({
    labels: [],
    datasets: {
      label: "",
      backgroundColors: [],
      data: [],
    },});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async () => {
    setLoading(true);
    try {
      if (selectedSites.value.length>0) {
        const site = selectedSites.value.map(e => e.id)
        const response = await getAlarmAnalysisGraph(timeframe, site.join(","), site.includes(-1));
        processData(response.content)
      } else {
        setData({
          labels: [],
          datasets: {
            label: "",
            backgroundColors: [],
            data: [],
          },})
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
    if (!isMounted) {
      setIsMounted(true)
    }
  }

  const processData = (data) => {
    let colorPref = {};
    try {
      if (JSON.parse(sessionStorage.getItem("alarm_color"))) {
        colorPref = JSON.parse(sessionStorage.getItem("alarm_color"));
      }
    } catch (e) {
      console.error(e)
    }
    const alarms = []
    const colors = []
    Object.entries(data).forEach(([key, value]) => {
      if (key !== 'time') {
        if (value.reduce((acc, curr) => acc + curr, 0)>0) {
          let title
          const count = value.reduce((acc, curr) => acc + curr, 0);

          switch (key) {
            case "backflow": title = "Back Flow"; break;
            case "battery_low": title = "Battery Low"; break;
            case "burst": title = "Burst"; break;
            case "faulty_sensor": title = "Faulty Sensor"; break;
            case "leak": title = "Leak"; break;
            case "magnet": title = "Magnet Tamper"; break;
            case "no_flow": title = "No Consumption"; break;
            case "non_realtime_backflow": title = "Non-Realtime Back Flow"; break;
            case "reboot": title = "Reboot"; break;
            case "tilt": title = "Tilt"; break;
            case "wire_cut": title = "Wire Cut"; break;
            default: title = ""; break;
          }

          colors.push(colorPref[shortToLong(key)]??getAlarmColor(key));
          alarms.push({
            title: title,
            count: count
          })
        }
      }
    });

    const processedData = {
      labels: alarms.map(item => item.title),
      datasets: {
        label: "",
        backgroundColors: colors,
        data: alarms.map(item => item.count),
      },
    }

    setData(processedData)
  };

  const getIcon = () => {
    switch (timeframe) {
      case "day": return "today";
      case "week": return "date_range";
      case "month": return "calendar_month";
      default: return "water_drop";
    }
  }

  const getTitle = () => `Past ${timeframe} total alarms`;

  const getDescription = () => `${data.datasets.data.reduce((acc, curr) => acc + curr, 0)} total alarms.`;

  return (
    <Card sx={{marginTop: "1rem"}}>
      <PieChart
        icon={{
          color: "secondary",
          component: getIcon()
        }}
        title={getTitle()}
        height="20rem"
        description={getDescription()}
        chart={data}
        loading={loading}
      />
    </Card>
  );
}

AlarmAnalysisPieChart.propTypes = {
  timeframe: PropTypes.oneOf(["day", "week", "month"])
}

export default AlarmAnalysisPieChart;