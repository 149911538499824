/* eslint-disable react/prop-types */
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import { getFirmwareStatusColor } from "util/LookupUtil";
import MDTypography from "components/MDBase/MDTypography";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";
import React from "react";

export const columnDefs = [
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "File Name", accessorKey: "file_name" },
  {
    header: "Created Timestamp", accessorKey: "created_timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.createdUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.createdUtc, rowB.original.item.createdUtc),
  },
  {
    header: "Completed Timestamp", accessorKey: "completed_timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.modifiedUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.modifiedUtc, rowB.original.item.modifiedUtc),
  },
  { header: "Status", accessorKey: "status",
    Cell: ({ cell, row }) =>
      <MDTypography
        variant="body"
        color={getFirmwareStatusColor(row.original.item)}
      >
        {cell.getValue()}
      </MDTypography>
  }
]

export const firmwareTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      file_name: singular.fileName,
      created_timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.createdUtc))),
      completed_timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.modifiedUtc))),
      status: singular.description
    }

  });
}