import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { getFirmwareStatus, postUserPreference } from "util/APIHelper";
import { columnDefs, firmwareTableProcess } from "layouts/firmware/tableUtil";
import FirmwareProgressModal from "components/Modals/FirmwareProgressModal";
import WebSocketService from "model/WebSocketService";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import useCooldown from "util/hooks/useCooldown";
import MDDatePicker from "components/MDBase/MDDatePicker";
import { InputAdornment } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MDTypography from "components/MDBase/MDTypography";
import { Info } from "@mui/icons-material";
import { formatDateParam } from "util/UtilHelper";

function DetailsFirmware({endpoint}) {
  const [firmwares, setFirmwares] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);

  const [selectedFirmwareProgress, setSelectedFirmwareProgress] = useState({});
  const [firmwareModal, setFirmwareModal] = useState(false);
  const handleOpen = () => setFirmwareModal(true);
  const handleClose = () => setFirmwareModal(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDateChange = (date) => {
    if (date.length === 2) {
      setStartDate(date[0]);

      // Create a new Date object from the end date
      const endDate = new Date(date[1]);

      // Set the hour to 23, minute to 59, and second to 59
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);

      setEndDate(endDate);

      getData(true, date[0], endDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("meter_details_firmware_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const columns = JSON.stringify(item);
    sessionStorage.setItem("meter_details_firmware_column_state", columns);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "meter_details_firmware_column_state",
          value: columns
        }
      ])
    }
  }
  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "FirmwareStatusTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  useEffect(() => {
    getData()
  }, []);

  const getData = async (ws=false, start=null, end=null) => {
    const s = start??startDate;
    const e = end??endDate;

    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      const response = await getFirmwareStatus(
        (s===null)?'2000-01-01T13:00:00Z':formatDateParam(s),
        (e===null)?'2030-01-01T13:00:00Z':formatDateParam(e),
        '',
        true,
        endpoint
      )
      setFirmwares(firmwareTableProcess(response.content));

    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false)
    }
  }
  const throttledGetData = useCooldown(() => {
    getData(true)
  }, 1000)

  const handleRowClick = (data) => {
    setSelectedFirmwareProgress(data.original)
    handleOpen()
  }

  return (
    <>
      <MRTable
        data={firmwares}
        columns={columnDefs}
        isLoading={loading}
        isRefetching={wsLoading}
        onRefresh={getData}
        onRowClick={handleRowClick}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
        customTopLeftToolbar={()=>(
          <MDDatePicker
            input={{
              sx:{width:"15rem", margin:"0 0.5rem 1rem 0.5rem"},
              slotProps:{
                input: {
                  endAdornment: <InputAdornment position="end">
                    <Tooltip title={
                      <MDTypography variant="button" color="white">
                        Large datasets can take
                        <b style={{color:"orangered"}}> longer to load </b>
                        and might <b style={{color:"orangered"}}>slow down</b> performance.
                      </MDTypography>}>
                      <Info fontSize="small" color={
                        (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) > 30 ? "warning" : "secondary"
                      }/>
                    </Tooltip>
                  </InputAdornment>,
                },
              }
            }}
            onChange={handleDateChange}
            placeholder="Date Range"
            range
            options={{
              mode: "range",
              dateFormat: "Y-m-d",
              maxDate: new Date(),
            }}
          />
        )}
      />
      <FirmwareProgressModal handleClose={handleClose} open={firmwareModal} data={selectedFirmwareProgress}/>
    </>
  );
}

DetailsFirmware.propTypes = {
  endpoint: PropTypes.string.isRequired,
}
export default DetailsFirmware;
