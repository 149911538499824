import Card from "@mui/material/Card";
import {CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import {getFirmwareUpdateHistory} from "util/APIHelper";
import TimelineItem from "components/MDComponents/Timeline/TimelineItem";
import TimelineList from "components/MDComponents/Timeline/TimelineList";
import {processTimelineData} from "components/Modals/FirmwareProgressModal/processData";

function FirmwareProgressModal({open, handleClose, data}){
  const [loading, setLoading] = useState(false);
  const [timelineData, setTimelineData] = useState([]);

  useEffect(() => {
    if (open) {
      getData()
    }
  }, [open]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getFirmwareUpdateHistory(data.item.id)
      setTimelineData(processTimelineData(response.content))
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 'auto',
    outline: "none",
    boxShadow: 24,
    p: '1.5rem 0 1.5rem 0',
  };

  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, dateTime, description, lastItem }) => (
      <TimelineItem
        key={title + color}
        color={color}
        icon={icon}
        title={title}
        dateTime={dateTime}
        description={description}
        lastItem={lastItem}
      />
    )
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} variant="h4">
          {data.meter_sn}
        </MDTypography>
        <Divider />
        <MDBox display="flex" sx={{p:'0 1rem 0 1rem'}}>
          <MDBox sx={{maxWidth: '200px', minWidth: '100px'}}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <MDTypography sx={{display:"flex"}} fontWeight="bold" variant="body2"> {`File Name:`}
                </MDTypography>
                <MDTypography variant="body2"> {data.file_name??""}
                </MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDTypography sx={{display:"flex"}} fontWeight="bold" variant="body2"> {`Created Timestamp:`}
                </MDTypography>
                <MDTypography variant="body2"> {data.created_timestamp}
                </MDTypography>
              </Grid>
            </Grid>
          </MDBox>

          <Divider sx={{height:'auto'}} orientation="vertical" flexItem/>

          <MDBox sx={{ minWidth: "10rem", maxHeight: '80vh', overflowX: 'hidden', overflowY: 'auto'}}>
            {
              (loading)?(
                <MDBox display="flex" justifyContent="center">
                  <CircularProgress size="2rem" color="info" />
                </MDBox>
              ):(
                <TimelineList title="">{renderTimelineItems}</TimelineList>
              )
            }
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  )
}

FirmwareProgressModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
}
export default FirmwareProgressModal;