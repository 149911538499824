import { useCallback, useRef } from "react";

const useCooldown = (callback, cooldownTime = 5000) => {
  const cooldownRef = useRef(false);
  const timeoutRef = useRef(null);

  return useCallback(() => {
    if (!cooldownRef.current) {
      callback();
      cooldownRef.current = true;
      timeoutRef.current = setTimeout(() => {
        cooldownRef.current = false;
      }, cooldownTime);
    }
  }, [callback, cooldownTime]);
};

export default useCooldown;