import PropTypes from "prop-types";
import React, {useEffect, useState} from "react";
import { getAlarm, postUserPreference } from "util/APIHelper";
import { columnDefs, detailsAlarmTableProcess } from "layouts/meter/details/alarm/tableUtil";
import WebSocketService from "model/WebSocketService";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBase/MDBox";
import AlarmIcon, { getAlarmTitle } from "components/AlarmIcon";
import MDTypography from "components/MDBase/MDTypography";
import useCooldown from "util/hooks/useCooldown";
import MDDatePicker from "components/MDBase/MDDatePicker";
import { InputAdornment } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import { Info } from "@mui/icons-material";
import { formatDateParam } from "util/UtilHelper";
const alarmsInfo = [
  "leakageDetection",
  "noFlowDetection",
  "burstDetection",
  "backFlowDetection",
  "batteryLow",
  "faultySensor",
  "wireCutDetection",
  "tiltDetection",
  "magnetTamper",
  "nonRealtimeBackFlowDetection",
  "rebootDetection",
];

function DetailsAlarm({endpoint}) {
  const [alarms, setAlarms] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDateChange = (date) => {
    if (date.length === 2) {
      setStartDate(date[0]);

      // Create a new Date object from the end date
      const endDate = new Date(date[1]);

      // Set the hour to 23, minute to 59, and second to 59
      endDate.setHours(23);
      endDate.setMinutes(59);
      endDate.setSeconds(59);

      setEndDate(endDate);

      getData(true, date[0], endDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("meter_details_alarm_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallBack = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("meter_details_alarm_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "meter_details_alarm_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "AlarmTable" && message.action === "Refresh") {
          if (message.content.endpoint === endpoint) {
            throttledGetData()
          }
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  useEffect(() => {
    getData()
  }, []);

  const getData = async (ws=false, start=null, end=null) => {
    const s = start??startDate;
    const e = end??endDate;

    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      const alarmResponse = await getAlarm(
        endpoint,
        (s===null||e===null)?1000:999999,
        formatDateParam(s),
        formatDateParam(e)
      );
      setAlarms(detailsAlarmTableProcess(alarmResponse.content));

    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false)
    }
  }
  const throttledGetData = useCooldown(() => {
    getData(true)
  }, 1000)

  return (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDBox sx={{ margin: "0 1rem 0 1rem", display: "flex", justifyContent: "center", alignContent:"start" }}>
          <Grid container rowSpacing={4} justifyContent="center" alignItems="center">
            {alarmsInfo.map((el, index) => (
              <Grid item xs={4} sm={3} md={2} lg={1} key={index}>
                <MDBox sx={{ px: 0, py: 0, display: "flex", flexDirection: "column", alignItems: "center" }}>
                  <AlarmIcon
                    alarm={el}
                    size="1.35rem"
                    displayOnly="true"
                  />
                  <MDTypography variant="body2" sx={{ mt: 1, textAlign: "center", lineHeight: "1.2" }}>
                    {getAlarmTitle(el)}
                  </MDTypography>
                </MDBox>
              </Grid>
            ))}
          </Grid>
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <MRTable
          data={alarms}
          columns={columnDefs}
          isLoading={loading}
          isRefetching={wsLoading}
          onRefresh={getData}
          onColumnStateChanged={columnStateCallBack}
          initialColumnOrder={columnState.order}
          initialColumnPinning={columnState.pinning}
          initialColumnVisibility={columnState.visibility}
          customTopLeftToolbar={()=>(
            <MDDatePicker
              input={{
                sx:{width:"15rem", margin:"0 0.5rem 1rem 0.5rem"},
                slotProps:{
                  input: {
                    endAdornment: <InputAdornment position="end">
                      <Tooltip title={
                        <MDTypography variant="button" color="white">
                          Large datasets can take
                          <b style={{color:"orangered"}}> longer to load </b>
                          and might <b style={{color:"orangered"}}>slow down</b> performance.
                        </MDTypography>}>
                        <Info fontSize="small" color={
                          (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) > 30 ? "warning" : "secondary"
                        }/>
                      </Tooltip>
                    </InputAdornment>,
                  },
                }
              }}
              onChange={handleDateChange}
              placeholder="Date Range"
              range
              options={{
                mode: "range",
                dateFormat: "Y-m-d",
                maxDate: new Date(),
              }}
            />
          )}
        />
      </Grid>
    </Grid>
  );
}

DetailsAlarm.propTypes = {
  endpoint: PropTypes.string.isRequired,
}
export default DetailsAlarm;
