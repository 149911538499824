import PropTypes from "prop-types";
import {Card} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DefaultLineChart from "components/MDComponents/Charts/LineCharts/DefaultLineChart";
import { checkStatus, formatDate } from "util/UtilHelper";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

const SitesKPIGraph = ({selectedMeters, endpointIntervals, data, groups, isLoading}) => {
  const [chartData, setChartData] = useState({});
  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);
  useEffect(() => {
    if (!isLoading && data.length>0) {
      if (groups.length>0) {
        const siteList = groups.map(group => group.sites.map(site => ({id: site.id, name: site.name, group_id: group.id, group_name: group.name}))).flat()
        setSelectedSites(siteList.slice(0,10));
        setSites(siteList);
        processData(siteList, siteList.slice(0,10));
      }
    }
  }, [isLoading]);

  const processData = (allSites = null, items = null) => {
    let colors = {};
    try {
      if (JSON.parse(sessionStorage.getItem("group_site_color"))) {
        colors = JSON.parse(sessionStorage.getItem("group_site_color"));
      }
    } catch (e) {
      console.error(e)
    }
    const datasets = [];
    (allSites??sites).forEach((site) => {
      if (((items)??selectedSites).find((obj) => obj.id === site.id)) {
        const list = selectedMeters.filter((meter) => meter.site_id === site.id ).map((_)=>_.endpoint)
        const filteredData = data.map((dates) => {
          return {
            ...dates,
            data: dates.data.filter((date_data) => (list.includes(date_data.endpoint)))
          }
        });

        const filteredEndpointIntervals = endpointIntervals.filter((intervals) => list.includes(intervals.endpoint))
        const totalExpected = filteredEndpointIntervals.reduce((acc, obj) => acc + obj.expectedLogCount, 0)
        const totalReceived = filteredData.reduce((acc, obj) =>
          acc + obj.data.reduce((innerAcc, innerObj) => innerAcc + innerObj.count, 0), 0);

        let c = checkStatus(((totalReceived/(totalExpected*filteredData.length)??1)*100).toFixed(2), "info");

        if (colors.sites) {
          if (colors.sites.length > 0) {
            const index = colors.sites.findIndex((obj) => obj.id === site.id);
            if (index > -1) {
              c = colors.sites[index].color
            }
          }
        }

        const subData = {
          label: site.name,
          color: c,
          data: filteredData.map((item) => {
            return (((item.data.reduce((acc, obj) => acc + obj.count, 0))/totalExpected) * 100).toFixed(2)
          })
        }

        datasets.push(subData)
      }
    });

    const processedData = {
      labels: data.map((item) =>
        formatDate(
          localStorage.getItem("datetime-format"),
          new Date(item.date),
          true
        )
      ),
      datasets: datasets
    }

    setChartData(processedData);
  }

  const onChange = (item) => {
    setSelectedSites(item)
    processData(sites, item);
  }

  return (
    <Card>
      {
        useMemo(()=> (
          <DefaultLineChart
            icon={{
              color:"info",
              component: "show_chart"
            }}
            title="Sites KPI Graph"
            height="30rem"
            description=" "
            chart={chartData}
            loading={isLoading}
            legend
            smooth
            extraAction={
              <Autocomplete
                sx={{ paddingLeft: "1rem", minWidth: "11rem", marginRight:"1rem", marginBottom:"1rem", maxWidth:"20rem"}}
                multiple
                limitTags={1}
                groupBy={(option) => option.group_name}
                value={selectedSites}
                options={sites}
                onChange={(event, value) => {
                  onChange(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <MDInput label="Sites" {...params} />}
              />
            }
          />
        ), [chartData, isLoading, sites, selectedSites])
      }
    </Card>
  );
}

SitesKPIGraph.defaultProps = {
  isLoading: false
}

SitesKPIGraph.propTypes = {
  selectedMeters: PropTypes.array.isRequired,
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}
export default SitesKPIGraph;