import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React from "react";
import Grid from "@mui/material/Grid";
import AlarmAnalysisPieChart from "layouts/analysis/alarm/components/AlarmAnalysisPieChart";
import AlarmAnalysisBarChart from "layouts/analysis/alarm/components/AlarmAnalysisBarChart";

function AnalysisAlarm() {
  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <Grid container spacing={2}>
        <Grid xs={12} md={6} item><AlarmAnalysisBarChart timeframe="day" /></Grid>
        <Grid xs={12} md={6} item><AlarmAnalysisPieChart timeframe="day" /></Grid>
        <Grid xs={12} md={6} item><AlarmAnalysisBarChart timeframe="week" /></Grid>
        <Grid xs={12} md={6} item><AlarmAnalysisPieChart timeframe="week" /></Grid>
        <Grid xs={12} md={6} item><AlarmAnalysisBarChart timeframe="month" /></Grid>
        <Grid xs={12} md={6} item><AlarmAnalysisPieChart timeframe="month" /></Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default AnalysisAlarm;