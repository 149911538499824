import React, {useEffect, useState} from "react";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDTypography from "components/MDBase/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBase/MDBox";
import {setDashboardEdit, setDashboardLayout, setReset, useDashboardController} from "context/dashboard";
import {availableWidgets, defaultDashboardLayout} from "util/LookupUtil";
import {v4 as uuidv4} from "uuid";
import {postDashboardLayout} from "util/APIHelper";
import {useMaterialUIController} from "context/md";

function EditRegion() {
  const [dashboardController, dashboardDispatch] = useDashboardController();
  const { layout, edit } = dashboardController;
  const [originalLayout, setOriginalLayout] = useState(layout);
  const [controller,] = useMaterialUIController();
  const {user} = controller;

  const [anchorEl, setAnchorEl] = useState(null);
  const [showWidgetMenu, setShowWidgetMenu] = useState(false);
  const handleWidgetMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
    setShowWidgetMenu(!showWidgetMenu);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setShowWidgetMenu(false);
  };

  const handleAddWidget = (item) => {
    const layoutArr = layout

    layoutArr.push({w: item.w, h: item.h,x: 0,y: Infinity,i: `${item.widget}#${uuidv4()}`})
    setDashboardLayout(dashboardDispatch, layoutArr)
  }

  const renderMenuItems = () => {
    const menuItems = []
    availableWidgets.map((item)=>{
      const exists = layout.some(x => x.i.toLowerCase().split('#')[0] === item.widget.toLowerCase());
      if (!exists) {
        menuItems.push(
          <MenuItem key={uuidv4()} onClick={()=>{handleAddWidget(item)}}>
            <MDTypography variant="body2" color="info" style={{ cursor: 'pointer', margin: '10px 0' }}>
              {item.title}
            </MDTypography>
          </MenuItem>
        );
      }
    });

    if (menuItems.length === 0) {
      menuItems.push(
        <MenuItem key={uuidv4()} disabled>
          <MDTypography variant="body2" color="dark" style={{ margin: '10px 0' }}>
            No Available Widgets
          </MDTypography>
        </MenuItem>
      )
    }

    return menuItems;
  }

  const widgetMenu = () => (
    <Menu
      id="simple-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
      PaperProps={{style: {
          maxHeight: "70vh", marginTop: '1rem', },}}
      anchorOrigin={{vertical: 'bottom',}}
    >
      {renderMenuItems()}
    </Menu>
  );

  const handleReset = async () => {
    await postDashboardLayout(user.email, JSON.stringify(defaultDashboardLayout));
    setReset(dashboardDispatch, uuidv4())
    setDashboardLayout(dashboardDispatch, defaultDashboardLayout);
  }

  return (
    <MDBox sx={{display:"flex", justifyContent:"flex-end"}}>
      {
        (edit &&
          <>
            <Tooltip title="Reset Widgets">
              <MDButton
                color="secondary"
                variant="outlined"
                onClick={handleReset}
                iconOnly
                sx={({palette: { text }}) => ({ color: text.main, marginRight: "1.5rem" })}
              >
                <Icon>restore</Icon>
              </MDButton>
            </Tooltip>
          </>
        )
      }
      {
        (edit &&
          <>
            <Tooltip title="Add Widgets">
              <MDButton
                color="secondary"
                variant="outlined"
                onClick={handleWidgetMenuOpen}
                iconOnly
                sx={({palette: { text }}) => ({ color: text.main, marginRight: "1.5rem" })}
              >
                <Icon>add</Icon>
              </MDButton>
            </Tooltip>
            {widgetMenu()}
          </>
        )
      }
      <Tooltip title={(edit)?"Save Changes": "Edit Dashboard"}>
        <MDButton
          color="secondary"
          variant="outlined"
          onClick={()=>{
            setDashboardEdit(dashboardDispatch, !edit)
            setOriginalLayout(layout);
          }}
          iconOnly
          sx={({palette: { text }}) => ({ color: text.main, marginRight: "1.5rem" })}
        >
          <Icon>{(edit)?"check":"edit"}</Icon>
        </MDButton>
      </Tooltip>
    </MDBox>
  );
}

export default EditRegion;