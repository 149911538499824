/* eslint-disable react/prop-types */
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import Alarm from "model/Alarm";
import AlarmIcon from "components/AlarmIcon";
import MDBox from "components/MDBase/MDBox";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";
import React from "react";

export const columnDefs = [
  {
    header: "Timestamp", accessorKey: "timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.createdUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.createdUtc, rowB.original.item.createdUtc),
  },
  {
    header: "Alarms",
    accessorKey: "alarms",
    size: 2000,
    Cell: ({ cell }) => {
      const alarms = cell.getValue();
      if (!alarms || !alarms.length) {
        return "-";
      }

      return (
        <MDBox sx={{ display: "flex", flexWrap: "wrap", gap: 1 }}>
          {alarms.includes("leakageDetection") && <AlarmIcon alarm="leakageDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("noFlowDetection") && <AlarmIcon alarm="noFlowDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("burstDetection") && <AlarmIcon alarm="burstDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("backFlowDetection") && <AlarmIcon alarm="backFlowDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("batteryLow") && <AlarmIcon alarm="batteryLow" size="1.5rem" triggered={true} />}
          {alarms.includes("faultySensor") && <AlarmIcon alarm="faultySensor" size="1.5rem" triggered={true} />}
          {alarms.includes("wireCutDetection") && <AlarmIcon alarm="wireCutDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("tiltDetection") && <AlarmIcon alarm="tiltDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("magnetTamper") && <AlarmIcon alarm="magnetTamper" size="1.5rem" triggered={true} />}
          {alarms.includes("nonRealtimeBackFlowDetection") && <AlarmIcon alarm="nonRealtimeBackFlowDetection" size="1.5rem" triggered={true} />}
          {alarms.includes("rebootDetection") && <AlarmIcon alarm="rebootDetection" size="1.5rem" triggered={true} />}
        </MDBox>
      );
    },
    filterVariant: "multi-select",
    filterSelectOptions: [
      { text: "Leakage Detection", value: "leakageDetection" },
      { text: "No Flow Detection", value: "noFlowDetection" },
      { text: "Burst Detection", value: "burstDetection" },
      { text: "Backflow Detection", value: "backFlowDetection" },
      { text: "Battery Low", value: "batteryLow" },
      { text: "Faulty Sensor", value: "faultySensor" },
      { text: "Wire Cut Detection", value: "wireCutDetection" },
      { text: "Tilt Detection", value: "tiltDetection" },
      { text: "Magnet Tamper", value: "magnetTamper" },
      { text: "Non-Realtime Backflow Detection", value: "nonRealtimeBackFlowDetection" },
      { text: "Reboot Detection", value: "rebootDetection" },
    ],
  },
]

export const detailsAlarmTableProcess = (rawData) => {
  return rawData.map((singular) => {
    const alarm = new Alarm(singular)

    return {
      item: singular,
      timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.createdUtc))),
      alarms: alarm.getActiveAlarms(),
      alarm: alarm,
    }

  });
}