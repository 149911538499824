import {Card} from "@mui/material";
import DefaultLineChart from "components/MDComponents/Charts/LineCharts/DefaultLineChart";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import {getConsumptionAnalysisGraph} from "util/APIHelper";
import { formatDate, getLastItem } from "util/UtilHelper";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function ConsumptionAnalysisGraph({type, timeframe}) {
  const [data, setData] = useState({
    labels: [],
    x_axis: [],
    datasets: [
      {
        label: "",
        color: "info",
        data: ""
      },
    ],});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async () => {
    setLoading(true);
    try {
      if (selectedSites.value.length>0) {
        const site = selectedSites.value.map(e => e.id)
        const response = await getConsumptionAnalysisGraph(timeframe, type, site.join(","), site.includes(-1));
        processData(response.content)
      } else {
        setData({
          labels: [],
          x_axis: [],
          datasets: [
            {
              label: "",
              color: "info",
              data: ""
            },
          ],})
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
    if (!isMounted) {
      setIsMounted(true)
    }
  }

  const processData = (data) => {
    const processedData = {
      labels: data.time.map(item =>
        formatDate(
          localStorage.getItem("datetime-format"),
          new Date(item),
          timeframe !== "day"
        ).slice(0, (timeframe === "day") ? -3 : 10)
      ),
      x_axis: data.time.map(item => {
        switch (timeframe) {
          case "day": return item.split(" ")[1];
          case "month": return formatDate(
            localStorage.getItem("datetime-format"),
            new Date(item),
            true
          ).slice(0, 5);
          default: return formatDate(
            localStorage.getItem("datetime-format"),
            new Date(item),
            true
          ).slice(0, 10)
        }
      }),
      datasets: [
        {
          label: "Consumption m³",
          color: "info",
          data: data.values.map(item => (item / 1000)),
        },
      ],
    };

    setData(processedData)
  };

  const getIcon = () => {
    switch (timeframe) {
      case "day": return "today";
      case "week": return "date_range";
      case "month": return "calendar_month";
      default: return "water_drop";
    }
  }

  const getTitle = () => `Past ${timeframe} ${type} consumption`;

  const getDescription = (from, to) => `${from} - ${to}`;

  return (
    <Card sx={{marginTop: "1rem"}}>
      <DefaultLineChart
        icon={{
          color: "secondary",
          component: getIcon()
        }}
        title={getTitle()}
        height="20rem"
        description={getDescription((data.labels)?data.labels[0]:'', (data.labels)?getLastItem(data.labels):'')}
        chart={data}
        loading={loading}
      />
    </Card>
  );
}

ConsumptionAnalysisGraph.propTypes = {
  type: PropTypes.oneOf(["total", "average"]),
  timeframe: PropTypes.oneOf(["day", "week", "month"])
}

export default ConsumptionAnalysisGraph;