import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { getMeter, getTrendLogsReport } from "util/APIHelper";
import { columnDefs, kpiMeterTableProcess } from "layouts/analysis/kpi/tableUtil";
import Grid from "@mui/material/Grid2";
import MRTable from "components/MaterialReactTable";
import MDBox from "components/MDBase/MDBox";
import MDDatePicker from "components/MDBase/MDDatePicker";
import { Card, InputAdornment } from "@mui/material";
import Tooltip from "@mui/material/Tooltip";
import MDTypography from "components/MDBase/MDTypography";
import { Info } from "@mui/icons-material";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import { formatDate } from "util/UtilHelper";
import TrendLogTable from "./components/trendLogTable";

function ReportsTrendLogs() {
  const [isLoading, setLoading] = useState(false);
  const [isRetrieving, setRetrieving] = useState(false);
  const [meters, setMeters] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [trendLogRecords, setTrendLogRecords] = useState([]);

  useEffect(() => {
    getData()
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeter({stray:false});
      setMeters(kpiMeterTableProcess(response.content.meters));
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }
  const onRowSelectionChange = (table) => {
    setSelectedMeters(table.getSelectedRowModel().rows.map(it=>it.original));
  }

  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const handleDateChange = (date) => {
    if (date.length === 2) {
      setStartDate(date[0]);
      const endDate = new Date(date[1]);
      endDate.setHours(23, 59, 59, 999);
      setEndDate(endDate);
    } else {
      setStartDate(null);
      setEndDate(null);
    }
  };
  const checkDisable = () => {
    return !!((startDate && endDate && endDate < startDate) ||
      !startDate ||
      !endDate ||
      isLoading || selectedMeters.length === 0 );
  }

  const handleSubmit = async () => {
    setRetrieving(true);
    try {
      const response = await getTrendLogsReport(
        formatDate("YYYY-MM-DD",startDate,true),
        formatDate("YYYY-MM-DD",endDate,true),
        selectedMeters.map(it=>it.endpoint)
      );

      if (response.success) {
        const trendLogResponse = response.content.map((item) => {
          let selected = {}
          if (selectedMeters.find((it) => it.endpoint === item.endpoint)) {
            selected = selectedMeters.find((it) => it.endpoint === item.endpoint)
          }
          return {
            ...selected,
            ...item
          }
        });

        setTrendLogRecords(trendLogResponse);
      }
    } catch (e) {
      console.error(e)
    }
    setRetrieving(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title="Trend Logs" hideSites/>
      <Grid container spacing={3}>
        <Grid size={{xs:12}} >
          <Card>
            <MRTable
              data={meters}
              columns={columnDefs}
              isLoading={isLoading}
              isRefetching={isRetrieving}
              onRefresh={getData}
              enableRowSelection
              onRowSelectionChange={onRowSelectionChange}
              customTopLeftToolbar={()=>(
                <MDBox display="flex" alignItems="center" mb={2}>
                  <MDDatePicker
                    input={{
                      sx:{width:"15rem", margin:"0 0.5rem 0rem 0.5rem"},
                      slotProps:{
                        input: {
                          endAdornment: <InputAdornment position="end">
                            <Tooltip title={
                              <MDTypography variant="button" color="white">
                                Large datasets can take
                                <b style={{color:"orangered"}}> longer to load </b>
                                and might <b style={{color:"orangered"}}>slow down</b> performance.
                              </MDTypography>}>
                              <Info fontSize="small" color={
                                (new Date(endDate) - new Date(startDate)) / (1000 * 60 * 60 * 24) > 30 ? "warning" : "secondary"
                              }/>
                            </Tooltip>
                          </InputAdornment>,
                        },
                      }
                    }}
                    onChange={handleDateChange}
                    placeholder="Date Range"
                    range
                    options={{
                      mode: "range",
                      dateFormat: "Y-m-d",
                      maxDate: new Date(),
                    }}
                  />
                  <MDBox ml={2}>
                    <MDButton disabled={checkDisable()} onClick={handleSubmit} variant="outlined" color="info">
                      <Icon>download</Icon>&nbsp; Generate
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
            />
          </Card>
        </Grid>
        <Grid size={{xs:12}}>
          <TrendLogTable
            data={trendLogRecords}
            isLoading={isRetrieving}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ReportsTrendLogs;