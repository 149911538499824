import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";

import React, { PureComponent } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import {Card} from "@mui/material";
import LineChartExample from "layouts/test/charts/components/LineChart";
import ChartJSLine from "layouts/test/charts/components/ChartJSLine";
import PieChart from "components/MDComponents/Charts/PieChart";
import pieChartData from "layouts/example/pages/charts/data/pieChartData";
const data = [
  {
    name: 'Page A',
    uv: 4000,
    pv: 2400,
    amt: 2400,
  },
  {
    name: 'Page B',
    uv: 3000,
    pv: 1398,
    amt: 2210,
  },
  {
    name: 'Page C',
    uv: 2000,
    pv: 9800,
    amt: 2290,
  },
  {
    name: 'Page D',
    uv: 2780,
    pv: 3908,
    amt: 2000,
  },
  {
    name: 'Page E',
    uv: 1890,
    pv: 4800,
    amt: 2181,
  },
  {
    name: 'Page F',
    uv: 2390,
    pv: 3800,
    amt: 2500,
  },
  {
    name: 'Page G',
    uv: 3490,
    pv: 4300,
    amt: 2100,
  },
];

function ChartsTest() {
  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        <LineChartExample/>
      </Card>

      <Card sx={{margin:"2rem 0 2rem 0", padding: "2rem", height:"30rem"}}>
        <ChartJSLine/>
      </Card>
    </DashboardLayout>
  );
}

export default ChartsTest;