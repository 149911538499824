import React, { useState } from "react";
import PropTypes from "prop-types";
import DataTextView from "./util";
import MDBox from "../../components/MDBase/MDBox";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import MDTypography from "../../components/MDBase/MDTypography";
import MDInput from "../../components/MDBase/MDInput";
import Divider from "@mui/material/Divider";
import { useMaterialUIController } from "../../context/md";

import { alpha } from "@mui/material/styles";

function SearchView({ nodes, network }) {
  const [{ darkMode }] = useMaterialUIController();

  const [searchTerm, setSearchTerm] = useState("");
  const [selectedNode, setSelectedNode] = useState(null);

  function onClickClose() {
    setSelectedNode(null);
  }

  const handleSearchInput = (event) => {
    console.log(event.target.value.toLowerCase());
    setSearchTerm(event.target.value.toLowerCase());
  };

  const handleNodeClick = (node) => {
    setSelectedNode(node);
    network.selectNodes([node.endpointId]);
  };

  const filteredNodes = nodes.filter(node =>
    node.endpoint.toLowerCase().includes(searchTerm),
  );

  const searchViewWidth = 250;
  const infoViewWidth = 350;

  const bg = darkMode? "#282B33" : "#f0f2f5";

  return (
    <MDBox sx={{
      backgroundColor: alpha(bg, 0.8),
      borderRadius: '16px',
      display: "flex", flexDirection: "row", overflowY: "auto", height: "100%", width: "100%",
    }}>
      <MDBox id="nodeList" sx={{
        p: 1, display: "flex", flexDirection: "column", overflowY: "auto", height: "100%", minWidth: searchViewWidth, maxWidth: searchViewWidth  }}>
        {/* search bar*/}
        <MDInput
          type="search"
          placeholder="Search endpoint..."
          value={searchTerm}
          onChange={handleSearchInput}
        />
        <MDTypography sx={{ p: 1, fontSize: "14px" }}>
          Showing {filteredNodes.length} of {nodes.length}
        </MDTypography>

        <Divider sx={{ m: 0 }} />
        {/* item list */}
        <List sx={{ mt: 1 }}>
          {filteredNodes.map(node => (
            <ListItem
              key={node.endpointId}
              button // Make the entire ListItem clickable
              onClick={() => handleNodeClick(node)}
              selected={selectedNode && selectedNode.endpointId === node.endpointId}
              sx={{
                color: "red",
                borderRadius: 1,
                "&.Mui-selected": {
                  backgroundColor: (theme) => theme.palette.info.main,
                  "& .MuiTypography-root": { // Target the typography inside the selected ListItem
                    color: "#f2f2f2", // Set the desired text color for the selected state
                  },
                },
              }}
            >
              <MDTypography
                sx={{ pt: 1, pb: 1, pl: 2, pr: 2, fontSize: 15 }}>{node.parentEndpointId ? node.endpoint : `${node.endpoint} (Gateway)`}</MDTypography>
            </ListItem>
          ))}
        </List>
      </MDBox>
      <MDBox id="infoView" sx={{
        pl: 5, display: "flex", flexDirection: "column", overflowY: "auto", height: "100%", minWidth: selectedNode === null ? 0 : infoViewWidth, maxWidth: infoViewWidth, position: "relative" }}>
        {/* info view */}
        <DataTextView data={selectedNode} onClickClose={onClickClose} />
      </MDBox>
    </MDBox>
  );
}

SearchView.propTypes = {
  nodes: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    isGateway: PropTypes.bool,
    nodeInfo: PropTypes.shape({
      identifier: PropTypes.string.isRequired,
    }).isRequired,
  })).isRequired,
  network: PropTypes.shape({
    selectNodes: PropTypes.func.isRequired,
  }).isRequired,
};

export default SearchView;
