// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// MD React components
import MDInput from "components/MDBase/MDInput";
import { DatePicker } from "@mui/x-date-pickers";
import React from "react";

function MonthYearPicker({ input, placeholder, ...rest }) {
  return (
    <DatePicker
      views={['year', 'month']}
      label="Year and Month"
      sx={{width:"10.8rem"}}
      {...rest}
      render={({ defaultValue }, ref) => (
        <MDInput {...input} defaultValue={defaultValue} placeholder={placeholder} inputRef={ref} />
      )}
    />
  );
}

// Setting default values for the props of MDDatePicker
MonthYearPicker.defaultProps = {
  placeholder: "",
  input: {},
};

// Typechecking props for the MDDatePicker
MonthYearPicker.propTypes = {
  placeholder: PropTypes.string,
  input: PropTypes.objectOf(PropTypes.any),
};

export default MonthYearPicker;
