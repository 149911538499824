import PropTypes from "prop-types";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBase/MDBox";
import React, {useEffect, useState} from "react";
import {getFirmwareFiles} from "util/APIHelper";
import Autocomplete from "@mui/material/Autocomplete";
import MDInput from "components/MDBase/MDInput";
import QueueFirmwareModal from "components/Modals/QueueFirmwareModal";
import MDTypography from "components/MDBase/MDTypography";
import SuccessRateCell from "components/TableCells/FirmwareTable/SuccessRateCell";

function FirmwareQueueSubmit({selectedMeters}) {
  const [firmwareFiles, setFirmwareFiles] = useState([]);
  const [selectedFirmware, setSelectedFirmware] = useState(null);

  const [queueFirmwareModal, setQueueFirmwareModal] = useState(false);
  const handleOpenModal = () => {
    if (selectedMeters.length>0 && selectedFirmware) {
      setQueueFirmwareModal(true);
    }
  }
  const handleCloseModal = () => setQueueFirmwareModal(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    try {
      const response = await getFirmwareFiles();
      setFirmwareFiles(response.content);
    } catch (e) {
      console.error(e)
    }
  }

  return (
    <MDBox sx={{margin:"0 0 1rem 0.5rem", display:"flex"}} >
      <Autocomplete
        options={firmwareFiles}
        value={selectedFirmware}
        onChange={(event, value) => {
          setSelectedFirmware(value);
        }}
        isOptionEqualToValue={(option, value) =>
          option.fileName === value.fileName
        }
        getOptionLabel={(option) => option.fileName}
        renderInput={(params) => <MDInput label="Firmware File" {...params} />}
        renderOption={(props, option) => (
          <MDBox {...props} >
            <MDBox display="flex"
                   justifyContent="space-between"
                   alignItems="center"
                   sx={{width:"100%"}}>
              <MDTypography variant="body" color="text">
                {option.fileName}
              </MDTypography>
              <SuccessRateCell success_rate={((option.updateSuccessCount/((option.updateCount===0)?1:option.updateCount))*100).toFixed(2)}/>
            </MDBox>
          </MDBox>
        )}
        sx={{
          width:"17rem",
          '& .MuiOutlinedInput-root': {
            height: '2.5rem'
          },
          marginRight:"1rem"
        }}
        size="small"
      />
      <MDButton onClick={handleOpenModal} variant="outlined" color="info">
        <Icon>queue</Icon>&nbsp; Queue Firmware Update
      </MDButton>

      <QueueFirmwareModal
        selectedFirmwareFile={selectedFirmware}
        handleClose={handleCloseModal}
        selectedMeters={selectedMeters}
        open={queueFirmwareModal}
      />
    </MDBox>
  )
}

FirmwareQueueSubmit.propTypes = {
  selectedMeters: PropTypes.array.isRequired
}

export default FirmwareQueueSubmit;