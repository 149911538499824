/* eslint-disable react/prop-types */
import { epochToTimestamp } from "util/UtilHelper";
import DescriptionCell from "components/TableCells/General/DescriptionCell";
import SuccessRateCell from "components/TableCells/FirmwareTable/SuccessRateCell";

export const columnDefs = [
  { header: "File Name", accessorKey: "file_name", },
  { header: "Description", accessorKey: "description",
    Cell: ({ cell }) => <DescriptionCell description={cell.getValue()} /> },
  { header: "Update Count", accessorKey: "update_count", },
  { header: "Update Failed", accessorKey: "update_failed", },
  { header: "Update Success", accessorKey: "update_success", },
  { header: "Success Rate", accessorKey: "success_rate",
    Cell: ({ cell }) => <SuccessRateCell success_rate={cell.getValue()}/> },
  { header: "Created", accessorKey: "created", },
]

export const firmwareFilesTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      file_name: singular.fileName,
      description: singular.description,
      update_count: singular.updateCount,
      update_failed: singular.updateFailCount,
      update_success: singular.updateSuccessCount,
      success_rate: ((singular.updateSuccessCount / ((singular.updateCount === 0) ? 1 : singular.updateCount)) * 100).toFixed(2),
      created: epochToTimestamp(singular.createdUtc)
    }
  });
}