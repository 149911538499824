const NODE_GROUPS = {
  Server: { color: { background: "#D37676", highlight: { background: "#45974d", border: "#88e193" } } },
  Gateway: { color: { background: "#878787", highlight: { background: "#45974d", border: "#88e193" } } },
  Node: {
    color: {
      background: "#51829B",
      highlight: {
        background: "#45974d", border: "#88e193",
      },
    },
  },
};

export const OPTIONS = {
  layout: {
    // improvedLayout: nodes <= 150,
    randomSeed: "0.5530197825241683:1710380568327", // preferred seed to generate trees as separate as possible
    hierarchical: {
      enabled: false,
      levelSeparation: 150,
      nodeSpacing: 100,
      treeSpacing: 200,
      blockShifting: true,
      edgeMinimization: false,
      parentCentralization: true,
      direction: "UD",        // UD, DU, LR, RL
      sortMethod: "directed",  // hubsize, directed
      shakeTowards: "roots",  // roots, leaves
    },
  },
  nodes: {
    borderWidthSelected: 5,
    color: {
      background: "#4285F4",
    }, borderWidth: 0, opacity: 1, shape: "box",
    font: "14px arial white ",
  },
  edges: {
    color: "#666666",
    smooth: {
      type: "continuous", // Use continuous curves for smooth edges
    },
  },
  // https://visjs.github.io/vis-network/docs/network/physics.html#
  physics: {
    // enabled: document.getElementById('toggleButton').textContent === 'Physics: Enabled',
    maxVelocity: 15,
    timestep: 0.1,
    barnesHut: {
      gravitationalConstant: -2000,
      centralGravity: 0.3,
      springLength: 95,
      springConstant: 0.04,
      damping: 0.09,
      avoidOverlap: 0.5, // Adjust this value to control overlap avoidance
    },
  },
  groups: NODE_GROUPS,
  // autoResize: true,
  // height: '100%',
  // width: '100%'
};
