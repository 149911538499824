// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Divider from "@mui/material/Divider";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import {Skeleton} from "@mui/material";

function StatisticsCard({ color, title, count, percentage, icon, iconWidget, isLoading }) {
  return (
    <Card>
      <MDBox display="flex" justifyContent="space-between" pt={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor={color}
          color={color === "light" ? "dark" : "white"}
          coloredShadow={color}
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          mt={-3}
        >
          {
            (iconWidget && !isLoading)?iconWidget:
              <Icon fontSize="medium" color="inherit">
                {icon}
              </Icon>
          }
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            {title}
          </MDTypography>
          {
            (isLoading)?
              <Skeleton sx={{marginTop: "0.4rem"}} variant="rounded" height={"1.5rem"}/>:
              <MDTypography variant="h4">{count}</MDTypography>
          }
        </MDBox>
      </MDBox>
      <Divider />
      <MDBox pb={2} px={2}>
        {
          (isLoading)?
            <Skeleton variant="rounded"/>:
            <MDTypography component="p" variant="button" color="text" display="flex">
              <MDTypography
                component="span"
                variant="button"
                fontWeight="bold"
                color={percentage.color}
              >
                {percentage.amount}
              </MDTypography>
              &nbsp;{percentage.label}
            </MDTypography>
        }
      </MDBox>
    </Card>
  );
}

// Setting default values for the props of ComplexStatisticsCard
StatisticsCard.defaultProps = {
  color: "info",
  percentage: {
    color: "success",
    text: "",
    label: "",
  },
  isLoading: false,
  iconWidget: null
};

// Typechecking props for the ComplexStatisticsCard
StatisticsCard.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "light",
    "dark",
  ]),
  title: PropTypes.string.isRequired,
  count: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  percentage: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "dark",
      "white",
    ]),
    amount: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string,
  }),
  icon: PropTypes.node.isRequired,
  isLoading: PropTypes.bool,
  iconWidget: PropTypes.node
};

export default StatisticsCard;
