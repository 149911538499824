/* eslint-disable react/prop-types */
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";

export const columnDefs = [
  { header: "Endpoint", accessorKey: "endpoint", hide: true },
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "Year", accessorKey: "year", },
  { header: "Month", accessorKey: "month", },
  { header: "Consumption (m³)", accessorKey: "consumption", },
  { header: "MIU S/N", accessorKey: "miu_sn", hide: true },
  { header: "Group", accessorKey: "group_name" },
  { header: "Site", accessorKey: "site_name" },
  { header: "Custom 1", accessorKey: "custom_1", hide: true },
  { header: "Custom 2", accessorKey: "custom_2", hide: true },
  { header: "Custom 3", accessorKey: "custom_3", hide: true },
  { header: "Custom 4", accessorKey: "custom_4", hide: true },
  { header: "Custom 5", accessorKey: "custom_5", hide: true },
]

export const monthlyConsumptionTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      year: singular.year,
      month: singular.month,
      consumption: (singular.consumption / 1000).toFixed(4),
      group_name: singular.group_name??"",
      site_name: singular.site_name??"",
      custom_1: singular.custom_1,
      custom_2: singular.custom_2,
      custom_3: singular.custom_3,
      custom_4: singular.custom_4,
      custom_5: singular.custom_5,
    }
  });
}