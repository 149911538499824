// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import {v4 as uuidv4} from "uuid";
import AlarmIcon from "components/AlarmIcon";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

function MeterAlarmCell({ data }) {

  return (
    <MDBox>
      <Grid container style={{ display: 'flex'}}>
        {
          (data.getActiveAlarms().length>0)?(
            data.getActiveAlarms().map((alarm)=>{
              return (
                <Grid key={uuidv4()} item>
                  <AlarmIcon size="1.5rem" alarm={alarm} triggered/>
                </Grid>
              )
            })
          ):(
            <MDTypography variant="body" color="text">No active alarms</MDTypography>
          )
        }
      </Grid>
    </MDBox>
  );
}

// Setting default value for the props of CustomerCell
MeterAlarmCell.defaultProps = {
  data: {}
};

// Typechecking props for the CustomerCell
MeterAlarmCell.propTypes = {
  data: PropTypes.object
};

export default MeterAlarmCell;
