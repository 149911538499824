import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React, { useEffect, useState } from "react";
import { getMeter, getMonthlyConsumptionReport } from "util/APIHelper";
import { columnDefs, kpiMeterTableProcess } from "layouts/analysis/kpi/tableUtil";
import Grid from "@mui/material/Grid2";
import MRTable from "components/MaterialReactTable";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import { Card } from "@mui/material";
import MonthYearPicker from "components/Inputs/MonthYearPicker";
import dayjs from "dayjs";
import MonthlyConsumptionLogTable from "./components/monthlyConsumptionLogTable";

function ReportsMonthlyConsumption() {
  const [isLoading, setLoading] = useState(false);
  const [isRetrieving, setRetrieving] = useState(false);
  const [meters, setMeters] = useState([]);
  const [selectedMeters, setSelectedMeters] = useState([]);
  const [monthlyConsumptionRecords, setMonthlyConsumptionRecords] = useState([]);

  useEffect(() => {
    getData()
  }, []);
  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeter({stray:false});
      setMeters(kpiMeterTableProcess(response.content.meters));
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }
  const onRowSelectionChange = (table) => {
    setSelectedMeters(table.getSelectedRowModel().rows.map(it=>it.original));
  }

  const [startDate, setStartDate] = useState(
    dayjs(new Date(new Date().getFullYear(), 1, 1).toISOString().split('T')[0])
  );
  const [endDate, setEndDate] = useState(
    dayjs(new Date().toISOString().split('T')[0])
  );
  const handleStartDateChange = (date) => {
    let end = endDate;
    setStartDate(date);
    if (date.toDate() > endDate.toDate()) {
      end = date;
    }
    setEndDate(end);
  }

  const handleEndDateChange = (date) => {
    setEndDate(date);
  }
  const checkDisable = () => {
    return !!((startDate && endDate && endDate < startDate) ||
      !startDate ||
      !endDate ||
      isLoading || selectedMeters.length === 0 );
  }

  const handleSubmit = async () => {
    setRetrieving(true);
    try {
      const startT = startDate.format("YYYY-MM-01");
      const endT = endDate.format("YYYY-MM-01");

      const response = await getMonthlyConsumptionReport(startT, endT, selectedMeters.map(it=>it.endpoint));

      if (response.success) {
        const newObject = selectedMeters.reduce((acc, obj) => {
          acc[obj.endpoint] = obj; // Use the endpoint as the key
          return acc;
        }, {});

        const data = response.content.map((obj) => {
          return {
            ...newObject[obj.endpoint],
            ...obj
          }
        });
        setMonthlyConsumptionRecords(data);
      }
    } catch (e) {
      console.error(e)
    }
    setRetrieving(false);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title="Monthly Consumption" hideSites/>
      <Grid container spacing={3}>
        <Grid size={{xs:12}} >
          <Card>
            <MRTable
              data={meters}
              columns={columnDefs}
              isLoading={isLoading}
              isRefetching={isRetrieving}
              onRefresh={getData}
              enableRowSelection
              onRowSelectionChange={onRowSelectionChange}
              customTopLeftToolbar={()=>(
                <MDBox display="flex" alignItems="center" ml={1.3} mb={1}>
                  <MonthYearPicker
                    label="Start Month"
                    onChange={handleStartDateChange}
                    value={dayjs(startDate.toDate().toISOString().split('T')[0])}
                    minDate={dayjs('2020-01-01')}
                    maxDate={dayjs(new Date().toISOString().split('T')[0])}
                  />
                  <MDTypography variant="body2" fontWeight="light" color="text">
                    &nbsp; to &nbsp;
                  </MDTypography>
                  <MonthYearPicker
                    label="End Month"
                    onChange={handleEndDateChange}
                    value={dayjs(endDate.toDate().toISOString().split('T')[0])}
                    minDate={(startDate)?(dayjs(startDate.toDate().toISOString().split('T')[0])):null}
                    maxDate={dayjs(new Date().toISOString().split('T')[0])}
                  />
                  <MDBox ml={2}>
                    <MDButton disabled={checkDisable()} onClick={handleSubmit} variant="outlined" color="info">
                      <Icon>download</Icon>&nbsp; Generate
                    </MDButton>
                  </MDBox>
                </MDBox>
              )}
            />
          </Card>
        </Grid>
        <Grid size={{xs:12}} >
          <MonthlyConsumptionLogTable
             data={monthlyConsumptionRecords}
             isLoading={isRetrieving}
          />
        </Grid>
      </Grid>
    </DashboardLayout>
  );
}

export default ReportsMonthlyConsumption;