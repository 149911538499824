import React, { useEffect, useState } from "react";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import { getFirmwareFiles, postUserPreference } from "util/APIHelper";
import { columnDefs, firmwareFilesTableProcess } from "layouts/firmware/Files/tableUtil";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import MDBox from "components/MDBase/MDBox";
import UploadFirmwareFileModal from "components/Modals/UploadFirmwareFileModal";
import WebSocketService from "model/WebSocketService";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import useCooldown from "util/hooks/useCooldown";

function FirmwareFiles() {
  const [firmwareFiles, setFirmwareFiles] = useState([])
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);

  const [uploadFirmwareFileModal, setUploadFirmwareFileModal] = useState(false);
  const handleOpenUploadFirmwareFileModal = () => setUploadFirmwareFileModal(true);
  const handleCloseUploadFirmwareFileModal = () => setUploadFirmwareFileModal(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("firmware_file_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("firmware_file_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "firmware_file_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "FirmwareFileTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  useEffect(() => {
    getData()
  }, []);

  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      const response = await getFirmwareFiles()
      setFirmwareFiles(firmwareFilesTableProcess(response.content));

    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false)
    }
  }
  const throttledGetData = useCooldown(() => {
    getData(true)
  }, 1000)

  return (
    <DashboardLayout>
      <DashboardNavbar hideSites />
      <MRTable
        data={firmwareFiles}
        columns={columnDefs}
        isLoading={loading}
        isRefetching={wsLoading}
        onRefresh={getData}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
        customTopRightToolbar={()=>(
          <MDBox sx={{margin:"0 0.5rem 1rem 0"}} >
            <MDButton onClick={handleOpenUploadFirmwareFileModal} variant="outlined" color="info">
              <Icon>upload</Icon>&nbsp; Upload File
            </MDButton>
          </MDBox>
        )}
      />
      <UploadFirmwareFileModal handleClose={handleCloseUploadFirmwareFileModal} open={uploadFirmwareFileModal} />
    </DashboardLayout>
  );
}

export default FirmwareFiles;
