import axios from "axios";

//#region DEFAULTS
const instance = axios.create({
  baseURL: process.env.REACT_APP_API_URL
});

const handleRequest = async (requestPromise) => {

  try {
    return (await requestPromise).data;
  } catch (error) {
    if (error.response?.status === 401) { window.location.href = `${(process.env.REACT_APP_URL!=="/")?process.env.REACT_APP_URL:""}/logout`; }
    else console.error("Request failed:", error.message);
    throw error;
  }
};

const headers = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem("_auth")}`
  };
};
//#endregion


//#region AUTH
export const login = (email, password) => {
  const body = {
    email: email,
    password: password
  }

  return instance.post("auth/login", body)
}

export const postResetPassword = (email) => instance.post('auth/user/resetPassword',{email:email})

export const getUsers = () => handleRequest(instance.get('auth/user',{headers: headers()}))

export const getUserRoles = () => handleRequest(instance.get('auth/user/roles', { headers: headers() }))

export const postCreateUser = (email, access) =>
  handleRequest(instance.post("auth/user", {
    email:email,
    roleId:access
  }, { headers: headers() }))

export const putUpdateUser = (active, dateFormat, roleId, userId, sites) =>
  handleRequest(
    instance.put(
      'auth/user',
      {
        active: active,
        dateFormat: dateFormat,
        roleId: roleId,
        userId: userId,
        sites: sites
      },
      {headers: headers()}
    )
  )

export const putUpdateDatetime = (dateFormat, userId) =>
  handleRequest(
    instance.put(
      'auth/user/datetime',
      {
        dateFormat: dateFormat,
        userId: userId
      },
      {headers: headers()}
    )
  )

export const postChangePassword = (email, oldPassword, newPassword) =>
  handleRequest(
    instance.post(
      'auth/user/changePassword',
      {
        email: email,
        oldPassword: oldPassword,
        newPassword: newPassword
      },
      {headers: headers()}
    )
  )
//#endregion


//#region DATA
export const getMeterDetail = (endpoint) =>
  handleRequest(
    instance.get(`data/meter/${encodeURIComponent(endpoint)}`, {headers: headers()})
  )

export const getReading = (endpoint, itemCount, startTimestamp, endTimestamp, sorting) =>
  handleRequest(
    instance.get(
      `data/meter/${encodeURIComponent(endpoint)}/reading` +
      `?itemCount=${(itemCount)?itemCount:1000}` +
      `${(startTimestamp)?`&startTimestamp=${startTimestamp}`:''}` +
      `${(endTimestamp)?`&endTimestamp=${endTimestamp}`:''}` +
      `${(sorting)?`&sorting=${sorting}`: ''}`,
      {headers: headers()}
    )
  )

export const getStatus = (endpoint, itemCount, startTimestamp, endTimestamp, sorting) =>
  handleRequest(
    instance.get(
      `data/meter/${encodeURIComponent(endpoint)}/status` +
      `?itemCount=${(itemCount)?itemCount:1000}` +
      `${(startTimestamp)?`&startTimestamp=${startTimestamp}`:''}` +
      `${(endTimestamp)?`&endTimestamp=${endTimestamp}`:''}` +
      `${(sorting)?`&sorting=${sorting}`: ''}`,
      {headers: headers()}
    )
  )

export const getAlarm = (endpoint, itemCount, startTimestamp, endTimestamp, sorting) =>
  handleRequest(
    instance.get(
      `data/meter/${encodeURIComponent(endpoint)}/alarm` +
      `?itemCount=${(itemCount)?itemCount:1000}` +
      `${(startTimestamp)?`&startTimestamp=${startTimestamp}`:''}` +
      `${(endTimestamp)?`&endTimestamp=${endTimestamp}`:''}` +
      `${(sorting)?`&sorting=${sorting}`: ''}`,
      {headers: headers()}
    )
  )

export const getMonthlyConsumptionLogs = (endpoint, startTimestamp, endTimestamp) =>
  handleRequest(
    instance.get(
      `data/meter/${encodeURIComponent(endpoint)}/monthlyConsumptionLogs` +
      `?startTimestamp=${startTimestamp}` +
      `&endTimestamp=${endTimestamp}`,
      {headers: headers()}
    )
  )

export const getUploadedMeterDetail = (endpoint) =>
  handleRequest(instance.get(`data/uploaded/${encodeURIComponent(endpoint)}`, {headers: headers()}))

export const getUploadedMeterTemplate = () =>
  handleRequest(instance.get('data/uploadedTemplate', {headers: headers()}))

export const getMeter = ({ itemCount, page, sorting, group, site, stray }={}) =>
  handleRequest(
    instance.get(
      'data/meters' +
      `?itemCount=${itemCount?itemCount:999999}` +
      `${(page)?`&page=${page}`:''}` +
      `${(group)?`&group=${group}`:''}` +
      `${(site)?`&site=${site}`:''}` +
      `${(stray!==null)?`&stray=${stray}`:''}` +
      `${(sorting)?`&sorting=${sorting}`:''}`,
      {headers: headers()}
    )
  )

export const postCreateMeter = (meters) =>
  handleRequest(instance.post('data/meters', {meters:meters}, {headers:headers()}))

export const getDetailsGraph = (endpoint, variant, endTimestamp, type) => {
  let url = "data/consumption"
  if (variant === "anomaly") {
    url = "anomaly/graph"
  }
  return handleRequest(instance.get(`${url}?endpoint=${encodeURIComponent(endpoint)}&type=${type}&endTimestamp=${endTimestamp}`, {headers: headers()}))
}

export const getInsightConsumption = (endpoint, type) =>
  handleRequest(instance.get(`data/insight/consumption/${encodeURIComponent(endpoint)}/${type}`, {headers: headers()}))

export const getConsumptionAnalysisGraph = (timeframe, type, sites, stray) =>
  handleRequest(
    instance.get(
      `data/analysis/consumption?timeframe=${timeframe}&type=${type}` +
      `&site=${sites}` +
      `&stray=${stray}`,
      {headers: headers()}
    )
  )

export const getAlarmAnalysisGraph = (timeframe, sites, stray) =>
  handleRequest(
    instance.get(
      `data/analysis/alarm?timeframe=${timeframe}` +
      `&site=${sites}` +
      `&stray=${stray}`,
      {headers: headers()}
    )
  )

export const getKPIAnalysis = (start, end, meters) =>
  handleRequest(instance.post('data/analysis/kpi', {startTimestamp:start, endTimestamp: end, endpoints: meters}, {headers:headers()}))

export const getMeterBatteryAnalysis = (start, end, endpoint) =>
  handleRequest(instance.get(`data/meter/analysis/battery?endpoint=${encodeURIComponent(endpoint)}&startTimestamp=${start}&endTimestamp=${end}`, {headers:headers()}))

export const getMeterTemperatureAnalysis = (start, end, endpoint) =>
  handleRequest(instance.get(`data/meter/analysis/temperature?endpoint=${encodeURIComponent(endpoint)}&startTimestamp=${start}&endTimestamp=${end}`, {headers:headers()}))

export const getMeterReadingAnalysis = (start, end, endpoint) =>
  handleRequest(instance.get(`data/meter/analysis/reading?endpoint=${encodeURIComponent(endpoint)}&startTimestamp=${start}&endTimestamp=${end}`, {headers:headers()}))

export const getMeterSignalAnalysis = (start, end, endpoint) =>
  handleRequest(instance.get(`data/meter/analysis/signal?endpoint=${encodeURIComponent(endpoint)}&startTimestamp=${start}&endTimestamp=${end}`, {headers:headers()}))

export const getMeterAlarmAnalysis = (start, end, endpoint) =>
  handleRequest(instance.get(`data/meter/analysis/alarm?endpoint=${encodeURIComponent(endpoint)}&startTimestamp=${start}&endTimestamp=${end}`, {headers:headers()}))


//#endregion

//#region REPORT
export const getMonthlyConsumptionReport = (start, end, meters) =>
  handleRequest(instance.post('report/monthlyConsumption', {startTimestamp:start, endTimestamp: end, endpoints: meters}, {headers:headers()}))

export const getTrendLogsReport = (start, end, meters) =>
  handleRequest(instance.post('report/trendLogs', {startTimestamp:start, endTimestamp: end, endpoints: meters}, {headers:headers()}))

//#endregion

//#region GROUP/SITE
export const getAllGroup = () =>
  handleRequest(
    instance.get(`group/all`, {headers: headers()})
  )

export const getGroupList = () =>
  handleRequest(
    instance.get(`group/list`, {headers: headers()})
  )

export const postCreateGroup = (groupName) =>
  handleRequest(
    instance.post(
      'group/create',
      {
        groupName: groupName
      },
      {headers: headers()}
    )
  )

export const putUpdateGroup = (groupId, groupName) =>
  handleRequest(
    instance.put(
      `group/update`,
      {
        groupId: groupId,
        groupName: groupName
      },
      {headers: headers()}
    )
  )

export const deleteGroup = (groupID) =>
  handleRequest(instance.delete(`group/delete/${groupID}`,
      {headers: headers()}
    )
  )

export const getSelfSites = () =>
  handleRequest(
    instance.get(`site/list`, {headers: headers()})
  )

export const getAllSites = () =>
  handleRequest(
    instance.get(`site/all`, {headers: headers()})
  )

export const getSiteListByUserID = (userId) =>
  handleRequest(
    instance.get(`site/list/user/${encodeURIComponent(userId)}`, {headers: headers()})
  )

export const getSiteListByGroupID = (groupId) =>
  handleRequest(
    instance.get(`site/list/${encodeURIComponent(groupId)}`, {headers: headers()})
  )

export const postCreateSite = (groupId, siteName) =>
  handleRequest(
    instance.post(
      'site/create',
      {
        groupId: groupId,
        siteName: siteName
      },
      {headers: headers()}
    )
  )

export const putUpdateSite = (siteId, siteName) =>
  handleRequest(
    instance.put(
      `site/update`,
      {
        siteId: siteId,
        siteName: siteName
      },
      {headers: headers()}
    )
  )

export const deleteSite = (siteID) =>
  handleRequest(instance.delete(`site/delete/${siteID}`,
      {headers: headers()}
    )
  )

export const postAssignMeter = (sitesMeterList) =>
  handleRequest(
    instance.post(
      'site/assignMeter',
      {
        sites: sitesMeterList
      },
      {headers: headers()}
    )
  )
//#endregion

//#region DEV
export const getResource = (endpoint, path) =>
  handleRequest(
    instance.get(
      `dev/resource?endpoint=${encodeURIComponent(endpoint)}&path=${path}`, // todo - this path should be prepended with /
      {headers: headers()}
    )
  )

export const getClient = (endpoint) =>
  handleRequest(instance.get(`dev/client/${encodeURIComponent(endpoint)}`, {headers: headers()}))

export const postGetResource = (endpoint, path, requestType, value, type) => {
  //TODO - Multiple functions not yet implemented
  console.error('postGetResource NOT implemented')
}

export const getEndpoints = () => handleRequest(instance.get(`dev/clients`, {headers: headers()}))

export const getEndpointIndividual = (endpoint) =>
  handleRequest(instance.get(`dev/client/${encodeURIComponent(endpoint)}`, {headers: headers()}))

export const getEndpointsList = () => handleRequest(instance.get('dev/clientsList', {headers: headers()}))
//#endregion


//#region DOWNLINK
export const getDownlinkRequest = (startTimestamp, endTimestamp, sites, stray, endpoint) =>
  handleRequest(
    instance.get(
      'downlink/request' +
      `?startTimestamp=${startTimestamp}` +
      `&endTimestamp=${endTimestamp}` +
      `&site=${sites}` +
      `&stray=${stray}` +
      `${(endpoint)?`&endpoint=${encodeURIComponent(endpoint)}`:''}`,
      {headers: headers()}
    )
  )

export const getCommands = () => handleRequest(instance.get('downlink/commands', {headers: headers()}))

export const postDownlinkRequest = (endpoints, commandId, parameters) =>
  handleRequest(
    instance.post(
      'downlink/request',
      {
        commandId: commandId,
        endpoints: endpoints,
        parameters: parameters
      },
      {headers: headers()}
    )
  )
//#endregion


//#region DASHBOARD
export const getEndpointWidgetData = (sites, stray) =>
  handleRequest(instance.get(
    'dashboard/endpoints' +
    `?site=${sites}`+
    `&stray=${stray}`,
    {headers: headers()}
    )
  );

export const getDashboardAlarmCounter = (sites, stray) =>
  handleRequest(instance.get(
    'dashboard/dashboardCounter' +
    `?site=${sites}`+
    `&stray=${stray}`,
    {headers: headers()}
    )
  );

export const getDashboardAnomalyGraph = (type, endTimestamp, sites, stray) =>
  handleRequest(
    instance.get(
      'dashboard/anomaly' +
      `?type=${type}` +
      `&endTimestamp=${endTimestamp}` +
      `&site=${sites}`+
      `&stray=${stray}`,
      {headers: headers()}
    )
  )

export const getDashboardConnectivityGraph = (type, endTimestamp, site, stray) =>
  handleRequest(
    instance.get(
      'dashboard/graph' +
      `?type=${type}` +
      `&endTimestamp=${endTimestamp}` +
      `&site=${site}`+
      `&stray=${stray}`,
      {headers: headers()}
    )
  )

export const getDashboardLayout = (email) =>
  handleRequest(instance.get(`dashboard/dashboardLayout?email=${email}`,
      {headers: headers()}
    )
  )

export const postDashboardLayout = (email, items) =>
  handleRequest(
    instance.post(
      'dashboard/dashboardLayout',
      {
        items: items,
        email: email
      },
      {headers: headers()}
    )
  )

//#endregion


//#region FIRMWARE
export const getFirmwareStatus = (startTimestamp, endTimestamp, sites, stray, endpoint) =>
  handleRequest(
    instance.get(
      'firmware/status' +
      `?startTimestamp=${startTimestamp}` +
      `&endTimestamp=${endTimestamp}` +
      `&site=${sites}` +
      `&stray=${stray}` +
      `${(endpoint)?`&endpoint=${encodeURIComponent(endpoint)}`:''}`,
      {headers: headers()}
    )
  )

export const getFirmwareUpdateHistory = (id) =>
  handleRequest(instance.get(`firmware/history?id=${id}`, {headers: headers()}))

export const getFirmwareFiles = () => handleRequest(instance.get('firmware/files', {headers: headers()}))

export const postFirmwareRequest = (endpoints, firmwareId) =>
  handleRequest(
    instance.post(
      'firmware/request',
      {
        firmwareId: firmwareId,
        endpoint: endpoints
      },
      {headers: headers()}
    )
  )

export const uploadFirmwareFile = (description, file) => {
  const formData = new FormData();
  formData.append('file', file);
  formData.append('description', description);

  return handleRequest(instance.post('firmware/upload', formData, {headers: headers()}));
};
//#endregion


//#region DATA FILE PUSH
export const updateDataPusherConfig = (ftpHost, ftpPort, ftpUsername, ftpPassword, timestamps) =>
  handleRequest(
    instance.post(
      'datafilepush/update',
      {
        ftpHost: ftpHost,
        ftpPort: ftpPort,
        ftpUsername: ftpUsername,
        ftpPassword: ftpPassword,
        timestamps: timestamps
      },
      {headers: headers()}
    )
  )

export const getLatestDataPusherConfig = () =>
  handleRequest(
    instance.get(
      'datafilepush/getConfig',
      {headers: headers()}
    )
  )
//#endregion

//#region USER PREFERENCE
export const getUserPreference = (email, type) =>
  handleRequest(instance.get(`data/userPreference?email=${email}&type=${type}`,
      {headers: headers()}
    )
  )

export const postUserPreference = (email, preferences) =>
  handleRequest(
    instance.post(
      'data/userPreference',
      {
        preferences: preferences,
        email: email
      },
      {headers: headers()}
    )
  )

export const deleteUserPreference = (email, type) =>
  handleRequest(instance.delete(`data/userPreference?email=${email}&type=${type}`,
      {headers: headers()}
    )
  )
//#endregion

//#region ANOMALY
export const getAnomalyData = (itemCount, sites, stray) =>
  handleRequest(
    instance.get(
      `anomaly/get?itemCount=${itemCount}` +
      `&site=${sites}`+
      `&stray=${stray}`,
      {headers: headers()}))

export const getDeviceAnomalyData = (endpoint, itemCount, startTimestamp, endTimestamp) =>
  handleRequest(instance.get(`anomaly/get/${encodeURIComponent(endpoint)}?itemCount=${itemCount}`+
      `${(startTimestamp)?`&startTimestamp=${startTimestamp}`:''}` +
    `${(endTimestamp)?`&endTimestamp=${endTimestamp}`:''}`, {headers: headers()}))
//#endregion

//#region MESH
export const getMeshStatus = (startTimestampUtc, endTimestampUtc) =>
  handleRequest(instance.get(`mesh/status?startTimestampUtc=${startTimestampUtc}&endTimestampUtc=${endTimestampUtc}`, { headers: headers() }));
//#endregion

//#region example

export const getAnalyticsSalesCountry = async () =>
  handleRequest(instance.get("/dashboard/analytics/salesbycountry"));

export const getAnalyticsWebsiteViews = async () =>
  instance.get("/dashboard/analytics/websiteviews");

export const getAnalyticsDailySales = async () => instance.get("/dashboard/analytics/dailysales");

export const getAnalyticsCompletedTasks = async () =>
  instance.get("/dashboard/analytics/completedtasks");

export const getSalesChannels = async () => instance.get("/dashboard/sales/channels");

export const getSalesRevenue = async () => instance.get("/dashboard/sales/revenue");

export const getSalesSalesAge = async () => instance.get("/dashboard/sales/salesbyage");

export const getSalesSalesCountry = async () => instance.get("/dashboard/sales/salesbycountry");

export const getSalesTopSelling = async () => instance.get("/dashboard/sales/topsellingproducts");

export const getProfileConversations = async () =>
  instance.get("/pages/profile/profileoverview/conversations");

export const getAccountBasicInfo = async () => instance.get("/pages/account/settings/basicinfo");

export const getProjectsTimeline = async () => instance.get("/pages/projects/timeline");

export const getWidgetTasks = async () => instance.get("/pages/widgets/tasks");

export const getWidgetsCalories = async () => instance.get("/pages/widgets/calories");

export const getWidgetCalendar = async () => instance.get("/pages/widgets/calendar");

export const getWidgetsCategories = async () => instance.get("/pages/widgets/categories");

export const getChartsLine = async () => instance.get("/pages/charts/line");

export const getChartsLineGradient = async () => instance.get("/pages/charts/linegradient");

export const getChartsBar = async () => instance.get("/pages/charts/bar");

export const getChartsBarHorizontal = async () => instance.get("/pages/charts/barhorizontal");

export const getChartsMixed = async () => instance.get("/pages/charts/mixed");

export const getChartsBubble = async () => instance.get("/pages/charts/bubble");

export const getChartsDoughnut = async () => instance.get("/pages/charts/doughnut");

export const getChartsPie = async () => instance.get("/pages/charts/pie");

export const getChartsRadar = async () => instance.get("/pages/charts/radar");

export const getChartsPolar = async () => instance.get("/pages/charts/polar");

export const getAppKanban = async () => instance.get("/applications/kanban");

export const getAppDatatable = async () => instance.get("/applications/datatable");

export const getAppCalendar = async () => instance.get("/applications/calendar");

export const getProductsProductPage = async () => instance.get("/ecommerce/products/productpage");

export const getOrderOrderList = async () => instance.get("/ecommerce/orders/orderlist");

export const register = async (name, email, password, phone, agency, role) =>
  instance.post("users/register", { name, email, password, phone, agency, role });

export const confirmRegister = async (id) => instance.post(`users/confirm/${id}`);

export const forgotPassword = async (email) => instance.post("users/forgotpassword", { email });

export const confirmReset = async (id, password) =>
  instance.post(`users/resetpass/${id}`, { password });

export const logout = async (token) => instance.post("users/logout", { token });

export const edit = async (userID, name, email) =>
  instance.post("/users/edit", { userID, name, email });

//#endregion
