import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import { getMeterAlarmAnalysis } from "util/APIHelper";
import { downloadChart, formatDate } from "util/UtilHelper";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { downloadExcel } from "util/ExcelUtil";
import { CircularProgress } from "@mui/material";
import { Line } from "react-chartjs-2";
import { alarmBaseData, alarmOptions } from "./configs";

const AlarmCountTimeline = React.forwardRef(({ endpoint, startDate, endDate }, ref) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(alarmBaseData);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeterAlarmAnalysis(
        formatDate("YYYY-MM-DD",startDate,true),
        formatDate("YYYY-MM-DD",endDate,true),
        endpoint
      );
      if (response.success) {
        console.log(response.content);

        const alarmChartData = response.content.map((item) => {
          return {x:item["created_utc"], y:item["alarms"].length, alarms:item["alarms"]}
        });

        let currentData = structuredClone(alarmBaseData);
        currentData.datasets[0].data = alarmChartData;
        setData(currentData);

        console.log(currentData);
      } else {
        setData([])
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const options = alarmOptions;
  const chartRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => { setAnchorEl(event.currentTarget); }
  const handleMenuClose = () => { setAnchorEl(null); }

  React.useImperativeHandle(ref, () => ({
    getData
  }));
  return (
    <Grid container spacing={1}>
      <Grid size={{xs:12}}>
        <Card>
          <MDBox display="flex" px={2} pt={2}>
            <MDBox mr="auto">
              <MDTypography variant="h6">Alarm Timeline</MDTypography>
              <MDBox mb={2}>
                <MDTypography component="div" variant="button" color="text">
                  Analysis Graph
                </MDTypography>
              </MDBox>
            </MDBox>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={getData}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem", marginRight:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">refresh</Icon>
            </IconButton>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={handleMenuOpen}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => { downloadChart(chartRef, 'Alarm graph'); handleMenuClose(); }}>Download Image</MenuItem>
              <MenuItem
                onClick={() => {
                  const { datasets } = data
                  const records = [];

                  for (let i=0; i<datasets[0].data.length; i++) {
                    records.push({
                      "Timestamp": datasets[0].data[i].x,
                      "Count": datasets[0].data[i].y,
                      "Alarms": datasets[0].data[i].alarms.join(", "),
                    })
                  }
                  const contents = {
                    "Data": records
                  }
                  downloadExcel("Alarm data", contents);
                }}
              >
                Download Excel
              </MenuItem>
            </Menu>
          </MDBox>
          {
            isLoading ? (
              <MDBox height="30rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <CircularProgress color="info" />
              </MDBox>
            ):(
              <MDBox height="30rem" px={1} pb={1}>
                <Line ref={chartRef} data={data} options={options} redraw />
              </MDBox>
            )
          }
        </Card>
      </Grid>
    </Grid>
  )
});

AlarmCountTimeline.propTypes = {
  endpoint: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default AlarmCountTimeline;