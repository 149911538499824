import {Navigate, Outlet} from "react-router-dom";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";

export function InverseProtectedRoutes() {
  const isAuthenticated = useIsAuthenticated()
  return isAuthenticated() ? <Navigate to="/" /> : <Outlet />;
}

export default function ProtectedRoutes() {
  const isAuthenticated = useIsAuthenticated()
  return isAuthenticated() ? <Outlet /> : <Navigate to="/login" />;
}
