import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import App from 'App';
import 'bootstrap/dist/css/bootstrap.min.css';
import { MaterialUIControllerProvider } from 'context/md';
import AuthProvider from "react-auth-kit";
import createStore from 'react-auth-kit/createStore';
import {SBControllerProvider} from "context/snackbar";
import {DashboardControllerProvider} from "context/dashboard";
import {stringPrototypes} from "util/prototypes/stringPrototypes";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
Object.assign(String.prototype, stringPrototypes);

const container = document.getElementById("app");
const root = createRoot(container);

const store = createStore({
  authName:'_auth',
  authType:'localstorage',
  cookieDomain: window.location.hostname,
  cookieSecure: false,
});

root.render(
  <AuthProvider store={store}>
    <BrowserRouter basename="/">
      <MaterialUIControllerProvider>
        <SBControllerProvider>
          <DashboardControllerProvider>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <App />
            </LocalizationProvider>
          </DashboardControllerProvider>
        </SBControllerProvider>
      </MaterialUIControllerProvider>
    </BrowserRouter>
  </AuthProvider>
);
