/* eslint-disable react/prop-types */
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";

export const columnDefs = [
  { header: "Endpoint", accessorKey: "endpoint", hide: true },
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
        unClickable={row.original.un_clickable}
      />
  },
  { header: "MIU S/N", accessorKey: "miu_sn", hide: true },
  { header: "Group", accessorKey: "group_name" },
  { header: "Site", accessorKey: "site_name" },
  { header: "Custom 1", accessorKey: "custom_1" },
  { header: "Custom 2", accessorKey: "custom_2" },
  { header: "Custom 3", accessorKey: "custom_3" },
  { header: "Custom 4", accessorKey: "custom_4" },
  { header: "Custom 5", accessorKey: "custom_5" },
]

export const kpiMeterTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      miu_sn: singular.miuSerialNumber,
      custom_1: singular.custom1,
      custom_2: singular.custom2,
      custom_3: singular.custom3,
      custom_4: singular.custom4,
      custom_5: singular.custom5,
      group_id: singular.groupId??-1,
      group_name: singular.groupName??"",
      site_id: singular.siteId??-1,
      site_name: singular.siteName??"",
      item: singular
    }
  });
}