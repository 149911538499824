import MDBox from "../../../../components/MDBase/MDBox";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import MDTypography from "components/MDBase/MDTypography";
import React, { useEffect, useState } from "react";
import { Memory, Map, Check, LightMode, DarkMode, Room, SettingsBrightness } from "@mui/icons-material";
import MDInput from "components/MDBase/MDInput";
import Tooltip from "@mui/material/Tooltip";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import { CircularProgress, IconButton } from "@mui/material";
import MDBadge from "components/MDBase/MDBadge";

function MapTab({handleSubmit, handleDelete, preferences}) {
  const [lightModeURL, setLightModeURL] = useState("");
  const [darkModeURL, setDarkModeURL] = useState("");
  const handleLightModeURL = (event) => setLightModeURL(event.target.value);
  const handleDarkModeURL = (event) => setDarkModeURL(event.target.value);

  const [isDeletingTheme, setIsDeletingTheme] = useState(false);
  const handleDeleteTheme = async () => {
    setIsDeletingTheme(true);
    await handleDelete("dashboard_map_theme")
    setIsDeletingTheme(false);
  }
  const [isDeletingPosition, setIsDeletingPosition] = useState(false);
  const handleDeletePosition = async () => {
    setIsDeletingPosition(true);
    await handleDelete("dashboard_map_coordinate")
    setIsDeletingPosition(false);
  }
  const [isDeletingLightModeURL, setDeletingLightModeURL] = useState(false);
  const handleDeleteLightModeURL = async () => {
    setDeletingLightModeURL(true);
    await handleDelete("dashboard_light_url");
    setDeletingLightModeURL(false);
  }
  const [isDeletingDarkModeURL, setDeletingDarkModeURL] = useState(false);
  const handleDeleteDarkModeURL = async () => {
    setDeletingDarkModeURL(true);
    await handleDelete("dashboard_dark_url");
    setDeletingDarkModeURL(false);
  }

  const [isSubmittingLightModeURL, setSubmittingLightModeURL] = useState(false);
  const handleSubmitLightURL = async () => {
    setSubmittingLightModeURL(true);
    await handleSubmit("dashboard_light_url", lightModeURL);
    setSubmittingLightModeURL(false);
  }
  const [isSubmittingDarkModeURL, setSubmittingDarkModeURL] = useState(false);
  const handleSubmitDarkURL = async () => {
    setSubmittingDarkModeURL(true);
    await handleSubmit("dashboard_dark_url", darkModeURL);
    setSubmittingDarkModeURL(false);
  }

  useEffect(() => {
    if (preferences.filter((item)=>item.item==="dashboard_light_url").length===1) {
      setLightModeURL(preferences.find((item)=>item.item==="dashboard_light_url").value.toString())
    } else {
      setLightModeURL("");
    }
    if (preferences.filter((item)=>item.item==="dashboard_dark_url").length===1) {
      setDarkModeURL(preferences.find((item)=>item.item==="dashboard_dark_url").value.toString())
    } else {
      setDarkModeURL("");
    }

  }, [preferences]);

  const buttonStyle = (color, reset) => ({
    color: "text",
    p: 0, mr: reset?1:0,
    "&:hover": {
      color: color,
    },
  })

  const handleLightModeButtons = () => {
    return (
      <>
        {
          (preferences.filter((item)=>item.item==="dashboard_light_url").length===0)?null:(
            isDeletingLightModeURL?(
              <MDBox display="flex" justifyContent="center" alignItems="center" pr={1}>
                <CircularProgress
                  color="error"
                  size="1rem"
                />
              </MDBox>
            ):(
              <IconButton
                onClick={handleDeleteLightModeURL}
                size="small"
                sx={buttonStyle("error.main", true)}
              >
                <Tooltip title="Reset">
                  <DeleteOutlineIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            )
          )
        }
        {
          isSubmittingLightModeURL?(
            <MDBox display="flex" justifyContent="center" alignItems="center" pr={1}>
              <CircularProgress
                color="success"
                size="1rem"
              />
            </MDBox>
          ):(
            <IconButton
              disabled={!(lightModeURL.length>0)}
              onClick={handleSubmitLightURL}
              size="small"
              sx={buttonStyle("success.main", false)}
            >
              <Tooltip title="Update">
                <Check fontSize="small" />
              </Tooltip>
            </IconButton>
          )
        }
      </>
    )
  }
  const handleDarkModeButtons = () => {
    return (
      <>
        {
          (preferences.filter((item)=>item.item==="dashboard_dark_url").length===0)?null:(
            isDeletingDarkModeURL?(
              <MDBox display="flex" justifyContent="center" alignItems="center" pr={1}>
                <CircularProgress
                  color="error"
                  size="1rem"
                />
              </MDBox>
            ):(
              <IconButton
                onClick={handleDeleteDarkModeURL}
                size="small"
                sx={buttonStyle("error.main", true)}
              >
                <Tooltip title="Reset">
                  <DeleteOutlineIcon fontSize="small" />
                </Tooltip>
              </IconButton>
            )
          )
        }
        {
          isSubmittingDarkModeURL?(
            <MDBox display="flex" justifyContent="center" alignItems="center" pr={1}>
              <CircularProgress
                color="success"
                size="1rem"
              />
            </MDBox>
          ):(
            <IconButton
              disabled={!(darkModeURL.length>0)}
              onClick={handleSubmitDarkURL}
              size="small"
              sx={buttonStyle("success.main", false)}
            >
              <Tooltip title="Update">
                <Check fontSize="small" />
              </Tooltip>
            </IconButton>
          )
        }
      </>
    )
  }
  const container = (item) =>
    <MDBox
      borderRadius="lg" display="flex" alignItems="center" sx={{
      padding: "0.5rem", gap: 1,
      border: ({ borders: { borderWidth, borderColor } }) =>
        `${borderWidth[1]} solid ${borderColor}`,
    }}>
      {item}
    </MDBox>

  const renderMapCoordinatePref = () => {
    return container(
      <>
        <Room color="text" fontSize="large" />
        <MDBox sx={{width:"100%"}}>
          <MDTypography variant="h6" mb={1}>Saved Map Position</MDTypography>
          <MDBox display="flex" justifyContent="space-between">
            {
              (preferences.filter((item)=>item.item==="dashboard_map_coordinate").length===0)?(
                <MDBadge size="xs" variant="contained" color="warning" badgeContent="NOT SET" container />
              ):(
                <MDBadge size="xs" variant="contained" color="info" badgeContent="SET" container />
              )
            }
          </MDBox>
        </MDBox>

        {
          (preferences.filter((item)=>item.item==="dashboard_map_coordinate").length===0)?null:(
            isDeletingPosition?(
              <MDBox display="flex" justifyContent="center" alignItems="center" pr={1}>
                <CircularProgress
                  color="error"
                  size="1rem"
                />
              </MDBox>
            ):(
              <IconButton
                onClick={handleDeletePosition}
                size="small"
                sx={buttonStyle("error.main", true)}
              >
                <Tooltip title="Reset">
                  <DeleteOutlineIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
            )
          )
        }
      </>
    )
  }

  const renderMapModePref = () => {
    return container(
      <>
        <SettingsBrightness color="text" fontSize="large" />
        <MDBox sx={{width:"100%"}}>
          <MDTypography variant="h6" mb={1}>Map Theme</MDTypography>
          <MDBox display="flex" justifyContent="space-between">
            {
              (preferences.filter((item)=>item.item==="dashboard_map_theme").length===0)?(
                <MDBadge size="xs" variant="contained" color="warning" badgeContent="NOT SET" container />
              ):(
                <MDBadge
                  size="xs"
                  variant="contained"
                  color="info"
                  badgeContent={preferences.filter((item)=>item.item==="dashboard_map_theme")[0].value}
                  container
                />
              )
            }
          </MDBox>
        </MDBox>

        {
          (preferences.filter((item)=>item.item==="dashboard_map_theme").length===0)?null:(
            isDeletingTheme?(
              <MDBox display="flex" justifyContent="center" alignItems="center" pr={1}>
                <CircularProgress
                  color="error"
                  size="1rem"
                />
              </MDBox>
            ):(
              <IconButton
                onClick={handleDeleteTheme}
                size="small"
                sx={buttonStyle("error.main", true)}
              >
                <Tooltip title="Reset">
                  <DeleteOutlineIcon fontSize="medium" />
                </Tooltip>
              </IconButton>
            )
          )
        }
      </>
    )
  }

  return (
    <Grid container spacing={4}>
      <Grid size={{xs:12}}>
        <MDBox display="flex" alignItems="center" mb={2}>
          <Map color="text" fontSize="medium" sx={{marginRight:"1rem"}}/>
          <MDTypography variant="h5">Custom Map Tiles</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid size={{xs:12, md:6}}>
              <MDBox display="flex" sx={{gap:0.5}} alignItems="center">
                <LightMode color="secondary" fontSize="medium"/>
                <MDInput
                  value={lightModeURL}
                  fullWidth
                  label="Light Mode Tile URL"
                  onChange={handleLightModeURL}
                  slotProps={{
                    input: {
                      endAdornment: handleLightModeButtons(),
                    }
                  }}
                />
              </MDBox>
            </Grid>
            <Grid size={{xs:12, md:6}}>
              <MDBox display="flex" sx={{gap:0.5}} alignItems="center">
                <DarkMode color="secondary" fontSize="medium" />
                <MDInput
                  value={darkModeURL}
                  fullWidth
                  label="Dark Mode Tile URL"
                  onChange={handleDarkModeURL}
                  slotProps={{
                    input: {
                      endAdornment: handleDarkModeButtons()
                    }
                  }}
                />
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
      <Grid size={{xs:12}}>
        <MDBox display="flex" alignItems="center" mb={2}>
          <Memory color="text" fontSize="medium" sx={{marginRight:"1rem"}}/>
          <MDTypography variant="h5">Memory</MDTypography>
        </MDBox>
        <MDBox>
          <Grid container spacing={2}>
            <Grid size={{xs:6}}>
              {renderMapCoordinatePref()}
            </Grid>
            <Grid size={{xs:6}}>
              {renderMapModePref()}
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  )
}

MapTab.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  preferences: PropTypes.array.isRequired
}

export default MapTab;