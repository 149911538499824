import Card from "@mui/material/Card";
import {CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBase/MDBox";
import SelectedMeterList from "components/Lists/SelectedMeterList";
import ContentCollapse from "components/ContentCollapse";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import {maskPresets, intervalPresets} from "util/downlinkParamUtil"
import {postDownlinkRequest} from "util/APIHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";

function QueueDownlinkCommandModal({open, handleClose, selectedMeters, selectedDownlinkCommand}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;
  const [openCollapse, setOpenCollapse] = useState(false);
  const [isLoading, setLoading] = useState(false);

  const [mask, setMask] = useState({
    second: '--',
    minute: '--',
    hour: '--',
    day: '--',
    month: '--',
    year: '--'
  });

  const [interval, setInterval] = useState({
    log: intervalPresets.log[0],
    transmit: intervalPresets.transmit[0]
  });

  const [genericParam, setGenericParam] = useState("");

  const handleGenericParam = (event) => setGenericParam(event.target.value);
  const handleIntegerGenericParam = (event) => {
    const numericValue = event.target.value.replace(/[^0-9]/g, '');
    setGenericParam(numericValue);
  };

  const handleLogInterval = (value) => setInterval(prevState => ({ ...prevState, log: value}));
  const handleTransmitInterval = (value) => setInterval(prevState => ({ ...prevState, transmit: value}));

  const handleSecondMask = (value) => setMask(prevState => ({ ...prevState, second: value }));
  const handleMinuteMask = (value) => setMask(prevState => ({ ...prevState, minute: value }));
  const handleHourMask = (value) => setMask(prevState => ({ ...prevState, hour: value }));
  const handleDayMask = (value) => setMask(prevState => ({ ...prevState, day: value }));
  const handleMonthMask = (value) => setMask(prevState => ({ ...prevState, month: value }));
  const handleYearMask = (value) => setMask(prevState => ({ ...prevState, year: value }));

  useEffect(() => {
    if (!open) {
      setOpenCollapse(false);
      setMask({
        second: '--',
        minute: '--',
        hour: '--',
        day: '--',
        month: '--',
        year: '--'
      });
      setInterval({
        log: intervalPresets.log[0],
        transmit: intervalPresets.transmit[0]
      });
      setGenericParam("")
    }
  }, [open]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
    maxHeight: "80vh"
  };

  const iconsStyle = ({palette: { text }}) => ({ color: text.main });
  const textStyle = (lines) => ({
    marginLeft: '0.5rem',
    display: '-webkit-box',
    WebkitBoxOrient: 'vertical',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'pre-line',
    WebkitLineClamp: lines,
  });
  const downlinkParameters = () => {
    return (
      <MDBox>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" >
              <Icon sx={iconsStyle}>terminal</Icon>
              <Tooltip title="Command Name">
                <MDTypography sx={textStyle(2)} variant="body2" verticalAlign="bottom">{selectedDownlinkCommand?.commandName}</MDTypography>
              </Tooltip>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            <MDBox display="flex" alignItems="center" >
              <Icon sx={iconsStyle}>description</Icon>
              <Tooltip title="Command Description">
                <MDTypography sx={textStyle(2)} variant="body2" verticalAlign="bottom">{selectedDownlinkCommand?.commandDesc}</MDTypography>
              </Tooltip>
            </MDBox>
          </Grid>
          <Grid item xs={12}>
            {parameters()}
          </Grid>
        </Grid>
      </MDBox>
    )
  };

  const parameters = () => {
    const widgets = [];

    selectedDownlinkCommand?.parameters.forEach((item)=>{
      if (item.description.toLowerCase().includes('mask')){
        widgets.push(
          <Grid container>
            <Grid item xs={12}>
              <MDTypography sx={{marginBottom:"0.5rem"}} variant="body2" verticalAlign="bottom">{item.description}</MDTypography>
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={maskPresets.year}
                value={mask.year}
                onChange={(event, value) => {handleYearMask(value);}}
                renderInput={(params) => <MDInput variant="standard" label="Y" {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={maskPresets.month}
                value={mask.month}
                onChange={(event, value) => {handleMonthMask(value);}}
                renderInput={(params) => <MDInput variant="standard" label="M" {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={maskPresets.day}
                value={mask.day}
                onChange={(event, value) => {handleDayMask(value);}}
                renderInput={(params) => <MDInput variant="standard" label="D" {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={maskPresets.hour}
                value={mask.hour}
                onChange={(event, value) => {handleHourMask(value);}}
                renderInput={(params) => <MDInput variant="standard" label="h" {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={maskPresets.minute}
                value={mask.minute}
                onChange={(event, value) => {handleMinuteMask(value);}}
                renderInput={(params) => <MDInput variant="standard" label="m" {...params} />}
                disableClearable
              />
            </Grid>
            <Grid item xs={2}>
              <Autocomplete
                options={maskPresets.second}
                value={mask.second}
                onChange={(event, value) => {handleSecondMask(value);}}
                renderInput={(params) => <MDInput variant="standard" label="s" {...params} />}
                disableClearable
              />
            </Grid>
          </Grid>
        )
      } else if (item.description.toLowerCase().includes('interval')) {
        const isLog = selectedDownlinkCommand?.commandName.toLowerCase().includes('log');
        widgets.push(
          <Grid container>
            <Grid item xs={12}>
              <MDTypography sx={{marginBottom:"0.5rem"}} variant="body2" verticalAlign="bottom">{item.description}</MDTypography>
            </Grid>
            <Grid item xs={12}>
              <Autocomplete
                options={isLog?intervalPresets.log:intervalPresets.transmit}
                value={isLog?interval.log:interval.transmit}
                onChange={(event, value) => {
                  if (isLog){
                    handleLogInterval(value);
                  } else {
                    handleTransmitInterval(value)
                  }
                }}
                isOptionEqualToValue={(option, value) =>
                  option.description === value.description
                }
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <MDInput label="" {...params} />}
                disableClearable
              />
            </Grid>
          </Grid>
        )
      } else {
        if (item.dataType.includes('integer')) {
          widgets.push(
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDTypography sx={{marginBottom:"0.5rem"}} variant="body2" verticalAlign="bottom">{item.description}</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  label="Value"
                  name="value"
                  inputProps={{
                    inputProps: {
                      inputMode: 'numeric',
                      pattern: '[0-9]*',
                    },
                  }}
                  fullWidth
                  onChange={handleIntegerGenericParam}
                  value={genericParam} />
              </Grid>
            </Grid>
          );
        } else {
          widgets.push(
            <Grid container spacing={1}>
              <Grid item xs={12}>
                <MDTypography sx={{marginBottom:"0.5rem"}} variant="body2" verticalAlign="bottom">{item.description}</MDTypography>
              </Grid>
              <Grid item xs={12}>
                <MDInput
                  label="Value"
                  name="value"
                  fullWidth
                  onChange={handleGenericParam}
                  value={genericParam} />
              </Grid>
            </Grid>
          );
        }
      }
    })
    return (
      <MDBox>
        <Grid container spacing={1}>
          {widgets.map((item, index)=>(
            <Grid key={index} item xs={12}>
              <MDBox
                borderRadius="lg"
                sx={{
                  border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
                  padding: "0.5rem"
                }}
              >
                {item}
              </MDBox>
            </Grid>
          ))}
        </Grid>
      </MDBox>
    );
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const parameters = [];

      selectedDownlinkCommand?.parameters.map((item)=>{
        let value
        if (item.description.toLowerCase().includes('mask')) {
          value = `${mask.year}${mask.month}${mask.day}${mask.hour}${mask.minute}${mask.second}`
        } else if (item.description.toLowerCase().includes('interval')) {
          value = `${interval.transmit.value}`
          if (selectedDownlinkCommand?.commandName.toLowerCase().includes('log')) {
            value = `${interval.log.value}`
          }
        } else {
          value = `${genericParam}`
        }
        parameters.push({
          id: item.id,
          description: item.description,
          value: value
        })
      })

      const response = await postDownlinkRequest(selectedMeters.map((i)=> i.endpoint).join(","), selectedDownlinkCommand?.id, parameters)
      if (response.success) {
        handleSB({
          color: "success",
          icon: "check",
          title: "Success",
          content: "Downlink successfully queued"
        });
      } else {
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: "Error queueing downlink"
        });
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} variant="h4">
          Queue Downlink
        </MDTypography>
        <Divider />
        <Grid sx={{ overflowY: 'auto',}} container spacing={1}>
          <Grid item xs={12}>
            {downlinkParameters()}
          </Grid>
          <Grid item xs={12}>
            <MDButton disabled={isLoading} size="medium" fullWidth variant="gradient" color="info" onClick={handleSubmit}>
              {
                isLoading?(
                  <CircularProgress color="white" size="1rem"/>
                ):"Queue"
              }
            </MDButton>
          </Grid>
          <Grid item xs={12}>
            <MDBox sx={{ width:"100%", overflowX: 'hidden'}}>
              <ContentCollapse
                title={`${(openCollapse)?'':selectedMeters.length} Selected Meter${(selectedMeters.length>1)?'s':''}`}
                open={openCollapse}
                onClick={() => (openCollapse ? setOpenCollapse(false) : setOpenCollapse(true))}
              >
                <MDBox sx={{padding:"0 1rem"}}>
                  <SelectedMeterList selectedMeters={selectedMeters} />
                </MDBox>
              </ContentCollapse>
            </MDBox>
          </Grid>
        </Grid>
      </Card>
    </Modal>
  )
}

QueueDownlinkCommandModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedMeters: PropTypes.array.isRequired,
  selectedDownlinkCommand: PropTypes.object.isRequired
}
export default QueueDownlinkCommandModal