import Card from "@mui/material/Card";
import {CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import { useEffect, useState } from "react";
import { deleteSite, postCreateSite, putUpdateSite } from "util/APIHelper";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";
import Tooltip from "@mui/material/Tooltip";
import { DeleteOutline } from "@mui/icons-material";
import { useMaterialUIController } from "context/md";
import IconButton from "@mui/material/IconButton";

function SiteAddEditModal({open, handleClose, groupName, groupId, isEdit=false, siteInfo=null}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [isError, setIsError] = useState(false);

  const [controller] = useMaterialUIController();
  const { user } = controller;

  useEffect(() => {
    if (open) {
      if (isEdit) {
        setDescription(siteInfo.name);
      }
    } else {
      setDescription("");
    }
  }, [open]);

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    if (event.target.value === "") {
      setIsError(true)
    } else {
      setIsError(false)
    }
  };

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      if (!isError) {
        let response;
        if (isEdit) {
          response = await putUpdateSite(siteInfo.id, description);
        } else {
          response = await postCreateSite(groupId, description);
        }
        if (response.success) {
          handleSB({
            color:"success",
            icon: "check",
            title: "Success",
            content: response.content
          })
        } else {
          handleSB({
            color:"error",
            icon: "warning",
            title: "Error",
            content: response.message
          })
        }
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: "Site name cannot be empty"
        })
      }
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false);
  }

  const handleDelete = async () => {
    setSubmitting(true);
    try {
      let response;
      response = await deleteSite(siteInfo.id);
      if (response.success) {
        handleSB({
          color:"success",
          icon: "check",
          title: "Success",
          content: response.content
        })
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: response.message
        })
      }
      handleClose()
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false);
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          {
            (isEdit)?"Edit Site":"Add Site"
          }
        </MDTypography>
        <Divider />
        <Grid container spacing={1}>
          {
            groupName && (
              <Grid item xs={12}>
                <MDInput type="text" label="Group Name" value={groupName} disabled fullWidth />
              </Grid>
            )
          }
          <Grid item xs={12}>
            <MDInput value={description} type="text" label="Site Name" onChange={handleDescriptionChange} fullWidth error={isError} helperText={isError?"Site name cannot be empty":null} />
          </Grid>
        </Grid>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          {
            (user?.isSuperuserAndAbove() && isEdit)?(
              <Tooltip title="Delete Site">
                <IconButton sx={{marginRight:"auto"}} onClick={handleDelete}>
                  {
                    submitting ? <CircularProgress color="error" size="1rem"/> : <DeleteOutline color="error"/>
                  }
                </IconButton>
              </Tooltip>
            ):null
          }
          <MDButton variant="outlined" color="secondary" sx={{marginRight:"1rem"}} onClick={handleClose}>Cancel</MDButton>
          <MDButton sx={{width:"9rem"}} disabled={submitting || loading} variant="gradient" color="info" onClick={submit}>
            {
              submitting?(
                <CircularProgress color="white" size="1rem"/>
              ):(
                (isEdit)?"Update Site":"Create Site"
              )
            }
          </MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

SiteAddEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  groupName: PropTypes.string,
  groupId: PropTypes.string.isRequired,
  isEdit: PropTypes.bool,
  siteInfo: PropTypes.object
}
export default SiteAddEditModal