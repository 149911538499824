import PropTypes from "prop-types";
import MDBox from "components/MDBase/MDBox";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";
import {useState} from "react";
import {v4 as uuidv4} from "uuid";
import MDTypography from "components/MDBase/MDTypography";
import { FixedSizeList as List } from 'react-window';
import {debounce} from "lodash";
import {ListItemButton} from "@mui/material";

function SelectedMeterList({selectedMeters}) {
  const [search, setSearch] = useState("");

  // Searching
  const [filteredMeters, setFilteredMeters] = useState(selectedMeters);

  const debouncedHandleSearch = debounce((value) => {
    const searchTerm = value.toLowerCase();
    const newFilteredMeters = selectedMeters.filter((item) => (
      item.meter_sn.toLowerCase().includes(searchTerm) ||
      item.endpoint.toLowerCase().includes(searchTerm)
    ));
    setFilteredMeters(newFilteredMeters);
  }, 300);

  const handleSearch = (value) => {
    setSearch(value);
    debouncedHandleSearch(value);
  };

  const Row = ({ index, style, data }) => {
    const item = data[index];
    return (
      <ListItemButton onClick={()=>{
        window.open(
          `/meter/details?endpoint=${item.endpoint}`,
          '_blank'
        )
      }} key={uuidv4()} style={style} sx={{ borderBottom: "1px solid lightgray" }}>
        <Grid container>
          <Grid item xs={12}>
            <MDTypography
              sx={{
                marginTop:"1rem",
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap"
              }}
              fontWeight="bold"
              verticalAlign="bottom"
              variant="body2"
              color="text"
            >
              {item.meter_sn}
            </MDTypography>
          </Grid>

          <Grid item xs={12}>
            <MDTypography verticalAlign="top" variant="caption" color="text">
              {item.endpoint}
            </MDTypography>
          </Grid>
        </Grid>
      </ListItemButton>
    );
  };

  Row.propTypes = {
    index: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    data: PropTypes.arrayOf(
      PropTypes.shape({
        meter_sn: PropTypes.string.isRequired,
        endpoint: PropTypes.string.isRequired
      })
    ).isRequired
  };


  return (
    <Grid container spacing={1}>
      <Grid item xs={12}>
        <MDInput
          placeholder="Search..."
          value={search}
          size="small"
          fullWidth
          onChange={({ currentTarget }) => {
            handleSearch(currentTarget.value);
          }}
        />

        <MDTypography variant="body2" color="text" fontWeight="light">
          {selectedMeters.length} Selected Meter{selectedMeters.length>1?"s":""}
        </MDTypography>
      </Grid>
      <Grid item xs={12}>
        <MDBox sx={{ maxHeight: '350px', overflowY: 'auto' }}>
          <List
            height={350} // Adjust as needed
            width="100%"
            itemSize={55}
            itemCount={filteredMeters.length}
            itemData={filteredMeters}
          >
            {Row}
          </List>
        </MDBox>
      </Grid>
    </Grid>
  );
}

SelectedMeterList.propTypes = {
  selectedMeters: PropTypes.array.isRequired
}

export default SelectedMeterList;