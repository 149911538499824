import PropTypes from "prop-types";
import {Card} from "@mui/material";
import { useEffect, useMemo, useState } from "react";
import DefaultLineChart from "components/MDComponents/Charts/LineCharts/DefaultLineChart";
import { checkStatus, formatDate } from "util/UtilHelper";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";

const GroupsKPIGraph = ({selectedMeters, endpointIntervals, data, groups, isLoading}) => {
  const [chartData, setChartData] = useState({});
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [isProcessing, setProcessing] = useState(false);

  useEffect(() => {
    if (!isLoading && data.length>0) {
      if (groups.length>0) {
        const groupList = groups.slice(0,10)
        setSelectedGroups(groupList)
        processData(groupList);
      }
    }
  }, [isLoading]);

  const processData = (items = null) => {
    let colors = {};
    try {
      if (JSON.parse(sessionStorage.getItem("group_site_color"))) {
        colors = JSON.parse(sessionStorage.getItem("group_site_color"));
      }
    } catch (e) {
      console.error(e)
    }
    setProcessing(true);
    const datasets = []

    groups.forEach((group) => {
      if (((items)??selectedGroups).find((obj) => obj.id === group.id)) {
        const list = selectedMeters.filter((meter) => meter.group_id === group.id ).map((_)=>_.endpoint)
        const filteredData = data.map((dates) => {
          return {
            ...dates,
            data: dates.data.filter((date_data) => (list.includes(date_data.endpoint)))
          }
        });

        const filteredEndpointIntervals = endpointIntervals.filter((intervals) => list.includes(intervals.endpoint))
        const totalExpected = filteredEndpointIntervals.reduce((acc, obj) => acc + obj.expectedLogCount, 0)
        const totalReceived = filteredData.reduce((acc, obj) =>
          acc + obj.data.reduce((innerAcc, innerObj) => innerAcc + innerObj.count, 0), 0);

        let c = checkStatus(((totalReceived/(totalExpected*filteredData.length)??1)*100).toFixed(2), "info");

        if (colors.groups) {
          if (colors.groups.length > 0) {
            const index = colors.groups.findIndex((obj) => obj.id === group.id);
            if (index > -1) {
              c = colors.groups[index].color
            }
          }
        }
        const subData = {
          label: group.name,
          color: c,
          data: filteredData.map((item) => {
            return (((item.data.reduce((acc, obj) => acc + obj.count, 0))/totalExpected) * 100).toFixed(2)
          })
        }

        datasets.push(subData)
      }
    });

    const processedData = {
      labels: data.map((item) =>
        formatDate(
          localStorage.getItem("datetime-format"),
          new Date(item.date),
          true
        )
      ),
      datasets: datasets
    }

    setChartData(processedData)
    setProcessing(false);
  }

  const onChange = (item) => {
    setSelectedGroups(item)
    processData(item);
  }

  return (
    <Card>
      {
        useMemo(()=> (
          <DefaultLineChart
            icon={{
              color:"info",
              component: "show_chart"
            }}
            title="Groups KPI Graph"
            height="30rem"
            description=" "
            chart={chartData}
            loading={isLoading}
            legend
            smooth
            extraAction={
              <Autocomplete
                sx={{ paddingLeft: "1rem", minWidth: "11rem", marginRight:"1rem", marginBottom:"1rem", maxWidth:"20rem"}}
                multiple
                limitTags={1}
                value={selectedGroups}
                options={groups}
                onChange={(event, value) => {
                  onChange(value);
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option.name}
                renderInput={(params) => <MDInput  label="Groups" {...params} />}
              />
            }
          />
        ), [chartData, isLoading, selectedGroups, isProcessing])
      }
    </Card>
  );
}

GroupsKPIGraph.defaultProps = {
  isLoading: false
}

GroupsKPIGraph.propTypes = {
  selectedMeters: PropTypes.array.isRequired,
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  groups: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}
export default GroupsKPIGraph;