import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { getMonthlyConsumptionLogs, postUserPreference } from "util/APIHelper";
import {
  columnDefs, monthlyConsumptionTableProcess,
} from "layouts/meter/details/consumption/tableUtil";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import MDTypography from "components/MDBase/MDTypography";
import MDBox from "components/MDBase/MDBox";
import MonthYearPicker from "components/Inputs/MonthYearPicker";
import dayjs from "dayjs";

function DetailsMonthlyConsumption({endpoint}) {
  const [monthlyConsumption, setMonthlyConsumption] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const [startDate, setStartDate] = useState(
    dayjs(new Date(new Date().getFullYear(), 1, 1).toISOString().split('T')[0])
  );
  const [endDate, setEndDate] = useState(
    dayjs(new Date().toISOString().split('T')[0])
  );
  const { user } = controller;

  const handleStartDateChange = (date) => {
    let end = endDate;
    setStartDate(date);
    if (date.toDate() > endDate.toDate()) {
      end = date;
    }
    setEndDate(end);
    getData(true, date, end);
  }

  const handleEndDateChange = (date) => {
    setEndDate(date);
    getData(true, startDate, date);
  }

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("meter_details_consumption_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const columns = JSON.stringify(item);
    sessionStorage.setItem("meter_details_consumption_column_state", columns);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "meter_details_consumption_column_state",
          value: columns
        }
      ])
    }
  }

  useEffect(() => {
    getData()
  }, []);

  const getData = async (ws=false, start=null, end=null) => {
    const s = start??startDate;
    const e = end??endDate;

    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      let startT = s.year().toString()
      let endT = e.year().toString()

      if (s.month() + 1 < 10) {
        startT += "-0" + (s.month() + 1).toString()
      } else {
        startT += "-" + (s.month() + 1).toString()
      }

      if (e.month() + 1 < 10) {
        endT += "-0" + (e.month() + 1).toString()
      } else {
        endT += "-" + (e.month() + 1).toString()
      }

      const response = await getMonthlyConsumptionLogs(
        endpoint,
        startT,
        endT
      );
      setMonthlyConsumption(monthlyConsumptionTableProcess(response.content));
    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
  }

  return (
    <MRTable
      data={monthlyConsumption}
      columns={columnDefs}
      isLoading={loading}
      isRefetching={wsLoading}
      onRefresh={getData}
      onColumnStateChanged={columnStateCallback}
      initialColumnOrder={columnState.order}
      initialColumnPinning={columnState.pinning}
      initialColumnVisibility={columnState.visibility}
      customTopLeftToolbar={()=>(
        <MDBox display="flex" alignItems="center" ml={1.3} mb={1}>
          <MonthYearPicker
            label="Start Month"
            onChange={handleStartDateChange}
            value={dayjs(startDate.toDate().toISOString().split('T')[0])}
            minDate={dayjs('2020-01-01')}
            maxDate={dayjs(new Date().toISOString().split('T')[0])}
          />
          <MDTypography variant="body2" fontWeight="light" color="text">
            &nbsp; to &nbsp;
          </MDTypography>
          <MonthYearPicker
            label="End Month"
            onChange={handleEndDateChange}
            value={dayjs(endDate.toDate().toISOString().split('T')[0])}
            minDate={(startDate)?(dayjs(startDate.toDate().toISOString().split('T')[0])):null}
            maxDate={dayjs(new Date().toISOString().split('T')[0])}
          />
        </MDBox>
      )}
    />
  );
}

DetailsMonthlyConsumption.propTypes = {
  endpoint: PropTypes.string.isRequired,
}
export default DetailsMonthlyConsumption;
