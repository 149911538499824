import jsrsasign from "jsrsasign";
import {useAuth} from "util/AuthUtil";

class User {
  constructor() {}

  login(email, token) {
    this.email = email;
    this.token = token;

    try {
      this.decoded = jsrsasign.jws.JWS.parse(this.token).payloadObj;

    } catch (e) {
      console.error("Error decoding token:", e);
      useAuth().logout()
    }

    this.datetimeFormat = "DD/MM/YY";

    if (this.decoded) {
      this.id = this.decoded.userId;
      this.role = this.decoded.role;
      this.expiry = this.decoded.exp;
      this.datetimeFormat = this.decoded.dateFormat

      switch (this.role) {
        case "viewer": this.accessLevel = 10; break;
        case "admin": this.accessLevel = 20; break;
        case "superuser": this.accessLevel = 30; break;
        case "developer": this.accessLevel = 100; break;
        default: this.accessLevel = 0; break;
      }
    } else {
      console.error("Token decoding failed or token is invalid.");
      useAuth().logout()
    }
  }

  isViewerAndAbove() { return this.accessLevel >= 10; }
  isAdminAndAbove() { return this.accessLevel >= 20; }
  isSuperuserAndAbove() { return this.accessLevel >= 30; }
  isDeveloperAndAbove() { return this.accessLevel >= 100; }

  logout() {
    this.email = null;
    this.token = null;
    this.decoded = null;
    this.datetimeFormat = null;
    this.id = null;
    this.role = null;
    this.expiry = null;
    this.accessLevel = null;
  }

  toJson() {
    return {
      email: this.email,
      token: this.token,
      datetimeFormat: this.datetimeFormat,
      id: this.id,
      expiry: this.expiry,
      accessLevel: this.accessLevel
    }
  }
}

export default User;
