import { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import DropzoneRoot from "./DropzoneRoot";
import MDTypography from "components/MDBase/MDTypography";
import MDBox from "components//MDBase/MDBox";
import Grid from "@mui/material/Grid";
import PropTypes from "prop-types";
import {formatBytes} from "util/UtilHelper";

function Dropzone({handleDrop, file=null}) {
  const onDrop = useCallback((acceptedFiles) => handleDrop(acceptedFiles), []);

  function fileTypeValidator(file) {
    if (!(file.name.endsWith('.bin'))) {
      return {
        code: "invalid-file-type",
        message: `File type is not .bin`
      };
    }
    return null
  }

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {'application/octet-stream':[]},
    validator: fileTypeValidator,
    maxFiles: 1,
  });

  return (
    <MDBox>
      <DropzoneRoot sx={{ cursor: "pointer" }} {...getRootProps({ className: 'dropzone' })}>
        <Grid container justifyContent="center">
          <Grid item>
            <MDBox color="primary" display="flex" textAlign="center" p={0}>
              {file? (
                <Grid container>
                  <Grid item xs={12}>
                    <MDTypography variant="body" color="text" >{file.name}</MDTypography>
                  </Grid>
                  <Grid item xs={12}>
                    <MDTypography variant="body2"  color="text" >{formatBytes(file.size)}</MDTypography>
                  </Grid>
                </Grid>
              ):(
                <MDTypography variant="body2" fontWeight="light" color="text" opacity={isDragActive ? 0.6 : 1}>
                  Drop the file here, or click to select the file (*.bin)
                </MDTypography>
              )}
            </MDBox>
          </Grid>
        </Grid>
      </DropzoneRoot>
    </MDBox>
  );
}

Dropzone.propTypes = {
  handleDrop: PropTypes.func.isRequired,
  file: PropTypes.object
}

export default Dropzone;
