import { useState } from "react";

// react-router-dom components
import {Link} from "react-router-dom";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDInput from "components/MDBase/MDInput";
import MDButton from "components/MDBase/MDButton";

import {postResetPassword} from "util/APIHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";
import LoginLayout from "layouts/auth/components/LoginLayout";

function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [isLoading, setLoading] = useState(false);

  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const handleSubmit = async (event) => {
    setLoading(true)
    event.preventDefault();
    try {
      const response = await postResetPassword(email);
      if (response.data.success) {
        handleSB({
          color: "success",
          icon: "check",
          title: "Success",
          content: response.data.content
        })
      } else {
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: response.data.message
        })
      }
    } catch (e) {
      console.error(e)
    }
    setLoading(false)
  }

  const handleEmailChange = (event) => setEmail(event.target.value);

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }


  return (
    <LoginLayout
      title="Forgot Password"
      description="Enter your email to receive a new password"
    >
      <MDBox component="form" role="form" onSubmit={handleSubmit}>
        <MDBox mb={2}>
          <MDInput label="Email" onChange={handleEmailChange} fullWidth />
        </MDBox>
        <MDBox mt={4} mb={1}>
          <MDButton disabled={isLoading} type="submit" variant="gradient" color="info" size="large" fullWidth>
            Reset Password
          </MDButton>
        </MDBox>
        <MDBox mt={3} textAlign="center">
          <MDTypography
            component={Link}
            to="/login"
            variant="button"
            color="info"
            fontWeight="medium"
            textGradient
          >
            Back to Login
          </MDTypography>
        </MDBox>
      </MDBox>
    </LoginLayout>
  );
}

export default ForgotPassword;
