import Card from "@mui/material/Card";
import { CircularProgress, Modal, Skeleton } from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import { useEffect, useState } from "react";
import { getAllSites, getSiteListByUserID, getUserRoles, putUpdateUser } from "util/APIHelper";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";

import Autocomplete from "@mui/material/Autocomplete";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";

function UserModal({ open, handleClose, data }) {
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const [active, setActive] = useState(true);
  const [roles, setRoles] = useState([]);
  const [selectedRole, setSelectedRole] = useState(null); // State for selected role
  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [selectedDateFormat, setSelectedDateFormat] = useState(""); // State for selected datetime

  const [sites, setSites] = useState([]);
  const [selectedSites, setSelectedSites] = useState([]);

  useEffect(() => {
    if (data.access_level && data.access_level !== 'superuser' && roles.length > 0) {
      setSelectedRole(roles.find(roleItem => roleItem.roleDescription === data.access_level)); // Set default role based on prop if it's not superuser
    }
  }, [roles, data]);

  useEffect(() => {
    if (open) {
      getData();
      setActive(data.item.active)
      setSelectedDateFormat(data.date_format); // Set selected datetime based on prop whenever modal is opened
      if (data.access_level && data.access_level !== 'superuser') {
        setSelectedRole(roles.find(roleItem => roleItem.roleDescription === data.access_level)); // Set selected role based on prop if it's not superuser
      }
    } else {
      setSelectedSites([]);
    }
  }, [open]);

  const getData = async () => {
    setLoading(true);
    try {
      const allSites = await getAllSites()
      setSites(allSites.content);
      const userSites = await getSiteListByUserID(data.item.userId)
      setSelectedSites(userSites.content);
      const response = await getUserRoles();
      setRoles(
        response.content.map((item) => ({
          roleId: item.roleId,
          roleDescription: item.roleDescription,
        }))
      );
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "1.5rem 1rem 1rem 1rem",
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      const response = await putUpdateUser(
        active,
        selectedDateFormat,
        (data.access_level==='superuser')?30:selectedRole.roleId,
        data.item.userId,
        selectedSites.map((site) => site.id)
      );
      if (response.success) {
        handleSB({
          color:"success",
          icon: "check",
          title: "Success",
          content: response.content
        })
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: response.message
        })
      }
    } catch (e) {
      console.error(e);
    }
    setSubmitting(false);
  };

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <>
        <Card sx={style}>
          <MDTypography
            sx={{ display: "flex", justifyContent: "center"}}
            id="modal-modal-title"
            variant="h5"
          >
            {data.email}
          </MDTypography>
          <Divider />
          <Grid container spacing={1}>
            <Grid item xs={12}>
              {/* Datetime autocomplete */}
              <Autocomplete
                options={["DD/MM/YYYY", "MM/DD/YYYY", "DD-MM-YYYY", "MM-DD-YYYY"]}
                value={selectedDateFormat}
                onChange={(event, value) => {
                  setSelectedDateFormat(value);
                }}
                renderInput={(params) => <MDInput label="Datetime Format" {...params} />}
                disableClearable
              />
            </Grid>
            {!data.access_level || data.access_level !== 'superuser' ? ( // Render role autocomplete if role is not superuser
              <Grid item xs={12}>
                {loading ? (
                  <Skeleton height="3.35rem" />
                ) : (
                  <Autocomplete
                    options={roles}
                    value={selectedRole}
                    onChange={(event, value) => {
                      setSelectedRole(value);
                    }}
                    isOptionEqualToValue={(option, value) =>
                      option.roleDescription === value.roleDescription
                    }
                    getOptionLabel={(option) => option.roleDescription}
                    renderInput={(params) => <MDInput label="User Role" {...params} />}
                    disableClearable
                  />
                )}
              </Grid>
            ) : null}
            <Grid item xs={12}>
              {loading ? (
                <Skeleton height="3.35rem" />
              ) : (
                <Autocomplete
                  multiple
                  limitTags={2}
                  groupBy={(option) => option.groupDescription}
                  options={sites}
                  value={selectedSites}
                  onChange={(event, value) => {
                    setSelectedSites(value);
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  getOptionLabel={(option) => option.description}
                  renderInput={(params) => <MDInput label="Sites" {...params} />}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              <Card sx={{ padding: "2px" }}>
                <Grid container>
                  <Grid item xs={6}>
                    <MDButton
                      sx={{ width: "100%", alignItems: "center" }}
                      onClick={() => setActive(true)}
                      variant={active ? "contained" : "text"}
                      color="success"
                    >
                      Active
                    </MDButton>
                  </Grid>
                  <Grid item xs={6}>
                    <MDButton
                      sx={{ width: "100%", alignItems: "center" }}
                      onClick={() => setActive(false)}
                      variant={active ? "text" : "gradient"}
                      color="error"
                    >
                      Inactive
                    </MDButton>
                  </Grid>
                </Grid>
              </Card>
            </Grid>
          </Grid>
          <MDBox mt={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
            <MDButton
              variant="outlined"
              color="text"
              sx={{ marginRight: "1rem" }}
              onClick={handleClose}
            >
              Cancel
            </MDButton>
            <MDButton
              sx={{ width: "9rem" }}
              disabled={submitting}
              variant="gradient"
              color="info"
              onClick={submit}
            >
              {submitting ? (
                <CircularProgress color="white" size="1rem" />
              ) : (
                "Update User"
              )}
            </MDButton>
          </MDBox>
        </Card>
      </>
    </Modal>
  );
}

UserModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired
};

export default UserModal;
