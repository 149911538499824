// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Tooltip from "@mui/material/Tooltip";
import {getSignalStrengthColor} from "util/LookupUtil";

function MeterSignalCell({ data }) {
  return (
    <Tooltip title={`RSRP: ${data.signal}`}>
      <MDTypography
        variant="body"
        color={getSignalStrengthColor(data.signalStrength)}
      >
        {data.signalStrength}
      </MDTypography>
    </Tooltip>
  );
}

// Setting default value for the props of CustomerCell
MeterSignalCell.defaultProps = {
  data: {}
};

// Typechecking props for the CustomerCell
MeterSignalCell.propTypes = {
  data: PropTypes.object
};

export default MeterSignalCell;
