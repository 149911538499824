import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import React, {useEffect, useState} from "react";
import { anomalyTableProcess, columnDefs } from "./tableUtil";
import { getAnomalyData, postUserPreference } from "util/APIHelper";
import WebSocketService from "model/WebSocketService";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import useCooldown from "util/hooks/useCooldown";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function MeterAnomaly() {
  const [anomaly, setAnomaly] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const params = new URLSearchParams(location.search);
  const search = params.get("type");
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("meter_anomaly_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("meter_anomaly_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "meter_anomaly_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "AnomalyTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  useEffect(() => {
    getData()
  }, []);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      if (selectedSites.value.length>0) {
        const sites = selectedSites.value.map(e => e.id);
        const response = await getAnomalyData(1000, sites.join(","), sites.includes(-1))
        setAnomaly(anomalyTableProcess(response.content))
      } else {
        setAnomaly([]);
      }
    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
    if (!isMounted) {
      setIsMounted(true)
    }
  }

  const throttledGetData = useCooldown(() => {
    getData(true);
  }, 1000);

  return (
    <DashboardLayout>
      <DashboardNavbar/>
      <MRTable
        data={anomaly}
        columns={columnDefs}
        isLoading={loading}
        isRefetching={wsLoading}
        onRefresh={getData}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
      />
    </DashboardLayout>
  );
}

export default MeterAnomaly;
