// prop-types is a library for typechecking of props
import PropTypes from "prop-types";
import Tooltip from "@mui/material/Tooltip";
import {navbarDesktopMenu} from "components/MDComponents/Navbars/DashboardNavbar/styles";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";

function MeterCoordinateCell({ data }) {
  return (data.latitude && data.longitude)?(
    <Tooltip title="View on Google Map">
      <IconButton onClick={()=>{
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${data.latitude},${data.longitude}`,
          '_blank'
        )
      }} size="small" disableRipple>
        <Icon fontSize="medium" color="info">
          map
        </Icon>
      </IconButton>
    </Tooltip>
  ):null;
}

// Setting default value for the props of CustomerCell
MeterCoordinateCell.defaultProps = {
  data: {}
};

// Typechecking props for the CustomerCell
MeterCoordinateCell.propTypes = {
  data: PropTypes.object
};

export default MeterCoordinateCell;
