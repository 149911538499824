import React, { useEffect, useState } from "react";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Grid from "@mui/material/Grid";
import {Card} from "@mui/material";
import MDBox from "components/MDBase/MDBox";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Icon from "@mui/material/Icon";
import AppBar from "@mui/material/AppBar";
import breakpoints from "assets/theme/base/breakpoints";
import { removeURLParam, setURLParam } from "util/UtilHelper";
import { columnDefs, meterTableProcess } from "./tableUtil";
import { getMeter, postUserPreference } from "util/APIHelper";
import {useNavigate} from "react-router-dom";
import MDButton from "components/MDBase/MDButton";
import RegisterMeterModal from "components/Modals/RegisterMeterModal";
import DataPusherModal from "components/Modals/DataPusherModal";
import MeterConfigModal from "components/Modals/MeterConfigModal";
import {useMaterialUIController} from "context/md";
import WebSocketService from "model/WebSocketService";
import MRTable from "components/MaterialReactTable";
import useCooldown from "util/hooks/useCooldown";
import MenuItem from "@mui/material/MenuItem";
import { allSites, selectedSites } from "../../signals/group_signals";
import { useSignalEffect } from "@preact/signals-react";

function Meter() {
  const [controller,] = useMaterialUIController();
  const {user} = controller;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const params = new URLSearchParams(location.search);
  const toggle_param = params.get("toggle");
  const group_param = params.get("group");
  const site_param = params.get("site");
  const navigate = useNavigate();

  const [meters, setMeters] = useState([])
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);

  const [registerMeterModal, setRegisterMeterModal] = useState(false);
  const handleOpenRegisterMeterModal = () => setRegisterMeterModal(true);
  const handleCloseRegisterMeterModal = () => setRegisterMeterModal(false);

  const [dataPusherModal, setDataPusherModal] = useState(false);
  const handleOpenDataPusherModal = () => setDataPusherModal(true);
  const handleCloseDataPusherModal = () => setDataPusherModal(false);

  const [meterConfigData, setMeterConfigData] = useState({});
  const [meterConfigModal, setMeterConfigModal] = useState(false);
  const handleOpenMeterConfigModal = (meterConfigData) => {
    setMeterConfigData(meterConfigData)
    setMeterConfigModal(true)
  };
  const handleCloseMeterConfigModal = () => setMeterConfigModal(false);

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("meter_status_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });
  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("meter_status_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "meter_status_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    if (group_param !== null) {
      selectedSites.value = allSites.value.filter((e) => e.groupId === parseInt(group_param))
    } else if (site_param !== null) {
      selectedSites.value = allSites.value.filter((e) => e.id === parseInt(site_param))
    }
  }, []);

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "DeviceTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  useEffect(() => {
    if (toggle_param === "registered") {
      setTabValue(1)
    } else if (toggle_param === "unregistered") {
      setTabValue(2)
    } else {
      setTabValue(0)
    }
  }, [toggle_param]);

  useEffect(() => {
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }
    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();
    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    // Update the URL with the new toggle value
    const newToggleValue = newValue === 1 ? "registered" : newValue === 2 ? "unregistered" : "connected";
    setURLParam("toggle", newToggleValue)
  };

  useEffect(() => {
    if (user) {
      getData()
    }
  }, [user]);

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      if (group_param !== null) {
        removeURLParam("group")
      }
      getData()
    }
  })
  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true);
    }
    try {
      if (selectedSites.value.length > 0) {
        const sites = selectedSites.value.map(e => e.id)
        const response = await getMeter({site:sites.join(","), stray:sites.includes(-1)});
        setMeters(meterTableProcess(response.content.meters));
      } else {
        setMeters([]);
      }
    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
    if (!isMounted) {
      setIsMounted(true)
    }
  }
  const throttledGetData = useCooldown(() => {
    if (!wsLoading && !loading) {
      getData(true);
    }
  }, 1000);

  const toggleComponent = () => (
    <Grid item xs={12} md={6} lg={5} sx={{ mr: "auto" }}>
      <Card>
        <AppBar position="static">
          <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
            <Tab label="Connected" icon={<Icon fontSize="small" > lan </Icon>} />
            <Tab label="Registered" icon={<Icon fontSize="small"> gpp_good </Icon>} />
            <Tab label="Unregistered" icon={<Icon fontSize="small"> gpp_bad </Icon>} />
          </Tabs>
        </AppBar>
      </Card>
    </Grid>
  )

  const meterClick = (row) => {
    if (row.original.registered) {
      navigate(`/meter/details?endpoint=${row.original.endpoint}`)
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MDBox>
            <Grid container spacing={2}>
              {toggleComponent()}
              <Grid item xs={12}>
                <MRTable
                  data={meters.filter(row => {
                    switch (`${tabValue}`) {
                      case '1': return row.uploaded;
                      case '2': return row.stray;
                      default: return row.registered;
                    }
                  })}
                  columns={columnDefs}
                  isLoading={loading}
                  isRefetching={wsLoading}
                  onRefresh={getData}
                  onRowClick={meterClick}
                  onColumnStateChanged={columnStateCallback}
                  initialColumnOrder={columnState.order}
                  initialColumnPinning={columnState.pinning}
                  initialColumnVisibility={columnState.visibility}
                  rowActionMenuItems={(user?.isAdminAndAbove())?({row, closeMenu})=> {
                    if (row.original.noAction) {
                      return []
                    } else return [
                      <MenuItem key="edit" onClick={() => {
                        handleOpenMeterConfigModal(row.original);
                        closeMenu();
                      }}>
                        <Icon fontSize="small" >edit</Icon>&nbsp; Edit Meter
                      </MenuItem>,
                    ]
                  }:null}
                  customTopRightToolbar={()=> {
                    return user?.isAdminAndAbove()?(
                      <MDBox sx={{margin:"0 0.5rem 1rem 0"}} >
                        <MDButton onClick={handleOpenRegisterMeterModal} variant="outlined" color="info" sx={{marginRight:"0rem"}}>
                          <Icon>add</Icon>&nbsp; Register Meter
                        </MDButton>
                        <MDButton sx={{display:"none"}} disabled onClick={handleOpenDataPusherModal} variant="outlined" color="info">
                          <Icon>access_time</Icon>&nbsp; Data Pusher
                        </MDButton>
                      </MDBox>
                    ):null
                  }}
                />
              </Grid>
            </Grid>
          </MDBox>
        </Grid>
      </Grid>
      <RegisterMeterModal handleClose={handleCloseRegisterMeterModal} open={registerMeterModal} />
      <DataPusherModal handleClose={handleCloseDataPusherModal} open={dataPusherModal} />
      <MeterConfigModal data={meterConfigData} handleClose={handleCloseMeterConfigModal} open={meterConfigModal} />
    </DashboardLayout>
  );
}

export default Meter;
