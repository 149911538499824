class Meter {
  constructor(endpoint, data) {
    this.endpoint = endpoint;

    // Expects an array
    if (Array.isArray(data)) {
      data.map((item)=>{
        switch (item.description) {
          case "Meter Serial Number": this.meter_sn = item.value; break;
          case "MIU Serial Number": this.miu_sn = item.value; break;
          case "Latitude": this.latitude = item.value; break;
          case "Longitude": this.longitude = item.value; break;
          case "Custom1": this.custom_1 = item.value; break;
          case "Custom2": this.custom_2 = item.value; break;
          case "Custom3": this.custom_3 = item.value; break;
          case "Custom4": this.custom_4 = item.value; break;
          case "Custom5": this.custom_5 = item.value; break;
          case "Start Mask": this.log_start_mask = item.value; break;
          case "Periodic Interval": this.log_interval = item.value; break;
          case "Record Dispatch Start Mask": this.transmit_start_mask = item.value; break;
          case "Record Dispatch Interval": this.transmit_interval = item.value; break;
          case "Group ID": this.group_id = item.value; break;
          case "Group Name": this.group_name = item.value; break;
          case "Site ID": this.site_id = item.value; break;
          case "Site Name": this.site_name = item.value; break;
          default:
            console.error(`Unhandled Meter Info: '${item.description}'`); break;
        }
      })
    }
  }

  print() {
    console.table(this)
  }
}

export default Meter;