// const socket = new WebSocket('wss://socketsbay.com/wss/v2/1/demo/');
/**
 * For usage, please view here.
 * @type {{disconnect: WebSocketService.disconnect, addMessageListener: WebSocketService.addMessageListener, removeMessageListener: WebSocketService.removeMessageListener, messageListeners: *[], connect: WebSocketService.connect}}
 */

/*
[in app.js]
import WebSocketService from "model/WebSocketService";

useEffect(() => {
  WebSocketService.connect();
  return () => {
    WebSocketService.disconnect();
  };
}, []);

[in app.js if sending message when start]
useEffect(() => {
    const setupWebSocket = async () => {
      if (user && isAuthenticated()) {
        try {
          await WebSocketService.connect(user.email); // Wait for the connection to be established
          WebSocketService.sendMessage('DeviceTable, ReadingTable, AlarmTable, StatusTable, DownlinkTable, UserTable, ResourceTable, FirmwareStatusTable, FirmwareFileTable, AnomalyTable, Account')
        } catch (error) {
          console.error('WebSocket connection failed:', error);
        }
      }
    };

    setupWebSocket();

    return () => {
      WebSocketService.disconnect();
    };
  }, [user, isAuthenticated]);


[in desired component or layout]
import WebSocketService from "model/WebSocketService";

useEffect(() => {
  const handleSignal = (data) => {
    console.log('message:', data);
    if (data === "test_data_1") {
      //do something if test_data_1

    } else if (data === "test_data_2") {
      //do something if test_data_2
    }
  };

  WebSocketService.addMessageListener(handleSignal);
}, []);
 */

// const socket = new WebSocket(process.env.REACT_APP_WS_URL);

const WebSocketService = {
  socket: null,
  messageListeners: [],

  connect: function(email) {
    return new Promise((resolve, reject) => {
      if (this.socket && this.socket.readyState === WebSocket.OPEN) {
        resolve();
        return;
      }

      this.socket = new WebSocket(`${process.env.REACT_APP_WS_URL}/${email}`);

      this.socket.addEventListener('open', () => {
        resolve(); // Resolve the promise here
      });

      this.socket.addEventListener('error', (error) => {
        console.error('WebSocket error:', error);
        reject(error); // Reject the promise if there's an error
      });

      this.socket.addEventListener('close', (event) => {
        // Optionally implement reconnection logic here
        if (event.code !== 1000) { // Ensure not closing due to normal disconnect call
          setTimeout(() => this.connect(), 5000); // Reconnect after a delay
        }
      });

      // Reattach message listeners upon reconnecting
      this.messageListeners.forEach((listener) => {
        this.socket.addEventListener('message', listener);
      });
    });
  },

  disconnect: function() {
    if (!this.socket || this.socket.readyState === WebSocket.CLOSED) {
      return;
    }

    this.socket.close(1000, 'Normal closure');
  },

  addMessageListener: function(callback) {
    if (this.socket) {
      this.socket.addEventListener('message', callback);
    }
    this.messageListeners.push(callback);
  },

  removeMessageListener: function(callback) {
    if (this.socket) {
      this.socket.removeEventListener('message', callback);
    }
    this.messageListeners = this.messageListeners.filter(listener => listener !== callback);
  },

  // Function to send a message to the server via WebSocket
  sendMessage: function(message) {
    if (!this.socket) {
      console.error('WebSocket is not connected. Message cannot be sent.');
      return;
    }

    // Check if the WebSocket connection is open
    if (this.socket.readyState === WebSocket.OPEN) {
      this.socket.send(message);
    } else {
      console.error('WebSocket is not open. Message cannot be sent.');
      // Optionally, queue the message or handle this scenario based on your application needs
    }
  },
  // Additional methods can be added here if necessary
};

export default WebSocketService;
