/* eslint-disable react/prop-types */
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import { basicSort, epochToTimestamp, formatDate } from "util/UtilHelper";
import AlarmCounterCell, {
  convertDetectionName,
} from "components/TableCells/TrendLogTable/AlarmCounterCell";
import { getAlarmName } from "components/AlarmIcon";

export const columnDefs = [
  { header: "Endpoint", accessorKey: "endpoint", hide: true },
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "MIU S/N", accessorKey: "miu_sn", hide: true },
  { header: "Group", accessorKey: "group_name", hide: true },
  { header: "Site", accessorKey: "site_name", hide: true },
  { header: "Date", accessorKey: "date", },
  { header: "Time", accessorKey: "time", },
  { header: "Consumption (m³)", accessorKey: "consumption", },
  { header: "Reading (m³)", accessorKey: "reading", },
  { header: "Min. Flow Rate (L)", accessorKey: "min_flow",
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(
        (rowA.original.item.min_consumption/rowA.original.item.log_interval),
        (rowB.original.item.min_consumption/rowB.original.item.log_interval)),
  },
  { header: "Max. Flow Rate (L)", accessorKey: "max_flow",
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(
        (rowA.original.item.max_consumption/rowA.original.item.log_interval),
        (rowB.original.item.max_consumption/rowB.original.item.log_interval)),
  },
  { header: "Alarms", accessorKey: "alarms",
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(getAlarmCount(rowA.original.item.alarm_count), getAlarmCount(rowB.original.item.alarm_count)),
    Cell: ({_, row}) =>
      <AlarmCounterCell data={row.original.item.alarm_count} />
  },
  { header: "Custom 1", accessorKey: "custom_1", hide: true },
  { header: "Custom 2", accessorKey: "custom_2", hide: true },
  { header: "Custom 3", accessorKey: "custom_3", hide: true },
  { header: "Custom 4", accessorKey: "custom_4", hide: true },
  { header: "Custom 5", accessorKey: "custom_5", hide: true },
]

function formatTime(seconds) {
  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? 's' : ''}`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes !== 1 ? 's' : ''}`;
  } else {
    const hours = Math.floor(seconds / 3600);
    return `${hours} hour${hours !== 1 ? 's' : ''}`;
  }
}

function getAlarmCount(alarm) {
  let count = 0
  for (let key in alarm) {
    const value = alarm[key];

    if (!isNaN(value)) {
      count += Number(value);
    }
  }
  return count;
}
function processAlarmText(alarm) {
  if (alarm.endpoint === "") return ""

  let alarmList = []
  for (let key in alarm) {
    if (!isNaN(alarm[key])) {
      if (alarm[key]>0) {
        let counter = ""
        if (alarm[key]>1) {counter = ` x ${alarm[key]}`}
        alarmList.push(`${getAlarmName(convertDetectionName(key))}${counter}`)
      }
    }
  }
  return alarmList.join(", ")
}

export const trendLogTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      endpoint: singular.endpoint,
      meter_sn: (singular.meter_sn) ? singular.meter_sn : singular.endpoint,
      miu_sn: singular.miu_sn,
      group_name: singular.group_name??"",
      site_name: singular.site_name??"",
      date: formatDate(localStorage.getItem("datetime-format"), new Date(epochToTimestamp(singular.timestamp)), true),
      time: formatDate(localStorage.getItem("datetime-format"), new Date(epochToTimestamp(singular.timestamp)),).split(" ")[1],

      consumption: (singular.total_consumption / 1000).toFixed(4),
      reading: (singular.max_reading / 1000).toFixed(4),
      min_flow: `${singular.min_consumption.toFixed(4)} L / ${formatTime(singular.log_interval)}`,
      max_flow: `${singular.max_consumption.toFixed(4)} L / ${formatTime(singular.log_interval)}`,
      alarms: processAlarmText(singular.alarm_count),
      custom_1: singular.custom_1,
      custom_2: singular.custom_2,
      custom_3: singular.custom_3,
      custom_4: singular.custom_4,
      custom_5: singular.custom_5,
    }
  });
}