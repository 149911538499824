import MDBox from "components/MDBase/MDBox";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Grid from "@mui/material/Grid2";
import MDInput from "components/MDBase/MDInput";
import MDTypography from "components/MDBase/MDTypography";
import AlarmIcon, { getAlarmColor, getAlarmTitle } from "components/AlarmIcon";
import MDButton from "components/MDBase/MDButton";
import Tooltip from "@mui/material/Tooltip";
import { IconButton } from "@mui/material";
import { SettingsBackupRestoreOutlined } from "@mui/icons-material";

const alarmsInfo = [
  "leakageDetection",
  "noFlowDetection",
  "burstDetection",
  "backFlowDetection",
  "batteryLow",
  "faultySensor",
  "wireCutDetection",
  "tiltDetection",
  "magnetTamper",
  "nonRealtimeBackFlowDetection",
  "rebootDetection",
];

function AlarmTab({handleSubmit, handleDelete, preferences}) {
  const [isLoading, setLoading] = useState(false);
  const [leakageDetection, setLeakageDetection] = useState(getAlarmColor("leak"));
  const [noFlowDetection, setNoFlowDetection] = useState(getAlarmColor("no_flow"));
  const [burstDetection, setBurstDetection] = useState(getAlarmColor("burst"));
  const [backFlowDetection, setBackFlowDetection] = useState(getAlarmColor("backflow"));
  const [batteryLow, setBatteryLow] = useState(getAlarmColor("battery_low"));
  const [faultySensor, setFaultySensor] = useState(getAlarmColor("faulty_sensor"));
  const [wireCutDetection, setWireCutDetection] = useState(getAlarmColor("wire_cut"));
  const [tiltDetection, setTiltDetection] = useState(getAlarmColor("tilt"));
  const [magnetTamper, setMagnetTamper] = useState(getAlarmColor("magnet"));
  const [nonRealtimeBackFlowDetection, setNonRealtimeBackFlowDetection] = useState(getAlarmColor("non_realtime_backflow"));
  const [rebootDetection, setRebootDetection] = useState(getAlarmColor("reboot"));

  useEffect(() => {
    if (preferences.length === 1) {
      try {
        const newColor = JSON.parse(preferences[0].value);
        setLeakageDetection(newColor.leakageDetection);
        setNoFlowDetection(newColor.noFlowDetection);
        setBurstDetection(newColor.burstDetection);
        setBackFlowDetection(newColor.backFlowDetection);
        setBatteryLow(newColor.batteryLow);
        setFaultySensor(newColor.faultySensor);
        setWireCutDetection(newColor.wireCutDetection);
        setTiltDetection(newColor.tiltDetection);
        setMagnetTamper(newColor.magnetTamper);
        setNonRealtimeBackFlowDetection(newColor.nonRealtimeBackFlowDetection);
        setRebootDetection(newColor.rebootDetection);
      } catch (e) {
        console.error(e);
      }
    } else {
      setLeakageDetection(getAlarmColor("leak"));
      setNoFlowDetection(getAlarmColor("no_flow"));
      setBurstDetection(getAlarmColor("burst"));
      setBackFlowDetection(getAlarmColor("backflow"));
      setBatteryLow(getAlarmColor("battery_low"));
      setFaultySensor(getAlarmColor("faulty_sensor"));
      setWireCutDetection(getAlarmColor("wire_cut"));
      setTiltDetection(getAlarmColor("tilt"));
      setMagnetTamper(getAlarmColor("magnet"));
      setNonRealtimeBackFlowDetection(getAlarmColor("non_realtime_backflow"));
      setRebootDetection(getAlarmColor("reboot"));
    }
  }, [preferences]);

  const updateColors = async () => {
    setLoading(true);
    const colors = {
      leakageDetection: leakageDetection,
      noFlowDetection: noFlowDetection,
      burstDetection: burstDetection,
      backFlowDetection: backFlowDetection,
      batteryLow: batteryLow,
      faultySensor: faultySensor,
      wireCutDetection: wireCutDetection,
      tiltDetection: tiltDetection,
      magnetTamper: magnetTamper,
      nonRealtimeBackFlowDetection: nonRealtimeBackFlowDetection,
      rebootDetection: rebootDetection
    };
    await handleSubmit("alarm_color", JSON.stringify(colors));
    setLoading(false);
  }

  const resetColors = async () => {
    setLoading(true);
    await handleDelete("alarm_color");
    setLoading(false);
  }

  const alarmColorComponent = (val, setter, alarm, bg=false) => {
    return (
      <Grid size={{xs:12}}>
        <MDBox display="flex" justifyContent="space-between" alignItems="center" sx={{backgroundColor:`rgba(0,0,0,${bg?0.1:0})`, borderRadius:"1rem"}} p={1}>
          <MDBox display="flex" alignItems="center" ml={1} sx={{gap:2}}>
            <AlarmIcon
              alarm={alarm}
              size="2rem"
              displayOnly
            />
            <MDTypography variant="body2">
              {getAlarmTitle(alarm)}
            </MDTypography>
          </MDBox>
          <MDBox alignItems="center">
            <IconButton
              onClick={()=>{setter(getAlarmColor(alarm))}}
              size="small"
              sx={{
                color: "text",
                p: 0, mr: 2,
                "&:hover": {
                  color: "error.main",
                },
              }}
            >
              <Tooltip title="Reset Color">
                <SettingsBackupRestoreOutlined fontSize="small" />
              </Tooltip>
            </IconButton>
            <MDInput disabled={isLoading} sx={{width:100}} value={val} type="color" onChange={(event)=>setter(event.target.value)} />
          </MDBox>
        </MDBox>
      </Grid>
    )
  }

  return (
    <MDBox>
      <Grid container spacing={2}>
        <Grid size={{xs:12}}>
          <MDTypography variant="subtitle2" textAlign="center">
            Colors set here will be used in the <b>Analysis</b> {">"} <b>Alarm</b> page.
          </MDTypography>
        </Grid>

        {alarmColorComponent(leakageDetection, setLeakageDetection, alarmsInfo[0], true)}
        {alarmColorComponent(noFlowDetection, setNoFlowDetection, alarmsInfo[1], false)}
        {alarmColorComponent(burstDetection, setBurstDetection, alarmsInfo[2], true)}
        {alarmColorComponent(backFlowDetection, setBackFlowDetection, alarmsInfo[3], false)}
        {alarmColorComponent(batteryLow, setBatteryLow, alarmsInfo[4], true)}
        {alarmColorComponent(faultySensor, setFaultySensor, alarmsInfo[5], false)}
        {alarmColorComponent(wireCutDetection, setWireCutDetection, alarmsInfo[6], true)}
        {alarmColorComponent(tiltDetection, setTiltDetection, alarmsInfo[7], false)}
        {alarmColorComponent(magnetTamper, setMagnetTamper, alarmsInfo[8], true)}
        {alarmColorComponent(nonRealtimeBackFlowDetection, setNonRealtimeBackFlowDetection, alarmsInfo[9], false)}
        {alarmColorComponent(rebootDetection, setRebootDetection, alarmsInfo[10], true)}

        <Grid size={{xs:12}}>
          <MDBox display="flex" alignItems="end" justifyContent="end" sx={{width:"100%", gap:2}}>
            <MDButton disabled={isLoading} onClick={resetColors} variant="gradient" color="error" size="small" ml="auto">
              reset colors
            </MDButton>
            <MDButton disabled={isLoading} onClick={updateColors} variant="gradient" color="info" size="small" ml="auto">
              update colors
            </MDButton>
          </MDBox>
        </Grid>
      </Grid>
    </MDBox>
  )
}

AlarmTab.propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  handleDelete: PropTypes.func.isRequired,
  preferences: PropTypes.array.isRequired
}

export default AlarmTab;