import Card from "@mui/material/Card";
import {CircularProgress, Input, Modal} from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBase/MDBox";
import React, {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDBase/MDButton";
import { getEndpointWidgetData, getUploadedMeterTemplate, postCreateMeter } from "util/APIHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";
import {parse} from "papaparse"
import {objectToCSV, toNumber} from "util/UtilHelper";
import UploadedMeterList from "components/Lists/UploadedMeterList";
import MeterConfigModal from "components/Modals/MeterConfigModal";
import { allSites } from "signals/group_signals";

function RegisterMeterModal({open, handleClose}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;
  const [isLoading, setLoading] = useState(false);
  const [meters, setMeters] = useState([]);

  useEffect(() => {
    setMeters([]);
    if (open) {
      getData()
    }
  }, [open]);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };
  const [endpoints, setEndpointData] = useState([]);
  const getData = async () => {
    const endpointResponse = await getEndpointWidgetData(allSites.value.map(e=>e.id).join(","), false);
    setEndpointData(endpointResponse.content.uploaded ?? []);
  }

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await postCreateMeter(meters);

      if (response.success) {
        handleSB({
          color: "success",
          icon: "check",
          title: "Success",
          content: "Meters successfully registered"
        });
      } else {
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: "Error registering meters"
        });
      }
    } catch (e) {
      console.error()
    }
    setLoading(false);
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  const [isDownloading, setDownloading] = useState(false);
  const handleDownloadTemplate = async () => {
    setDownloading(true);
    try {
      const response = await getUploadedMeterTemplate();

      const contents = []
      contents.push("Endpoint,Meter Serial Number,MIU Serial Number,Latitude,Longitude,ICCID,RFUID,Custom 1,Custom 2,Custom 3,Custom 4,Custom 5,Client Identity,Client Key,Status")// CSV header
      response.content.map((item) => {
        contents.push(objectToCSV(item))
      })
      const encodedUri = encodeURI(`data:text/csv;charset=utf-8,${contents.join('\n')+'\n'}`);
      const link = document.createElement("a");
      link.setAttribute("href", encodedUri);
      link.setAttribute("download", "meter_template.csv");
      document.body.appendChild(link);
      link.click();
    } catch (e) {
      console.error(e)
    }
    setDownloading(false);
  }

  const [isUploading, setUploading] = useState(false);
  const handleSelectFile = async (event) => {
    setUploading(true);
    const file = event.target.files[0];
    if (file) {
      parse(file, {
        complete: (result) => {
          const parsedMeters = result.data.slice(1).map((item) => ({
            endpoint: item[0],
            meterSerialNumber: item[1],
            miuSerialNumber: item[2],
            latitude: toNumber(item[3]),
            longitude: toNumber(item[4]),
            iccid: item[5],
            rfuid: item[6],
            custom1: item[7],
            custom2: item[8],
            custom3: item[9],
            custom4: item[10],
            custom5: item[11],
            clientIdentity: item[12],
            clientKey: item[13],
            status: toNumber(item[14], 1),
          }));
          setMeters(parsedMeters);
        },
        header: false, // Indicate that the first row contains headers
        skipEmptyLines: true,
      });
    }
    setUploading(false);
  };

  const uploadPanel = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDBox display="flex" alignItems="center" >
          <MDButton sx={{width:"9rem"}} disabled={isDownloading} size="medium" fullWidth variant="outlined" color="info" onClick={handleDownloadTemplate}>
            {
              isDownloading?(
                <CircularProgress color="white" size="1rem"/>
              ):(
                <><Icon>download</Icon>&nbsp; Download Template</>
              )
            }
          </MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <MDBox display="flex" alignItems="center" >
          <MDButton component="label" sx={{width:"9rem"}} size="medium" fullWidth variant="gradient" color="info">
            {
              isUploading?(
                <CircularProgress color="white" size="1rem"/>
              ):(
                <>
                  Select File
                  <Input
                    type="file"
                    hidden
                    accept=".csv"
                    onChange={handleSelectFile}
                  />
                </>
              )
            }
          </MDButton>
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <MDButton sx={{width:"9rem"}} disabled={isLoading || meters.length===0} size="medium" fullWidth variant="gradient" color="info" onClick={handleSubmit}>
          {
            isLoading?(
              <CircularProgress color="white" size="1rem"/>
            ):`Register ${meters.length} Meter${meters.length>1?'s':''}`
          }
        </MDButton>
      </Grid>
    </Grid>
  );

  const [editData, setEditData] = useState({});
  const [editMeterModal, setEditMeterModal] = useState(false);
  const handleOpenMeterEditModal = (meterConfigData) => {
    setEditData(meterConfigData)
    setEditMeterModal(true)
  };
  const handleCloseMeterEditModal = () => setEditMeterModal(false);

  const handleEditOnSubmit = (item) => {
    let m1 = meters;
    m1[item.index] = {
      ...item,
      latitude: toNumber(item.latitude),
      longitude: toNumber(item.longitude)
    };
    setMeters(m1)
    handleCloseMeterEditModal()
  }

  return (
    <>
      <Modal
        open={open}
        onClose={handleClose}
      >
        <Card sx={style}>
          <MDTypography sx={{display:"flex", justifyContent:"center"}} variant="h4">
            Register Meter
          </MDTypography>
          <Divider />
          <MDBox display="flex">
            <MDBox sx={{maxWidth: '150px', minWidth: '100px'}}>
              {uploadPanel()}
            </MDBox>

            <Divider sx={{height:'auto'}} orientation="vertical" flexItem/>

            <MDBox sx={{ width:"100%", overflowX: 'hidden'}}>
              <UploadedMeterList endpoints={endpoints} uploadedMeters={meters} onClick={handleOpenMeterEditModal} />
            </MDBox>
          </MDBox>
        </Card>
      </Modal>

      <MeterConfigModal data={{}} edit_data={editData} edit_onSubmit={handleEditOnSubmit} handleClose={handleCloseMeterEditModal} open={editMeterModal} />
    </>
  )
}

RegisterMeterModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired
}
export default RegisterMeterModal