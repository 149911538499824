export const maskPresets = {
  second: ['--', ...Array.from({length: 60}, (_, i) => i.toString().padStart(2, '0'))],
  minute: ['--', ...Array.from({length: 60}, (_, i) => i.toString().padStart(2, '0'))],
  hour: ['--', ...Array.from({length: 24}, (_, i) => i.toString().padStart(2, '0'))],
  day: ['--', ...Array.from({length: 31}, (_, i) => (i + 1).toString().padStart(2, '0'))],
  month: ['--', ...Array.from({length: 12}, (_, i) => (i + 1).toString().padStart(2, '0'))],
  year: ['--', ...Array.from({length: 100}, (_, i) => i.toString().padStart(2, '0'))]
};

export const intervalPresets = {
  log: [
    { description: '1 Minute', value: 60},
    { description: '5 Minutes', value: 300},
    { description: '10 Minutes', value: 600},
    { description: '15 Minutes', value: 900},
    { description: '1 Hour', value: 3600},
  ],

  transmit: [
    { description: '1 Minute', value: 60},
    { description: '5 Minutes', value: 300},
    { description: '10 Minutes', value: 600},
    { description: '1 Hour', value: 3600},
    { description: '2 Hour', value: 7200},
    { description: '6 Hour', value: 21600},
    { description: '12 Hour', value: 43200},
    { description: '1 Day', value: 86400},
    { description: '1 Week', value: 604800},
  ]
}