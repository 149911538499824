import { useRef, useState } from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Line } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler,
} from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// DefaultLineChart configurations
import configs from "components/MDComponents/Charts/LineCharts/DefaultLineChart/configs";

// MD React base styles
import colors from "assets/theme/base/colors";
import {CircularProgress, Skeleton} from "@mui/material";
import { downloadChart, isValidHex } from "util/UtilHelper";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {downloadExcel} from "util/ExcelUtil";

ChartJS.register(
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    Title,
    Tooltip,
    Legend,
    Filler
);

function DefaultLineChart({ icon, title, description, height, chart, loading, legend, smooth, extraAction }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => ({
      ...dataset,
      tension: smooth ? 0.4 : 0,
      pointRadius: smooth ? 0 : 3,
      borderWidth: 4,
      backgroundColor: "#00000000",
      fill: true,
      pointBackgroundColor: isValidHex(dataset.color)
        ? dataset.color // Use the color if it's a valid hex
        : colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
      borderColor: isValidHex(dataset.color)
        ? dataset.color // Use the color if it's a valid hex
        : colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main,
      maxBarThickness: 6,
    }))
    : [];

  const { data, options } = configs(chart.labels || [], chartDatasets);

  options.scales = {
    ...options.scales,
    x: {
      type: 'category',
      ticks: {
        ...options.scales.x.ticks,
        callback: function (value, index) {
          return (chart.x_axis)?chart.x_axis[index]:chart.labels[index];
        }
      }
    }
  };

  options.plugins = {
    legend: {
      display: legend,
    },
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const chartRef = useRef(null);
  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "dark"}
              variant="gradient"
              coloredShadow={icon.color || "dark"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0} mr="auto">
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              {
                loading?<Skeleton />:
                  <MDTypography component="div" variant="button" color="text">
                    {description}
                  </MDTypography>
              }
            </MDBox>
          </MDBox>
          {extraAction}
          <IconButton
            disabled={loading}
            color="info"
            onClick={handleMenuOpen}
            sx={{ margin: "-0.5rem -0.5rem 0 0" }}
          >
            <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { downloadChart(chartRef, title); handleMenuClose(); }}>Download Image</MenuItem>
            <MenuItem
              onClick={() => {
                const { labels, datasets } = chart
                const items = []

                if (labels && datasets) {
                  for (let x=0; x<labels.length; x++) {
                    const item = {}
                    item["Datetime"] = labels[x];
                    for (let i=0; i<datasets.length; i++) {
                      if (title.toLowerCase().includes("consumption")) {
                        item[datasets[i].label] = datasets[i].data[x].toFixed(4);
                      } else {
                        item[datasets[i].label] = datasets[i].data[x];
                      }
                    }
                    items.push(item)
                  }
                }

                const contents = {
                  "Data": items
                }
                downloadExcel(title, contents);
              }}
            >
              Download Excel
            </MenuItem>
          </Menu>
        </MDBox>
      ) : null}
      {loading?(
        <MDBox height={height} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CircularProgress color="info" />
        </MDBox>
      ):(
        <MDBox height={height}>
          <Line ref={chartRef} data={data} options={options} redraw />
        </MDBox>
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of DefaultLineChart
DefaultLineChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  loading: false,
  legend: false,
  smooth: false,
  extraAction: <></>
};

// Typechecking props for the DefaultLineChart
DefaultLineChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
  loading: PropTypes.bool,
  legend: PropTypes.bool,
  smooth: PropTypes.bool,
  extraAction: PropTypes.node
};

export default DefaultLineChart;
