/* eslint-disable react/prop-types */
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import DownlinkStatusCell from "components/TableCells/DownlinkTable/DownlinkStatusCell";
import DownlinkParameterCell from "components/TableCells/DownlinkTable/DownlinkParameterCell";
import { getDownlinkStatusText } from "util/LookupUtil";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";
import React from "react";

export const columnDefs = [
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "Command", accessorKey: "command" },
  { header: "Parameters", accessorKey: "parameters",
    Cell: ({ cell }) => <DownlinkParameterCell parameters={cell.getValue()} />
  },
  { header: "Status", accessorKey: "status",
    Cell: ({ cell }) => <DownlinkStatusCell status={cell.getValue()} /> },
  {
    header: "Created Timestamp", accessorKey: "created_timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.createdUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.createdUtc, rowB.original.item.createdUtc),
  },
  {
    header: "Completed Timestamp", accessorKey: "completed_timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.modifiedUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.modifiedUtc, rowB.original.item.modifiedUtc),
  }
]

export const downlinkTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      command: singular.commandName,
      parameters: singular.parameters.reduce((accumulator, param) =>
        accumulator + `${param.description}: ${param.value}\n`, ''
      ),
      created_timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.createdUtc))),
      completed_timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.modifiedUtc))),
      status: getDownlinkStatusText(singular.requestStatusDesc)
    }

  });
}