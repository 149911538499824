/* eslint-disable no-dupe-keys */
// MD React base styles
import colors from "assets/theme/base/colors";
const { gradients, dark } = colors;

import palette from "assets/colors";
import { isValidHex } from "util/UtilHelper";
const { material } = palette;

function configs(labels, datasets) {
  const backgroundColors = [];

  if (datasets.backgroundColors) {
    datasets.backgroundColors.forEach((color) => {
        if (color.split('-')[0] === 'material') {
          return material[color.split('-')[1]]
            ? backgroundColors.push(material[color.split('-')[1]][500])
            : backgroundColors.push(dark.main)
        } else if (isValidHex(color)) {
          return color
            ? backgroundColors.push(color)
            : backgroundColors.push(dark.main)
        } else {
          return gradients[color]
            ? backgroundColors.push(gradients[color].state)
            : backgroundColors.push(dark.main)
        }
      }
    );
  } else {
    backgroundColors.push(dark.main);
  }

  return {
    data: {
      labels,
      datasets: [
        {
          label: datasets.label,
          weight: 9,
          cutout: 0,
          tension: 0.9,
          pointRadius: 2,
          borderWidth: 2,
          backgroundColor: backgroundColors,
          fill: false,
          data: datasets.data,
        },
      ],
    },
    options: {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        legend: {
          position: 'right'
        },
      },
    },
  };
}

export default configs;
