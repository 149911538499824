/* eslint-disable react/prop-types */
import LinkCell from "../../components/TableCells/General/LinkCell";
import React from "react";

export const columnDefs = [
  { header: "Site Name", accessorKey: "site_name", },
  { header: "Total Meters",  accessorKey: "total_meters",
    Cell: ({ cell, row }) => (
      <LinkCell href={`/meter?site=${row.original.id}`} text={cell.getValue().toString()}/>
    )
  },
]

export const siteTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      id: singular.id,
      site_name: singular.description,
      total_meters: singular.meterCount,
      noAction: (singular.id === 0)
    }
  });
}