import { useState, useEffect } from "react";

// react-router components
import { useLocation, useNavigate } from "react-router-dom";

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// @material-ui core components
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";

// MD React examples
import Breadcrumbs from "components/MDComponents/Breadcrumbs";

// Custom styles for DashboardNavbar
import {
  navbar,
  navbarContainer,
  navbarRow,
  navbarIconButton,
  navbarDesktopMenu,
  navbarMobileMenu,
} from "components/MDComponents/Navbars/DashboardNavbar/styles";

// MD React context
import {
  useMaterialUIController,
  setTransparentNavbar,
  setMiniSidenav,
  setDarkMode,
} from "context/md";
import LogoutModal from "components/Modals/LogoutModal";
import MDInput from "components/MDBase/MDInput";
import Autocomplete from "@mui/material/Autocomplete";
import { allSites, selectedSites } from "signals/group_signals";
import {v4 as uuidv4} from "uuid";

function DashboardNavbar({ absolute, light, isMini, title, hideSites }) {
  const navigate = useNavigate()
  const [navbarType, setNavbarType] = useState();
  const [controller, dispatch] = useMaterialUIController();
  const { miniSidenav, transparentNavbar, fixedNavbar, darkMode } = controller;
  const route = useLocation().pathname.split("/").slice(1);

  const [logoutModal, setLogoutModal] = useState(false);
  const handleOpenLogoutModal = () => setLogoutModal(true);
  const handleCloseLogoutModal = () => setLogoutModal(false);

  useEffect(() => {
    // Setting the navbar type
    if (fixedNavbar) {
      setNavbarType("sticky");
    } else {
      setNavbarType("static");
    }

    // A function that sets the transparent state of the navbar.
    function handleTransparentNavbar() {
      setTransparentNavbar(dispatch, (fixedNavbar && window.scrollY === 0) || !fixedNavbar);
    }

    /** 
     The event listener that's calling the handleTransparentNavbar function when 
     scrolling the window.
    */
    window.addEventListener("scroll", handleTransparentNavbar);

    // Call the handleTransparentNavbar function to set the state with the initial value.
    handleTransparentNavbar();

    // Remove event listener on cleanup
    return () => window.removeEventListener("scroll", handleTransparentNavbar);
  }, [dispatch, fixedNavbar]);

  const handleMiniSidenav = () => setMiniSidenav(dispatch, !miniSidenav);
  const handleDarkMode = () => setDarkMode(dispatch, !darkMode);

  // Styles for the navbar icons
  const iconsStyle = ({ palette: { dark, white, text }, functions: { rgba } }) => ({
    color: () => {
      let colorValue = light || darkMode ? white.main : dark.main;

      if (transparentNavbar && !light) {
        colorValue = darkMode ? rgba(text.main, 0.6) : text.main;
      }

      return colorValue;
    },
  });

  const infoStyle = ({ palette: { info } }) => ({
    color: info.main,
  });

  const [autoKey, setAutoKey] = useState(1);
  useEffect(() => {
    // autocomplete dont refresh its contents even when the value changes,
    // so need to manually refresh
    // theres probably a better way to do this, this is a duct tape solution
    // todo - review better solution
    setAutoKey(uuidv4());
  }, [selectedSites.value, allSites.value]);

  return (
    <AppBar
      position={absolute ? "absolute" : navbarType}
      color="inherit"
      sx={(theme) => navbar(theme, { transparentNavbar, absolute, light, darkMode })}
    >
      <Toolbar sx={(theme) => navbarContainer(theme)}>
        <MDBox color="inherit" mb={{ xs: 1, md: 0 }} sx={(theme) => navbarRow(theme, { isMini })}>
          <Breadcrumbs icon="home" title={route[route.length - 1]} route={route} light={light} overrideTitle={title} />
          {
            (!hideSites)?(
              <Autocomplete
                key={autoKey}
                sx={{ paddingLeft: "1rem", minWidth: "11rem", paddingRight: (isMini)?"": "1rem" }}
                multiple
                limitTags={2}
                value={selectedSites.value}
                groupBy={(option) => option.groupDescription}
                options={allSites.value}
                onChange={(event, value) => {
                  selectedSites.value = value;
                  setAutoKey(uuidv4());
                }}
                isOptionEqualToValue={(option, value) =>
                  option.id === value.id
                }
                getOptionLabel={(option) => option.description}
                renderInput={(params) => <MDInput label="Sites" {...params} />}
              />
            ):null
          }
          <IconButton sx={navbarDesktopMenu} onClick={handleMiniSidenav} size="small" disableRipple>
            <Icon fontSize="medium" sx={iconsStyle}>
              {miniSidenav ? "menu_open" : "menu"}
            </Icon>
          </IconButton>
        </MDBox>
        {isMini ? null : (
          <MDBox sx={(theme) => navbarRow(theme, { isMini })}>
            <MDBox color={light ? "white" : "inherit"}>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={()=>{navigate("/profile")}}>
                <Icon sx={(route[0] === "profile")?infoStyle:iconsStyle}>account_circle</Icon>
              </IconButton>
              <IconButton
                size="small"
                disableRipple
                color="inherit"
                sx={navbarMobileMenu}
                onClick={handleMiniSidenav}
              >
                <Icon sx={iconsStyle} fontSize="medium">
                  {miniSidenav ? "menu_open" : "menu"}
                </Icon>
              </IconButton>
              <IconButton size="small" disableRipple color="inherit" onClick={handleDarkMode}>
                <Icon sx={iconsStyle} fontSize="medium">
                  {darkMode ? "dark_mode" : "light_mode"}
                </Icon>
              </IconButton>
              <IconButton sx={navbarIconButton} size="small" disableRipple onClick={handleOpenLogoutModal}>
                <Icon sx={iconsStyle}>logout</Icon>
              </IconButton>
            </MDBox>
          </MDBox>
        )}
      </Toolbar>
      <LogoutModal handleClose={handleCloseLogoutModal} open={logoutModal} />
    </AppBar>
  );
}

// Setting default values for the props of DashboardNavbar
DashboardNavbar.defaultProps = {
  absolute: false,
  light: false,
  isMini: false,
  hideSites: false,
};

// Typechecking props for the DashboardNavbar
DashboardNavbar.propTypes = {
  absolute: PropTypes.bool,
  light: PropTypes.bool,
  isMini: PropTypes.bool,
  title: PropTypes.string,
  hideSites: PropTypes.bool
};

export default DashboardNavbar;
