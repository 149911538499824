import {useEffect, useState} from "react";
import Grid from "@mui/material/Grid";
import MDBox from "components/MDBase/MDBox";
import PropTypes from "prop-types";
import {getAlarm, getInsightConsumption, getReading} from "util/APIHelper";
import TemperatureCard from "./components/temperatureCard";
import BatteryLevelCard from "./components/batteryLevelCard";
import LatestReadingCard from "./components/latestReadingCard";
import PastConsumptionCard from "./components/pastConsumptionCard";
import InfoCard from "./components/infoCard";
import AlarmCard from "./components/alarmCard";
import GraphCarousel from "layouts/meter/details/insight/components/graphCarousel";

function DetailsInsight({endpoint, meterData, meterDataLoading}) {
  const [batteryLevel, setBatteryLevel] = useState(100);
  const [temperature, setTemperature] = useState(0);
  const [timestamp, setTimestamp] = useState(0);
  const [latestReading, setLatestReading] = useState(0);
  const [pastConsumption, setPastConsumption] = useState(0);
  const [alarm, setAlarm] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getReading(endpoint, 1)
      setBatteryLevel(response.content[0].batteryLevel);
      setTemperature(response.content[0].temperature);
      setLatestReading(response.content[0].meterReading);
      setTimestamp(response.content[0].timestampUtc);

      const insightConsumptionResponse = await getInsightConsumption(endpoint, "default")
      setPastConsumption(insightConsumptionResponse.content.value)

      const alarmResponse = await getAlarm(endpoint, 1)
      setAlarm(alarmResponse.content[0])

    } catch (e) {
      console.error(e)
    }
    setLoading(false);
  }

  const widgetWrapper = (xs, lg, item) => (
    <Grid item xs={xs} lg={lg}>
      {item}
    </Grid>
  );
  return (
    <Grid spacing={3} container>
      <Grid xs={12} item>
        <MDBox>
          <Grid spacing={3} container>
            { widgetWrapper(12, 6, <InfoCard meterData={meterData} loading={meterDataLoading}/>) }
            { widgetWrapper(12, 6, <AlarmCard loading={loading} alarm={alarm} />) }
          </Grid>
        </MDBox>
      </Grid>

      <Grid xs={12} item>
        <MDBox>
          <Grid spacing={3} container>
            { widgetWrapper(6, 3, <LatestReadingCard loading={loading} reading={latestReading} timestamp={timestamp} />) }
            { widgetWrapper(6, 3, <PastConsumptionCard loading={loading} consumption={pastConsumption} />) }
            { widgetWrapper(6, 3, <TemperatureCard loading={loading} temperature={temperature} timestamp={timestamp} />) }
            { widgetWrapper(6, 3, <BatteryLevelCard loading={loading} batteryLevel={batteryLevel} timestamp={timestamp} />) }
          </Grid>
        </MDBox>
      </Grid>

      <Grid xs={12} item>
        <GraphCarousel endpoint={endpoint}/>
      </Grid>
    </Grid>
  );
}

DetailsInsight.propTypes = {
  endpoint: PropTypes.string.isRequired,
  meterData: PropTypes.object.isRequired,
  meterDataLoading: PropTypes.bool.isRequired
}
export default DetailsInsight;