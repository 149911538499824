import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Card from "@mui/material/Card";
import DetailsInsight from "./insight";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import breakpoints from "assets/theme/base/breakpoints";
import {setURLParam} from "util/UtilHelper";
import {getMeterDetail} from "util/APIHelper";
import Meter from "model/Meter";
import DetailsAlarm from "layouts/meter/details/alarm";
import DetailsReading from "layouts/meter/details/reading";
import DetailsStatus from "layouts/meter/details/status";
import DetailsAnomaly from "layouts/meter/details/anomaly";
import DetailsDownlink from "layouts/meter/details/downlink";
import DetailsFirmware from "layouts/meter/details/firmware";
import DetailsAnalysis from "layouts/meter/details/analysis";
import DetailsMonthlyConsumption from "layouts/meter/details/consumption";
import {useMaterialUIController} from "context/md";

function MeterDetails() {
  const [controller, dispatch] = useMaterialUIController();
  const {user} = controller;
  const [tabsOrientation, setTabsOrientation] = useState("horizontal");
  const [tabValue, setTabValue] = useState(0);
  const [meterData, setMeterData] = useState({});
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const params = new URLSearchParams(location.search);
  const endpoint = params.get("endpoint")
  const tab = params.get("tab");

  useEffect(() => {
    if (endpoint === null) {
      navigate("/meter")
    } else {
      getData()
    }
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeterDetail(endpoint);
      const meter = new Meter(endpoint, response.content)
      setMeterData(meter)
    } catch (e) {
      console.error(e)
      navigate("/meter")
    }
    setLoading(false);
  }

  useEffect(() => {
    let newTabValue;
    switch (tab) {
      case "insight": newTabValue = 0; break;
      case "analysis": newTabValue = 1; break;
      case "consumption": newTabValue = 2; break;
      case "alarm": newTabValue = 3; break;
      case "reading": newTabValue = 4; break;
      case "status": newTabValue = 5; break;
      case "anomaly": newTabValue = 6; break;
      case "downlink": newTabValue = user?.isAdminAndAbove() ? 7 : 0; break; // Redirect non-admins to default
      case "firmware": newTabValue = user?.isAdminAndAbove() ? 8 : 0; break; // Redirect non-admins to default
      default: newTabValue = 0; break;
    }
    setTabValue(newTabValue);
  }, [tab, user]);

  useEffect(() => {
    // A function that sets the orientation state of the tabs.
    function handleTabsOrientation() {
      return window.innerWidth < breakpoints.values.sm
        ? setTabsOrientation("vertical")
        : setTabsOrientation("horizontal");
    }

    window.addEventListener("resize", handleTabsOrientation);
    handleTabsOrientation();

    return () => window.removeEventListener("resize", handleTabsOrientation);
  }, [tabsOrientation]);

  const toggleComponent = () => (
    <Card sx={{mb:"2rem", mt:"0.5rem"}}>
      <Tabs orientation={tabsOrientation} value={tabValue} onChange={handleSetTabValue}>
        <Tab label="Insight" />
        <Tab label="Analysis"/>
        <Tab label="Monthly Consumption" />
        <Tab label="Alarm"/>
        <Tab label="Reading"/>
        <Tab label="Status"/>
        <Tab label="Anomaly"/>
        {user?.isAdminAndAbove() && <Tab label="Downlink"/>}
        {user?.isAdminAndAbove() && <Tab label="Firmware"/>}
      </Tabs>
    </Card>
  )

  const handleSetTabValue = (event, newValue) => {
    setTabValue(newValue);

    // Update the URL with the new toggle value
    let newTabValue
    switch (newValue) {
      case 0: newTabValue = "insight"; break;
      case 1: newTabValue = "analysis"; break;
      case 2: newTabValue = "consumption"; break;
      case 3: newTabValue = "alarm"; break;
      case 4: newTabValue = "reading"; break;
      case 5: newTabValue = "status"; break;
      case 6: newTabValue = "anomaly"; break;
      case 7: newTabValue = "downlink"; break;
      case 8: newTabValue = "firmware"; break;
      default: newTabValue = "insight"; break;
    }
    setURLParam("tab", newTabValue)
  };

  const handleTabContent = () => {
    switch (tabValue) {
      case 0: return <DetailsInsight endpoint={endpoint} meterData={meterData} meterDataLoading={loading}/>;
      case 1: return <DetailsAnalysis endpoint={endpoint} />;
      case 2: return <DetailsMonthlyConsumption endpoint={endpoint} />;
      case 3: return <DetailsAlarm endpoint={endpoint} />;
      case 4: return <DetailsReading endpoint={endpoint} />;
      case 5: return <DetailsStatus endpoint={endpoint} />;
      case 6: return <DetailsAnomaly endpoint={endpoint} />;
      case 7: return user?.isAdminAndAbove() ? <DetailsDownlink endpoint={endpoint} /> : <DetailsInsight endpoint={endpoint} meterData={meterData} meterDataLoading={loading}/>;
      case 8: return user?.isAdminAndAbove() ? <DetailsFirmware endpoint={endpoint} /> : <DetailsInsight endpoint={endpoint} meterData={meterData} meterDataLoading={loading}/>;
      default: return <DetailsInsight endpoint={endpoint} meterData={meterData} meterDataLoading={loading}/>
    }
  }

  return (
    <DashboardLayout>
      <DashboardNavbar title={(meterData.meter_sn)?meterData.meter_sn:endpoint} hideSites />
      {toggleComponent()}
      {handleTabContent()}
    </DashboardLayout>
  );
}

export default MeterDetails;
