import React, { useEffect, useRef, useState } from "react";
import { Network } from "vis-network";
import MDBox from "../../components/MDBase/MDBox";
import DashboardNavbar from "../../components/MDComponents/Navbars/DashboardNavbar";
import DashboardLayout from "../../components/MDComponents/LayoutContainers/DashboardLayout";
import { OPTIONS } from "./settings";
import { getMeshStatus } from "../../util/APIHelper";
import Card from "@mui/material/Card";
import MDTypography from "../../components/MDBase/MDTypography";
import SearchView from "./searchView";
import MDDatePicker from "../../components/MDBase/MDDatePicker";
import MDButton from "../../components/MDBase/MDButton";

const MeshNetworkVisualizer = () => {
  const [loading, setLoading] = useState(false);

  // graph
  const networkContainer = useRef(null);
  const [network, setNetwork] = useState(null);

  const [searchListItem, setSearchListItem] = useState([]);
  const [nodes, setNodes] = useState([]);
  let edges = [];

  // date pickers
  const dateTimeFormat = "Y-m-d, h:i K";
  let thirtyDaysAgo = new Date(); // 30 days ago at 12:00 AM
  thirtyDaysAgo.setDate(new Date().getDate() - 30);
  thirtyDaysAgo.setHours(0, 0, 0, 0); // Set time to 12:00 AM

  let todayEnd = new Date();
  todayEnd.setHours(23, 59, 59, 999); // Set time to 11:59 PM

  const [startDate, setStartDate] = useState(thirtyDaysAgo);
  const [endDate, setEndDate] = useState(todayEnd);
  const handleStartDateChange = (date) => {
    setStartDate(date[0]);
  };
  const handleEndDateChange = (date) => {
    setEndDate(date[0]);
  };

  const addSearchListItem = (newNode) => {
    setSearchListItem((prevNodes) => [...prevNodes, newNode]);
  };

  useEffect(() => {
    getData();
  }, []);

  const getData = async () => {
    setLoading(true);
    try {
      const startTimestampUtc = Math.floor(startDate.getTime() / 1000);
      const endTimestampUtc = Math.floor(endDate.getTime() / 1000);
      const wrapper = await getMeshStatus(startTimestampUtc, endTimestampUtc);
      const servers = wrapper.content.server;
      const data = wrapper.content.nodes;

      // reset
      setSearchListItem([]);
      setNodes([]);
      edges = [];
      setSearchListItem(data);

      servers.forEach((server) => {
        const node = {
          id: server.id,
          label: server.serverIp + "\n" + server.name,
          group: "Server",
        };
        nodes.push(node);
      });

      data.forEach((item) => {
        const nodeId = item.endpointId;
        const node = {
          id: nodeId,
          label: item.endpoint,
          group: item.parentEndpointId ? "Node" : "Gateway",
        };

        nodes.push(node);

        // arrows
        if (item.parentEndpointId) {
          let color;
          if (item.signalStrength >= 0 && item.signalStrength <= 50) {
            color = "red";
          } else {
            color = "green";
          }
          const signalStrength = item.signalStrength != null ? item.signalStrength.toString() : "N/A";
          const timestamp = item.timestampUtc != null ? new Date(item.timestampUtc * 1000).toLocaleString() : "N/A";
          edges.push({
            from: nodeId,
            to: item.parentEndpointId,
            arrows: "to",
            label: "Signal Strength: " +  signalStrength + "\n" +  timestamp,
            color: { color: color, highlight: color },
            font: { color: "orange", strokeWidth: 0, size: 15},
          });
        }
      });

      const meshData = {
        nodes: nodes,
        edges: edges,
      };

      const options = {
        ...OPTIONS,
        layout: {
          improvedLayout: nodes.length <= 150,
          hierarchical: {
            direction: "DU",  // UD for top to bottom, DU for bottom to top
            sortMethod: "directed",  // Other options: hubsize, directed
          },
        },
      };
      const localNetwork = new Network(networkContainer.current, meshData, options);
      setNetwork(localNetwork);
      // update state

      return () => {
        network.destroy();
      };

    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  return (
    <DashboardLayout>
      <DashboardNavbar />
      <Card>
        {/*<MDTypography>*/}
        {/*  TODOs*/}
        {/*  2. allow selection of time request param*/}
        {/*  4. physics toggle*/}
        {/*</MDTypography>*/}

        {/* top bar */}
        <MDBox sx={{
          display: "flex", flexDirection: "row", alignItems: "center",
          padding: "15px",
        }}>
          <MDDatePicker
            value={startDate}
            onChange={handleStartDateChange}
            placeholder="Start Date"
            options={{
              enableTime: true,
              dateFormat: dateTimeFormat,
              maxDate: todayEnd,
            }}
          />
          <MDTypography variant="body2" fontWeight="light" color="text">
            &nbsp; to &nbsp;
          </MDTypography>
          <MDDatePicker
            value={endDate}
            onChange={handleEndDateChange}
            placeholder="End Date"
            options={{
              enableTime: true,
              dateFormat: dateTimeFormat,
              minDate: startDate,
              maxDate: todayEnd,
            }}
          />
          <MDButton
            disabled={loading}
            sx={{ ml: 2 }}
            variant="gradient"
            color="info"
            onClick={() => {
              getData();
            }}
          >
            Visualize
          </MDButton>
        </MDBox>

        <MDBox display="flex" flexDirection="row" justifyContent="spaceBetween" alignItems="center" flexWrap="wrap" position="relative"
               sx={{ height: "80vh", width: "100%" }}>

          {/* search list (left) */}
          <MDBox style={{
            position: "absolute",
            top: "0",
            left: "0",
            padding: "0 15px 15px 15px",
            height: "100%",
            zIndex: 1,
          }}>
            {SearchView({ nodes: searchListItem, network })}
          </MDBox>

          {/* main view */}
          <MDBox ref={networkContainer} sx={{ height: "100%", width: "100%" }} />
        </MDBox>

      </Card>
    </DashboardLayout>
  );
};

export default MeshNetworkVisualizer;
