import PropTypes from "prop-types";
import MDBadge from "components/MDBase/MDBadge";

function AccessLevelCell({access}) {
  let color;

  switch (access) {
    case "viewer": color = "secondary"; break;
    case "admin": color = "warning"; break;
    case "superuser": color = "error"; break;
    default: color="dark";break;
  }

  return (
    <MDBadge variant="contained" color={color} size="lg" badgeContent={access} />
  );
}

AccessLevelCell.propTypes = {
  access: PropTypes.string.isRequired
}

export default AccessLevelCell;