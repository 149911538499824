import PropTypes from "prop-types";
import StatisticsCard from "components/Cards/StatisticsCard";
import { formatDate } from "util/UtilHelper";


function PastConsumptionCard({consumption, loading}) {

  function getFormattedDateRange() {

    const today = new Date();
    const past30Days = new Date(today);
    past30Days.setDate(today.getDate() - 30);

    const formattedFrom = formatDate(localStorage.getItem("datetime-format"), past30Days, true)
    const formattedTo = formatDate(localStorage.getItem("datetime-format"), today, true)
    return `${formattedFrom} - ${formattedTo}`;
  }

  return (
    <StatisticsCard
      color="info"
      icon="water_drop"
      title="30 Days Consumption"
      count={`${(consumption/1000).toFixed(4)} m³`}
      percentage={{
        label: getFormattedDateRange(),
      }}
      isLoading={loading}
    />
  );
}

PastConsumptionCard.defaultProps = {
  loading: false
}

PastConsumptionCard.propTypes = {
  consumption: PropTypes.number.isRequired,
  loading: PropTypes.bool
}

export default PastConsumptionCard;