import React, { useEffect, useState } from "react";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import MDBox from "components/MDBase/MDBox";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import MRTable from "components/MaterialReactTable";
import { useMaterialUIController } from "context/md";
import { getGroupList, postUserPreference } from "util/APIHelper";
import { columnDefs, groupTableProcess } from "./tableUtil";
import MenuItem from "@mui/material/MenuItem";
import GroupAddEditModal from "components/Modals/GroupAddEditModal";
import SiteAddEditModal from "components/Modals/SiteAddEditModal";

function Groups() {
  const [groups, setGroups] = useState([]);
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [addGroupModal, setAddGroupModal] = useState(false);
  const [addSiteModal, setAddSiteModal] = useState(false);
  const [groupName, setGroupName] = useState(null);
  const [groupId, setGroupId] = useState(null);
  const [groupInfo, setGroupInfo] = useState({});
  const [isEdit, setIsEdit] = useState(false);

  useEffect(() => {
    getData()
  }, []);

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("group_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("group_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "group_column_state",
          value: state
        }
      ])
    }
  }

  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      const response = await getGroupList()
      setGroups(groupTableProcess(response.content));

    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
  }

  const handleOpenGroupModal = () => setAddGroupModal(true);
  const handleCloseGroupModal = () => {
    getData(true)
    setAddGroupModal(false)
    setGroupInfo({});
    setIsEdit(false);
  };

  const handleOpenSiteModal = (groupName, groupId) => {
    setGroupName(groupName);
    setGroupId(groupId);
    setAddSiteModal(true);
  }
  const handleCloseSiteModal = () => {
    getData(true)
    setAddSiteModal(false);
    setGroupName(null);
    setGroupId(null);
  };

  const handleOpenEditGroupModal = (groupName, groupId) => {
    setIsEdit(true);
    setGroupInfo({
      id: groupId,
      name: groupName
    })
    setAddGroupModal(true);
  }

  return (
    <DashboardLayout>
      <DashboardNavbar hideSites />
      <MRTable
        columns={columnDefs}
        data={groups}
        isLoading={loading}
        isRefetching={wsLoading}
        onRefresh={getData}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
        customTopRightToolbar={()=> {
          return user?.isAdminAndAbove()?(
            <MDBox sx={{margin:"0 0.5rem 1rem 0"}} >
              <MDButton onClick={handleOpenGroupModal} variant="outlined" color="info" sx={{marginRight:"0rem"}}>
                <Icon>add</Icon>&nbsp; Add Group
              </MDButton>
            </MDBox>
          ):null
        }}
        rowActionMenuItems={(user?.isAdminAndAbove())?({row, closeMenu})=> {
          if (row.original.noAction) {
            return []
          } else return [
            <MenuItem key="edit" onClick={() => {
              handleOpenEditGroupModal(row.original.group_name, row.original.id);
              closeMenu();
            }}>
              <Icon fontSize="small" >edit</Icon>&nbsp; Edit Group
            </MenuItem>,

            <MenuItem key="add_site" onClick={() => {
              handleOpenSiteModal(row.original.group_name, row.original.id);
              closeMenu();
            }}>
              <Icon fontSize="small" >add</Icon>&nbsp; Add Site
            </MenuItem>,
          ]
        }:null}
      />
      <GroupAddEditModal handleClose={handleCloseGroupModal} open={addGroupModal} isEdit={isEdit} groupInfo={groupInfo}/>
      <SiteAddEditModal handleClose={handleCloseSiteModal} open={addSiteModal} groupName={groupName} groupId={String(groupId)} />
    </DashboardLayout>
  );
}

export default Groups;
