// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-flatpickr components
import Flatpickr from "react-flatpickr";

// react-flatpickr styles
import "flatpickr/dist/flatpickr.css";

// MD React components
import MDInput from "components/MDBase/MDInput";

function MDDatePicker({ input, placeholder, range, ...rest }) {
  return (
    <Flatpickr
      {...rest}
      render={({ defaultValue }, ref) => (
        <MDInput sx={range?{minWidth:"11.7rem"}:{}} {...input} defaultValue={defaultValue} placeholder={placeholder} inputRef={ref} />
      )}
    />
  );
}

// Setting default values for the props of MDDatePicker
MDDatePicker.defaultProps = {
  placeholder: "",
  range: false,
  input: {},
};

// Typechecking props for the MDDatePicker
MDDatePicker.propTypes = {
  placeholder: PropTypes.string,
  range: PropTypes.bool,
  input: PropTypes.objectOf(PropTypes.any),
};

export default MDDatePicker;
