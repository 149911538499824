import PropTypes from "prop-types";
import {useEffect, useState} from "react";
import {checkStatus} from "util/UtilHelper";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import {Card} from "@mui/material";
import MDBadge from "components/MDBase/MDBadge";
import Tooltip from "@mui/material/Tooltip";

const GroupKPIWidgetIndividual = ({endpointIntervals, data, column, columnValue}) => {
  const [percentage, setPercentage] = useState("0")
  const [totalExpected, setTotalExpected] = useState(1)
  const [totalReceived, setTotalReceived] = useState(0)
  const [color, setColor] = useState("success");

  useEffect(() => {
    calculate();
  }, [data]);

  const calculate = () => {
    if (endpointIntervals.length === 0) { return 0; }
    const totalExpected = (endpointIntervals.reduce((acc, obj) => acc + obj.expectedLogCount, 0)) * data.length;
    const totalReceived = data.reduce((acc, obj) =>
      acc + obj.data.reduce((innerAcc, innerObj) => innerAcc + innerObj.count, 0), 0);

    setTotalExpected(totalExpected)
    setTotalReceived(totalReceived)
    setPercentage(((totalReceived/totalExpected??1)*100).toFixed(2))
    setColor(checkStatus(((totalReceived/totalExpected??1)*100).toFixed(2)));
  }

  return (
    <Card sx={{minWidth: "15rem"}}>
      <MDBox p={3}>
        <Tooltip title={columnValue}>
          <MDTypography variant="body2" color="text" sx={{ overflow: 'hidden', whiteSpace: 'nowrap', textOverflow: 'ellipsis' }}>
            {columnValue}
          </MDTypography>
        </Tooltip>
        <MDTypography variant="caption" color="text">
          {column}
        </MDTypography>
        <MDBox mt={2} mb={1} lineHeight={0}>
          <MDTypography variant="h3" fontWeight="bold">
            {`${percentage} %`}
          </MDTypography>
        </MDBox>
        <MDBadge variant="contained" color={color} badgeContent={`${totalReceived} / ${totalExpected} logs`} container />
      </MDBox>
    </Card>
  );
}

GroupKPIWidgetIndividual.defaultProps = {
  column: "",
  columnValues: ""
}

GroupKPIWidgetIndividual.propTypes = {
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  column: PropTypes.string,
  columnValue: PropTypes.string
}
export default GroupKPIWidgetIndividual;