import Card from "@mui/material/Card";
import { Modal } from "@mui/material";
import PropTypes from "prop-types";

function DataPusherModal({open, handleClose}){

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card sx={style}>
      </Card>
    </Modal>
  )
}

DataPusherModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}
export default DataPusherModal