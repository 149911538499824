export const registrationDescription = (registrationStatus) => {
  switch (registrationStatus) {
    case "onSleeping": return "Asleep"
    case "updated": return "Awake"
    case "onAwake": return "Awake"
    case "registered": return "Registered"
    case "unregistered": return "Offline"
    default: return "Unknown"
  }
}

export const getSignalStrengthColor = (signalStrength) => {
  switch (signalStrength) {
    case "Excellent": return "success";
    case "Good": return "success";
    case "Moderate": return "warning";
    case "Bad": return "error";
    case "Unknown": return "text";
    default: return null;
  }
}

export const getFirmwareStatusColor = (status) => {
  if (status) {
    if (status.status === 0) { return "text"}
    else if (status.status < 4) { return "warning"}
    else if (status.status < 8) { return "success"}
    else if (status.status === 8) { return "success"}
    else { return "error" }
  } else {
    return "text"
  }
}

export const getDownlinkStatusText = (status) => {
  switch (status) {
    case "Completed": return "Success";
    case "New": return "Queued";
    case "PartiallyCompleted": return "Partially Completed";
    case "Failed": return "Fail";
    default: return status;
  }
}

export const defaultDashboardLayout = [
  {w:6, h:4, x:3, y:0, i:"map"},
  {w:1, h:1, x:1, y:3, i:"alarm_leakageDetection"},
  {w:1, h:1, x:2, y:3, i:"alarm_noFlowDetection"},
  {w:1, h:1, x:0, y:3, i:"alarm_burstDetection"},
  {w:3, h:1, x:9, y:0, i:"alarm_backFlowDetection"},
  {w:1, h:1, x:11, y:2, i:"alarm_batteryLow"},
  {w:1, h:1, x:10, y:3, i:"alarm_faultySensor"},
  {w:1, h:1, x:9, y:3, i:"alarm_wireCutDetection"},
  {w:1, h:1, x:11, y:3, i:"alarm_tiltDetection"},
  {w:3, h:1, x:9, y:1, i:"alarm_magnetTamper"},
  {w:2, h:1, x:9, y:2, i:"alarm_rebootDetection"},
  {w:3, h:1, x:0, y:1, i:"graph_anomaly_alarm"},
  {w:3, h:1, x:0, y:2, i:"latest_anomaly"},
  {w:3, h:1, x:0, y:0, i:"graph_connectivity_connected"}
]

export const availableWidgets = [
  {w: 5, h: 3, widget:"map", title: "Map"},
  {w: 1, h: 1, widget:"alarm_leakageDetection", title: "Alarm Counter (Leak)"},
  {w: 1, h: 1, widget:"alarm_noFlowDetection", title: "Alarm Counter (No Flow)"},
  {w: 1, h: 1, widget:"alarm_burstDetection", title: "Alarm Counter (Burst)"},
  {w: 1, h: 1, widget:"alarm_backFlowDetection", title: "Alarm Counter (Back Flow)"},
  {w: 1, h: 1, widget:"alarm_batteryLow", title: "Alarm Counter (Battery Low)"},
  {w: 1, h: 1, widget:"alarm_faultySensor", title: "Alarm Counter (Faulty Sensor)"},
  {w: 1, h: 1, widget:"alarm_wireCutDetection", title: "Alarm Counter (Wire Cut)"},
  {w: 1, h: 1, widget:"alarm_tiltDetection", title: "Alarm Counter (Tilt)"},
  {w: 1, h: 1, widget:"alarm_magnetTamper", title: "Alarm Counter (Magnet Tamper)"},
  {w: 1, h: 1, widget:"alarm_nonRealtimeBackFlowDetection", title: "Alarm Counter (Non-Realtime Backflow)"},
  {w: 1, h: 1, widget:"alarm_rebootDetection", title: "Alarm Counter (Reboot)"},
  {w: 3, h: 1, widget:"graph_anomaly_offline", title: "Anomaly Graph (Offline)"},
  {w: 3, h: 1, widget:"graph_anomaly_alarm", title: "Anomaly Graph (Alarm)"},
  {w: 3, h: 1, widget:"graph_anomaly_reading", title: "Anomaly Graph (Reading)"},
  {w: 3, h: 1, widget:"latest_anomaly", title: "Latest Anomaly"},
  {w: 3, h: 1, widget:"graph_connectivity_registered", title: "Connectivity Graph (Registered)"},
  {w: 3, h: 1, widget:"graph_connectivity_connected", title: "Connectivity Graph (Connected)"},
  {w: 3, h: 1, widget:"graph_connectivity_unregistered", title: "Connectivity Graph (Un-Registered)"},
]