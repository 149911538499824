import React, { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import MDButton from "components/MDBase/MDButton";
import { postChangePassword } from "util/APIHelper";
import "./animation.css";

function ChangePasswordSection({ email, handleSB }) {
  const [currentPassword, setCurrentPassword] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [triggerAnimation, setTriggerAnimation] = useState(false);
  const [passRequirement, setPassRequirement] = useState({
    eight_chars: false,
    special_char: false,
    uppercase: false,
    lowercase: false,
    numeric: false
  });
  const [passMatch, setPassMatch] = useState(true);

  const handleCurrentPasswordChange = (event) => setCurrentPassword(event.target.value);
  const handleNewPasswordChange = (event) => {
    setNewPassword(event.target.value);
    errorCheck(event.target.value, confirmPassword);
  };
  const handleConfirmPasswordChange = (event) => {
    setConfirmPassword(event.target.value);
    errorCheck(newPassword, event.target.value);
  };

  const errorCheck = (newPass, confirmPass) => {
    setPassMatch(newPass === confirmPass);
    const reqs = { ...passRequirement };
    reqs.eight_chars = newPass.length >= 8;
    reqs.special_char = /[!@#--$&*~_]/.test(newPass);
    reqs.uppercase = /[A-Z]/.test(newPass);
    reqs.lowercase = /[a-z]/.test(newPass);
    reqs.numeric = /\d/.test(newPass);
    setPassRequirement(reqs);
  };

  const passwordRequirements = [
    "Min 8 characters",
    "Min one special character (! @ # $ & * ~ - _)",
    "Min one uppercase letter [A-Z]",
    "Min one lowercase letter [a-z]",
    "Min one numeric number [0-9]"
  ];

  const renderPasswordRequirements = passwordRequirements.map((item, index) => {
    const itemClass = triggerAnimation ? "alert-animation" : "";
    let color = 'text';
    if (index === 0) { color = passRequirement.eight_chars ? 'success' : 'error'; }
    else if (index === 1) { color = passRequirement.special_char ? 'success' : 'error'; }
    else if (index === 2) { color = passRequirement.uppercase ? 'success' : 'error'; }
    else if (index === 3) { color = passRequirement.lowercase ? 'success' : 'error'; }
    else if (index === 4) { color = passRequirement.numeric ? 'success' : 'error'; }
    const itemKey = `element-${index}`;
    return (
      <MDBox key={itemKey} component="li" color={color} fontSize="1.25rem" lineHeight={1} className={itemClass}>
        <MDTypography variant="button" color={color} fontWeight="regular" verticalAlign="middle">
          {item}
        </MDTypography>
      </MDBox>
    );
  });

  const handleSubmit = async () => {
    try {
      if (passMatch && !(Object.values(passRequirement).includes(false))) {
        const response = await postChangePassword(email, currentPassword, newPassword);
        handleSB({
          color: response.success ? "success" : "error",
          icon: response.success ? "check" : "warning",
          title: response.success ? "Success" : "Error",
          content: response.success ? response.content : response.message
        });
      } else {
        const content = [];
        if (!passMatch) { content.push("Confirm password do not match."); }
        if (Object.values(passRequirement).includes(false)) {
          content.push("Please follow the password requirements.");
          setTriggerAnimation(true);
          setTimeout(() => setTriggerAnimation(false), 600);
        }
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: content.join("\n")
        });
      }
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <MDBox sx={{ padding: "1.5rem", minHeight: "578px" }}>
      <MDBox mb={3}>
        <MDTypography variant="h4">Change password</MDTypography>
      </MDBox>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <MDInput
            fullWidth
            label="Current Password"
            inputProps={{ type: "password", autoComplete: "" }}
            onChange={handleCurrentPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MDInput
            error={Object.values(passRequirement).includes(false)}
            fullWidth
            label="New Password"
            inputProps={{ type: "password", autoComplete: "" }}
            onChange={handleNewPasswordChange}
          />
        </Grid>
        <Grid item xs={12}>
          <MDInput
            error={!passMatch}
            fullWidth
            label="Confirm New Password"
            inputProps={{ type: "password", autoComplete: "" }}
            onChange={handleConfirmPasswordChange}
          />
        </Grid>
      </Grid>
      <MDBox mt={4} mb={1}>
        <MDTypography variant="h5">Password requirements</MDTypography>
      </MDBox>
      <MDBox mb={1}>
        <MDTypography variant="body2" color="text">
          Please follow this guide for a strong password
        </MDTypography>
      </MDBox>
      <MDBox display="flex" justifyContent="space-between" alignItems="flex-end" flexWrap="wrap">
        <MDBox component="ul" m={1} pl={3.25} mb={1}>
          {renderPasswordRequirements}
        </MDBox>
        <MDBox ml="auto">
          <MDButton onClick={handleSubmit} variant="gradient" color={(
            passMatch && !(Object.values(passRequirement).includes(false))
          )?"success":"error"} size="small">
            update password
          </MDButton>
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

ChangePasswordSection.propTypes = {
  email: PropTypes.string.isRequired,
  handleSB: PropTypes.func.isRequired
};

export default ChangePasswordSection;
