import { createContext, useContext, useMemo, useReducer } from "react";
import PropTypes from "prop-types";
const Dashboard = createContext();
Dashboard.displayName = "Dashboard";

function reducer(state, action) {
  switch (action.type) {
    case "EDIT": {
      return { ...state, edit: action.value };
    }
    case "LAYOUT": {
      return { ...state, layout: action.value };
    }
    case "RESET": {
      return { ...state, reset: action.value };
    }
    default: {
      throw new Error(`Unhandled action type: ${action.type}`);
    }
  }
}

function DashboardControllerProvider({ children }) {
  const initialState = {
    layout: [
      {w:6, h:4, x:3, y:0, i:"map"},
      {w:1, h:1, x:1, y:3, i:"alarm_leakageDetection"},
      {w:1, h:1, x:2, y:3, i:"alarm_noFlowDetection"},
      {w:1, h:1, x:0, y:3, i:"alarm_burstDetection"},
      {w:3, h:1, x:9, y:0, i:"alarm_backFlowDetection"},
      {w:1, h:1, x:11, y:2, i:"alarm_batteryLow"},
      {w:1, h:1, x:10, y:3, i:"alarm_faultySensor"},
      {w:1, h:1, x:9, y:3, i:"alarm_wireCutDetection"},
      {w:1, h:1, x:11, y:3, i:"alarm_tiltDetection"},
      {w:3, h:1, x:9, y:1, i:"alarm_magnetTamper"},
      {w:2, h:1, x:9, y:2, i:"alarm_rebootDetection"},
      {w:3, h:1, x:0, y:1, i:"graph_anomaly_alarm"},
      {w:3, h:1, x:0, y:2, i:"latest_anomaly"},
      {w:3, h:1, x:0, y:0, i:"graph_connectivity_connected"}
    ],
    edit: false,
    reset: ""
  };

  const [controller, dispatch] = useReducer(reducer, initialState);
  const value = useMemo(() => [controller, dispatch], [controller, dispatch]);
  return <Dashboard.Provider value={value}>{children}</Dashboard.Provider>;
}

function useDashboardController() {
  const context = useContext(Dashboard);

  if (!context) {
    throw new Error(
      "useDashboardController should be used inside the DashboardControllerProvider."
    );
  }

  return context;
}

DashboardControllerProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

const setDashboardEdit = (dispatch, value) => dispatch({ type: "EDIT", value });
const setDashboardLayout = (dispatch, value) => dispatch({ type: "LAYOUT", value });
const setReset = (dispatch, value) => dispatch({ type: "RESET", value });


export {
  DashboardControllerProvider,
  useDashboardController,
  setDashboardEdit,
  setDashboardLayout,
  setReset
};
