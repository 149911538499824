import React, { useEffect, useState } from "react";
import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import { getUsers, postUserPreference } from "util/APIHelper";
import { columnDefs, userTableProcess } from "layouts/users/tableUtil";
import Icon from "@mui/material/Icon";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import AddUserModal from "components/Modals/AddUserModal";
import UserModal from "components/Modals/UserModal";
import WebSocketService from "model/WebSocketService";
import { useMaterialUIController } from "context/md";
import MRTable from "components/MaterialReactTable";
import useCooldown from "util/hooks/useCooldown";

function Users() {
  const [users, setUsers] = useState([])
  const [loading, setLoading] = useState(false);
  const [wsLoading, setWsLoading] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [controller] = useMaterialUIController();
  const { user } = controller;

  const [columnState, setColumnState] = useState(() => {
    try {
      const savedState = JSON.parse(sessionStorage.getItem("user_column_state"));
      return savedState || {};
    } catch (e) {
      return {};
    }
  });

  const columnStateCallback = async (item) => {
    const state = JSON.stringify(item);
    sessionStorage.setItem("user_column_state", state);
    setColumnState(item);
    if (user) {
      await postUserPreference(user.email,[
        {
          type: "user_column_state",
          value: state
        }
      ])
    }
  }

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "UserTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, [columnState]);

  const handleOpenAddUserModal = () => setAddUserModal(true);
  const handleCloseAddUserModal = () => {
    getData()
    setAddUserModal(false)
  };

  useEffect(() => {
    getData()
  }, []);

  const getData = async (ws=false) => {
    if (ws) {
      setWsLoading(true);
    } else {
      setLoading(true)
    }
    try {
      const response = await getUsers()
      setUsers(userTableProcess(response.content));

    } catch (e) {
      console.error(e)
    }
    if (ws) {
      setWsLoading(false);
    } else {
      setLoading(false);
    }
  }
  const throttledGetData = useCooldown(()=>{
    getData(true)
  }, 1000)

  const [selectedUserData, setSelectedUserData] = useState({});
  const [userModal, setUserModal] = useState(false);
  const handleOpenUserModal = () => setUserModal(true);
  const handleCloseUserModal = () => {
    getData(true);
    setUserModal(false)
  };

  const handleRowClick = (row) => {
    setSelectedUserData(row.original)
    handleOpenUserModal()
  }

  return (
    <DashboardLayout>
      <DashboardNavbar hideSites/>
      <MRTable
        columns={columnDefs}
        data={users}
        isLoading={loading}
        isRefetching={wsLoading}
        onRefresh={getData}
        onRowClick={handleRowClick}
        onColumnStateChanged={columnStateCallback}
        initialColumnOrder={columnState.order}
        initialColumnPinning={columnState.pinning}
        initialColumnVisibility={columnState.visibility}
        customTopRightToolbar={()=>(
          <MDBox sx={{margin:"0 0.5rem 1rem 0"}} >
            <MDButton variant="outlined" color="info" onClick={handleOpenAddUserModal}>
              <Icon>add</Icon>&nbsp; Add User
            </MDButton>
          </MDBox>
        )}
      />
      <AddUserModal handleClose={handleCloseAddUserModal} open={addUserModal}/>
      <UserModal handleClose={handleCloseUserModal} open={userModal} data={selectedUserData} />
    </DashboardLayout>
  );
}

export default Users;
