class Alarm {
  constructor(data) {
    if (data) {
      this.leakageDetection = data.leakageDetection
      this.noFlowDetection = data.noFlowDetection
      this.burstDetection = data.burstDetection
      this.backFlowDetection = data.backFlowDetection || data.backflowDetection
      this.batteryLow = data.batteryLow
      this.faultySensor = data.faultySensor
      this.wireCutDetection = data.wireCutDetection
      this.tiltDetection = data.tiltDetection
      this.magnetTamper = data.magnetTamper
      this.nonRealtimeBackFlowDetection = data.nonRealtimeBackFlowDetection || data.nonRealtimeBackflowDetection
      this.rebootDetection = data.rebootDetection
    }
  }

  getNumberOfActiveAlarms() {
    return Object.values(this).filter(value => value === true).length;
  }
  getActiveAlarms() {
    return Object.keys(this).filter(key => this[key] === true);
  }

  getTotalSeverityLevel() {
    const severityWeights = {
      leakageDetection: 3,
      noFlowDetection: 2,
      burstDetection: 4,
      backFlowDetection: 2,
      batteryLow: 1,
      faultySensor: 5,
      wireCutDetection: 3,
      tiltDetection: 2,
      magnetTamper: 4,
      nonRealtimeBackFlowDetection: 1,
      rebootDetection: 2,
      // Add or adjust weights as needed
    };

    const activeAlarms = this.getActiveAlarms();
    let totalSeverity = 0;

    activeAlarms.forEach(alarm => {
      totalSeverity += severityWeights[alarm] || 0;
    });

    return totalSeverity;
  }
}

export default Alarm