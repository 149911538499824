import PropTypes from "prop-types";
import Icon from "@mui/material/Icon";
import Collapse from "@mui/material/Collapse";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";

function ContentCollapse({ title, open, children, ...rest }) {

  return (
    <MDBox
      borderRadius="lg"
      sx={{
        border: ({ borders: { borderWidth, borderColor } }) => `${borderWidth[1]} solid ${borderColor}`,
      }}
    >
      <MDBox
        {...rest}
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        p={2}
        sx={{ cursor: "pointer", backgroundColor:"rgba(0,0,0,0.05)"}}
      >
        <MDTypography variant="h6" color={open ? "dark" : "text"} sx={{ userSelect: "none" }}>
          {title}
        </MDTypography>
        <MDBox color="text">
          <Icon sx={{ fontWeight: "bold" }} fontSize="small">
            {open ? "remove" : "add"}
          </Icon>
        </MDBox>
      </MDBox>
      <Collapse timeout={400} in={open}>
        <>
          <Divider sx={{margin:"0 0 1rem 0"}}/>
          {children}
        </>
      </Collapse>
    </MDBox>
  );
}

ContentCollapse.propTypes = {
  title: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
};

export default ContentCollapse;
