/* eslint-disable react/prop-types */
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import AnomalyMessageCell from "components/TableCells/AnomalyTable/AnomalyMessageCell";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";
import React from "react";

export const columnDefs = [
  {
    header: "Timestamp", accessorKey: "timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.createdUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.createdUtc, rowB.original.item.createdUtc),
  },
  { header: "Anomaly Function", accessorKey: "anomaly_function", },
  { header: "Anomaly Message", accessorKey: "anomaly_message", size: 1000,
    Cell: ({ _, row }) => <AnomalyMessageCell data={row.original} />, },
]

export const detailsAnomalyTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      anomaly_function: singular.anomalyFunction,
      anomaly_message: singular.anomalyMessage,
      timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.createdUtc))),
    }
  });
}