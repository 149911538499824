import Card from "@mui/material/Card";
import MDBox from "components/MDBase/MDBox";
import Icon from "@mui/material/Icon";
import MDTypography from "components/MDBase/MDTypography";
import {Skeleton} from "@mui/material";
import {useEffect, useState} from "react";
import PropTypes from "prop-types";
import Alarm from "model/Alarm";
import Grid from "@mui/material/Grid";
import AlarmIcon from "components/AlarmIcon";
import {v4 as uuidv4} from "uuid"

function AlarmCard({alarm, loading}) {
  const [activeAlarms, setActiveAlarms] = useState([]);

  useEffect(() => {
    const alarmObj = new Alarm(alarm)
    setActiveAlarms(alarmObj.getActiveAlarms())

  }, [alarm]);

  return (
    <Card >
      <MDBox display="flex" justifyContent="space-between" py={1} px={2}>
        <MDBox
          variant="gradient"
          bgColor="info"
          color="white"
          coloredShadow="info"
          borderRadius="xl"
          display="flex"
          justifyContent="center"
          alignItems="center"
          width="4rem"
          height="4rem"
          sx={{minWidth:"4rem"}}
          mt={-3}
        >
          <Icon fontSize="medium" color="inherit">
            warning
          </Icon>
        </MDBox>
        <MDBox textAlign="right" lineHeight={1.25}>
          <MDTypography variant="button" fontWeight="light" color="text">
            Alarms
          </MDTypography>

          <Grid container>
            <Grid item xs={12}>
              {
                (loading)?
                  <Skeleton sx={{marginTop: "0.4rem"}} variant="rounded" height={"1.5rem"}/>:
                  <MDTypography variant="h5">
                    {
                      (activeAlarms.length>0)?
                        `${activeAlarms.length} Active Alarm(s)`:
                        'No Active Alarms'
                    }
                  </MDTypography>
              }
            </Grid>
            {
              (activeAlarms.length>0)?(
                <Grid item xs={12} py={1}>
                  <MDBox>
                    <Grid container spacing={2}  style={{ display: 'flex', justifyContent: 'flex-end' }}>
                      {
                        activeAlarms.map((alarm)=>{
                          return (
                            <Grid key={uuidv4()} item>
                              <AlarmIcon alarm={alarm} triggered/>
                            </Grid>
                          )
                        })
                      }
                    </Grid>
                  </MDBox>
                </Grid>
              ):null
            }
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

AlarmCard.defaultProps = {
  loading: false
}

AlarmCard.propTypes = {
  alarm: PropTypes.object.isRequired,
  loading: PropTypes.bool
}
export default AlarmCard;