import PropTypes from "prop-types";
import {Card} from "@mui/material";
import DefaultLineChart from "components/MDComponents/Charts/LineCharts/DefaultLineChart";
import { useEffect, useMemo, useState } from "react";
import { formatDate } from "util/UtilHelper";

const OverallKPIGraph = ({endpointIntervals, data, isLoading}) => {
  const [chartData, setChartData] = useState({});
  useEffect(() => {
    if (!isLoading && data.length>0) {
      processData();
    }
  }, [isLoading]);

  const processData = () => {
    const totalExpected = (endpointIntervals.reduce((acc, obj) => acc + obj.expectedLogCount, 0));
    const processedData = {
      labels: data.map((item) =>
        formatDate(
          localStorage.getItem("datetime-format"),
          new Date(item.date),
          true
        )
      ),
      datasets: [
        {
          label: "KPI (%)",
          color: "info",
          data: data.map((item) => {
            return (((item.data.reduce((acc, obj) => acc + obj.count, 0))/totalExpected) * 100).toFixed(2);
          })
        },
      ]
    }

    setChartData(processedData)
  }

  return (
    <Card>
      {
        useMemo(()=>(
          <DefaultLineChart
            icon={{
              color:"info",
              component: "show_chart"
            }}
            title="Overall KPI Graph"
            height="20rem"
            smooth
            description=" "
            chart={chartData}
            loading={isLoading}
          />
        ), [chartData])
      }
    </Card>
  );
}

OverallKPIGraph.defaultProps = {
  isLoading: false
}

OverallKPIGraph.propTypes = {
  endpointIntervals: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
  isLoading: PropTypes.bool

}
export default OverallKPIGraph;