import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid2";
import { downloadChart, formatDate } from "util/UtilHelper";
import React, { useRef, useState } from "react";
import { Line } from "react-chartjs-2";
import MDBox from "components/MDBase/MDBox";
import 'chartjs-adapter-date-fns';
import { batteryBaseData, batteryOptions } from "./configs";
import { CircularProgress } from "@mui/material";
import MenuItem from "@mui/material/MenuItem";
import Menu from "@mui/material/Menu";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import MDTypography from "components/MDBase/MDTypography";
import { getMeterBatteryAnalysis } from "util/APIHelper";
import MiniStatisticsCard from "components/MDComponents/Cards/StatisticsCards/MiniStatisticsCard";
import { BatteryCharging20 } from "@mui/icons-material";
import { downloadExcel } from "util/ExcelUtil";

const BatteryGraph = React.forwardRef(({ endpoint, startDate, endDate }, ref) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(batteryBaseData);
  const [levelMin, setLevelMin] = useState(null);
  const [levelMax, setLevelMax] = useState(null);
  const [voltMin, setVoltMin] = useState(null);
  const [voltMax, setVoltMax] = useState(null);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeterBatteryAnalysis(
        formatDate("YYYY-MM-DD",startDate,true),
        formatDate("YYYY-MM-DD",endDate,true),
        endpoint
      );
      if (response.success) {
        const batteryLevelData = response.content["battery_level"].map((item) => {
          return {x:item["timestampUtc"], y: item["batteryLevel"]}
        });

        const batteryVoltageData = response.content["voltage"].map((item) => {
          return {x:item["timestampUtc"], y: item["batteryVoltage"]}
        });

        let currentData = structuredClone(batteryBaseData);
        currentData.datasets[0].data = batteryLevelData;
        currentData.datasets[1].data = batteryVoltageData;
        setData(currentData);

        if (batteryLevelData.length>0) {
          setLevelMax(Math.max(...batteryLevelData.map(obj => obj.y)));
          setLevelMin(Math.min(...batteryLevelData.map(obj => obj.y)));
        } else {
          setLevelMax(null);
          setLevelMin(null);
        }

        if (batteryVoltageData.length>0) {
          setVoltMax(Math.max(...batteryVoltageData.map(obj => obj.y)));
          setVoltMin(Math.min(...batteryVoltageData.map(obj => obj.y)));
        } else {
          setVoltMax(null);
          setVoltMin(null);
        }
      } else {
        setData(batteryBaseData);
        setLevelMax(null);
        setLevelMin(null);
        setVoltMax(null);
        setVoltMin(null);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const options = batteryOptions;
  const chartRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => { setAnchorEl(event.currentTarget); };
  const handleMenuClose = () => { setAnchorEl(null); };

  const getLevelColor = (value) => {
    if (value === null) {
      return "secondary"
    }
    if (value>=90) {
      return "success"
    } else if (value>=50) {
      return "info"
    } else if (value>=30) {
      return "warning"
    } else {
      return "error"
    }
  }

  const getVoltColor = (value) => {
    if (value === null) {
      return "secondary"
    }
    if (value>=3.6) {
      return "success"
    } else if (value>=3.0) {
      return "info"
    } else {
      return "warning"
    }
  }

  React.useImperativeHandle(ref, () => ({
    getData
  }));
  return (
    <Grid container spacing={1}>
      <Grid size={{xs:12, md: 9}} order={{ xs: 2, md: 1 }}>
        <Card>
          <MDBox display="flex" px={2} pt={2}>
            <MDBox mr="auto">
              <MDTypography variant="h6">Battery Level & Voltage</MDTypography>
              <MDBox mb={2}>
                <MDTypography component="div" variant="button" color="text">
                  Analysis Graph
                </MDTypography>
              </MDBox>
            </MDBox>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={getData}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem", marginRight:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">refresh</Icon>
            </IconButton>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={handleMenuOpen}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => { downloadChart(chartRef, 'Battery Level & Voltage graph'); handleMenuClose(); }}>Download Image</MenuItem>
              <MenuItem
                onClick={() => {
                  const { datasets } = data
                  const contents = {
                    "Battery Level": datasets[0].data.map((item)=>({"Timestamp":item.x, "Battery Level": item.y})),
                    "Battery Voltage": datasets[1].data.map((item)=>({"Timestamp":item.x, "Battery Voltage": item.y})),
                  }
                  downloadExcel("Battery Level & Voltage data", contents);
                }}
              >
                Download Excel
              </MenuItem>
            </Menu>
          </MDBox>
          {
            isLoading ? (
              <MDBox height="30rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <CircularProgress color="info" />
              </MDBox>
            ):(
              <MDBox height="30rem" px={1} pb={1}>
                <Line ref={chartRef} data={data} options={options} redraw />
              </MDBox>
            )
          }
        </Card>
      </Grid>
      <Grid size={{xs:12, md: 3}} order={{ xs: 1, md: 2 }}>
        <MDBox>
          <Grid container spacing={1}>
            <Grid size={{ xs:6, md:12}} order={{ sm: 1, md: 1 }}>
              <MiniStatisticsCard
                title={{ text: "Maximum Level" }}
                count={`${(isLoading || !levelMax)?'':levelMax} %`}
                icon={{ color: getLevelColor(levelMax), component: "battery_full" }}
                direction="left"
              />
            </Grid>
            <Grid size={{ xs:6, md:12}} order={{ sm: 3, md: 2 }}>
              <MiniStatisticsCard
                title={{ text: "Minimum Level" }}
                count={`${(isLoading || !levelMin)?'':levelMin} %`}
                icon={{ color: getLevelColor(levelMin), component: "battery_1_bar" }}
                direction="left"
              />
            </Grid>
            <Grid size={{ xs:6, md:12}} order={{ sm: 2, md: 3 }}>
              <MiniStatisticsCard
                title={{ text: "Maximum Voltage" }}
                count={`${(isLoading || !voltMax)?'':voltMax} V`}
                icon={{ color: getVoltColor(voltMax), component: "battery_charging_full" }}
                direction="left"
              />
            </Grid>
            <Grid size={{ xs:6, md:12}} order={{ sm: 4, md: 4 }}>
              <MiniStatisticsCard
                title={{ text: "Minimum Voltage" }}
                count={`${(isLoading || !voltMax)?'':voltMin} V`}
                icon={{ color: getVoltColor(voltMin), component: (<BatteryCharging20 color="inherit" fontSize="medium"/>) }}
                direction="left"
              />
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  )
});

BatteryGraph.propTypes = {
  endpoint: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default BatteryGraph;