import { useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import { Bar } from "react-chartjs-2";
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend,
} from "chart.js";
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import colors from "assets/theme/base/colors";
import palette from "assets/colors/index";
const {material} = palette;
import { CircularProgress, Skeleton } from "@mui/material";
import { downloadChart, isValidHex } from "util/UtilHelper";
import configs from "components/MDComponents/Charts/BarCharts/VerticalBarChart/configs";
import {downloadExcel} from "util/ExcelUtil";


ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

function VerticalBarChart({ icon, title, description, height, chart, loading, stacked, extraAction, legend }) {
  const [anchorEl, setAnchorEl] = useState(null);

  const chartDatasets = chart.datasets
    ? chart.datasets.map((dataset) => {
      let color;

      if (dataset.color.split('-')[0] === 'material') {
        color = material[dataset.color.split('-')[1]]
          ? material[dataset.color.split('-')[1]][500]
          : colors.dark.main
      } else if (isValidHex(dataset.color)) {
        color = dataset.color
      } else {
        color = colors[dataset.color]
          ? colors[dataset.color || "dark"].main
          : colors.dark.main;
      }
      return {
        ...dataset,
        weight: 5,
        borderWidth: 0,
        borderRadius: 4,
        backgroundColor: color,
        fill: false,
        maxBarThickness: 35,
      };
    })
    : [];

  const { data, options } = useMemo(() => {
    const { data, options } = configs(chart.labels || [], chartDatasets);
    if (stacked) {
      if (!options.scales) options.scales = {};
      options.scales.y.stacked = true;
      options.scales.x.stacked = true;

      options.plugins.legend = { position: 'bottom' }
    } else {
      options.plugins.legend = { position: (legend)?'bottom':false }
    }

    options.scales.x.ticks.callback = function (value, index) {
      return (chart.x_axis) ? chart.x_axis[index] : chart.labels[index];
    }
    return { data, options };
  }, [chart, chartDatasets, stacked]);

  const chartRef = useRef(null);

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "dark"}
              variant="gradient"
              coloredShadow={icon.color || "dark"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0} mr="auto">
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              {
                loading ? <Skeleton /> :
                  <MDTypography component="div" variant="button" color="text">
                    {description}
                  </MDTypography>
              }
            </MDBox>
          </MDBox>
          {extraAction}
          <IconButton
            disabled={loading}
            color="info"
            onClick={handleMenuOpen}
            sx={{ margin: "-0.5rem -0.5rem 0 0" }}
          >
            <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { downloadChart(chartRef, title); handleMenuClose(); }}>Download Image</MenuItem>
            <MenuItem
              onClick={() => {
                const {labels, datasets} = chart;

                const items = []
                for (let i=0; i<labels.length; i++) {
                  const item = {}
                  item["Datetime"] = labels[i]

                  for (let x=0; x<datasets.length; x++) {
                    item[datasets[x].label] = datasets[x].data[i]
                  }
                  items.push(item)
                }
                const contents = {
                  "Data": items
                }

                downloadExcel(title, contents)
              }}
            >
              Download Excel
            </MenuItem>
          </Menu>
        </MDBox>
      ) : null}
      {
        loading ? (
          <MDBox height={height} sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}>
            <CircularProgress color="info" />
          </MDBox>
        ) : (
          <MDBox height={height}>
            <Bar ref={chartRef} data={data} options={options} redraw />
          </MDBox>
        )
      }
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

VerticalBarChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  loading: false,
  stacked: false,
  extraAction: <></>,
  legend: false
};

VerticalBarChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.array).isRequired,
  loading: PropTypes.bool,
  stacked: PropTypes.bool,
  extraAction: PropTypes.node,
  legend: PropTypes.bool
};

export default VerticalBarChart;
