import GridLayout from "react-grid-layout";
import MDBox from "components/MDBase/MDBox";
import React, { useEffect, useRef, useState } from "react";
import {
  setDashboardEdit,
  setDashboardLayout,
  useDashboardController,
} from "context/dashboard";
import PropTypes from "prop-types";
import DashboardAlarmTallyWidget from "layouts/dashboard/components/Widgets/AlarmTally";
import DashboardAnomalyGraphWidget from "layouts/dashboard/components/Widgets/AnomalyGraph";
import DashboardLatestAnomalyWidget from "layouts/dashboard/components/Widgets/LatestAnomaly";
import DashboardConnectivityGraphWidget from "layouts/dashboard/components/Widgets/ConnectivityGraph";
import MDButton from "components/MDBase/MDButton";
import Icon from "@mui/material/Icon";
import {postDashboardLayout} from "util/APIHelper";
import {useMaterialUIController} from "context/md";
import MapWidget from "layouts/dashboard/components/Widgets/MapWidget";
import {v4 as uuidv4} from "uuid"

function DashboardRegion({ data }) {
  const [dashboardController, dashboardDispatch] = useDashboardController();
  const { layout, edit} = dashboardController;
  const [controller,] = useMaterialUIController();
  const {user, miniSidenav} = controller;
  const [controlledLayout, setControlledLayout] = useState(layout);

  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(0);
  const [toRefresh, setToRefresh] = useState(uuidv4());

  useEffect(() => {
    const updateWidth = () => {
      if (containerRef.current) {
        setContainerWidth(containerRef.current.offsetWidth);
      }
    };
    updateWidth();
    window.addEventListener("resize", updateWidth);
    return () => {
      window.removeEventListener("resize", updateWidth);
      setDashboardEdit(dashboardDispatch, false);
    };
  }, []);

  const onLayoutChange = (layout) => {
    setControlledLayout(layout)
    setDashboardLayout(dashboardDispatch, layout);
    setToRefresh(uuidv4())
    if (edit) {
      postDashboardLayout(user.email, JSON.stringify(layout))
    }
  };

  const onResize = (layout, oldLayoutItem, layoutItem, placeholder) => {
    // Setting min/max width/height functions
    const minH = (lI, pI, val) => { if (lI.h < val && pI.h < val) { lI.h = val; pI.h = val } }
    const maxH = (lI, pI, val) => { if (lI.h > val && pI.h > val) { lI.h = val; pI.h = val } }
    const minW = (lI, pI, val) => { if (lI.w < val && pI.w < val) { lI.w = val; pI.w = val } }
    const maxW = (lI, pI, val) => { if (lI.w > val && pI.w > val) { lI.w = val; pI.w = val } }

    const id = layoutItem.i.split("#")[0];

    if (
      id.split("_")[0] === "graph" &&
      (id.split("_")[1] === "anomaly" || id.split("_")[1] === "connectivity")
    ) {
      maxH(layoutItem, placeholder, 1)
      minW(layoutItem, placeholder, 2)
    }
    if (id === "map") {
      minH(layoutItem, placeholder, 3)
      minW(layoutItem, placeholder, 5)
    }
  };

  const processWidgets = (_) => {
    const id = _.split("#")[0];
    if (id.split("_")[0] === "alarm") {
      return <DashboardAlarmTallyWidget data={data.alarm} alarm={id.split("_")[1]} />;
    } else if (id === "map") {
      return <MapWidget toRefresh={toRefresh} />;
    } else if (id.split("_")[0] === "graph") {
      if (id.split("_")[1] === "anomaly") {
        return <DashboardAnomalyGraphWidget type={id.split("_")[2]} />;
      }
      if (id.split("_")[1] === "connectivity") {
        return <DashboardConnectivityGraphWidget data={data} type={id.split("_")[2]} />;
      }
      // check for position 1 for type of graph [consumption?]
      // check for position 2 for variation of graph [simple/complex]
    } else {
      switch (id) {
        case "latest_anomaly":
          return <DashboardLatestAnomalyWidget />;
      }
    }
  };

  // This use effect is for delay between context update and actual widget update
  // due to wrong widget sizing when adding widget
  useEffect(() => {
    setTimeout(()=>{setControlledLayout(layout)}, 150)
  }, [dashboardController.layout.length, dashboardController.reset]);

  const [actionButtonHovered, setActionButtonHovered] = useState(false);
  const renderHoverableBox = (item) => {

    return (
      <MDBox
        key={item.i}
      >
        {edit && (
          <MDButton
            onMouseEnter={() => setActionButtonHovered(true)}
            onMouseLeave={() => setActionButtonHovered(false)}
            onClick={()=>{
              setActionButtonHovered(false)
              setControlledLayout(prev => prev.filter(obj => obj.i !== item.i))
            }}
            color="secondary"
            size="small"
            sx={{
              position: "absolute",
              top: "-0.5rem",
              right: "0.25rem",
              zIndex: 1,
            }}
            iconOnly
            circular
          >
            <Icon>close</Icon>
          </MDButton>
        )}
        {processWidgets(item.i)}
      </MDBox>
    );
  };

  return (
    <MDBox ref={containerRef}>
      <GridLayout
        style={{ marginLeft: "-10px" }}
        className="layout"
        layout={controlledLayout}
        cols={12}
        rowHeight={150}
        width={containerWidth + ((miniSidenav)?154:0)}
        onLayoutChange={onLayoutChange}
        onResize={onResize}
        isDraggable={edit && !actionButtonHovered}
        isResizable={edit}
      >
        {controlledLayout.map((item) => renderHoverableBox(item))}
      </GridLayout>
    </MDBox>
  );
}

DashboardRegion.defaultProps = {
  data: {},
};

DashboardRegion.propTypes = {
  data: PropTypes.object,
};

export default DashboardRegion;
