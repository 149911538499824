import Card from "@mui/material/Card";
import {CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDBox from "components/MDBase/MDBox";
import SelectedMeterList from "components/Lists/SelectedMeterList";
import React, {useState} from "react";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
import Tooltip from "@mui/material/Tooltip";
import SuccessRateCell from "components/TableCells/FirmwareTable/SuccessRateCell";
import MDButton from "components/MDBase/MDButton";
import {postFirmwareRequest} from "util/APIHelper";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";

function QueueFirmwareModal({open, handleClose, selectedMeters, selectedFirmwareFile=null}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;
  const [isLoading, setLoading] = useState(false);

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 450,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  const iconsStyle = ({palette: { text }}) => ({ color: text.main });
  const textStyle = (lines) => ({
      marginLeft: '0.5rem',
      display: '-webkit-box',
      WebkitBoxOrient: 'vertical',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'pre-line',
      WebkitLineClamp: lines,
  });

  const handleSubmit = async () => {
    setLoading(true);
    try {
      const response = await postFirmwareRequest(selectedMeters.map((i)=> i.endpoint).join(","), selectedFirmwareFile.id);

      if (response.success) {
        handleSB({
          color: "success",
          icon: "check",
          title: "Success",
          content: "Firmware successfully queued"
        });
      } else {
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: "Error queueing firmware"
        });
      }
    } catch (e) {
      console.error()
    }
    setLoading(false);
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  const firmwareDetails = () => (
    <Grid container spacing={2}>
      <Grid item xs={12}>
        <MDBox display="flex" alignItems="center" >
          <Icon sx={iconsStyle}>insert_drive_file</Icon>
          <Tooltip title="File Name" placement="right">
            <MDTypography sx={textStyle(2)} variant="body2" verticalAlign="bottom">{selectedFirmwareFile?.fileName}</MDTypography>
          </Tooltip>
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <MDBox display="flex" alignItems="center" >
          <Icon sx={iconsStyle}>description</Icon>
          <Tooltip title="Description" placement="right">
            <MDTypography sx={textStyle(5)} variant="body2" verticalAlign="bottom">{selectedFirmwareFile?.description}</MDTypography>
          </Tooltip>
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <MDBox display="flex" alignItems="center" >
          <Icon sx={iconsStyle}>trending_up</Icon>
          <Tooltip title="Success Rate" placement="right">
            <MDBox>
              <SuccessRateCell
                sx={{marginLeft: '0.5rem'}}
                success_rate={
                  ((selectedFirmwareFile?.updateSuccessCount/((selectedFirmwareFile?.updateCount===0)?1:selectedFirmwareFile?.updateCount))*100).toFixed(2)
                }
              />
            </MDBox>
          </Tooltip>
        </MDBox>
      </Grid>
      <Grid item xs={12}>
        <MDButton sx={{width:"9rem"}} disabled={isLoading} size="medium" fullWidth variant="gradient" color="info" onClick={handleSubmit}>
          {
            isLoading?(
              <CircularProgress color="white" size="1rem"/>
            ):"Queue"
          }
        </MDButton>
      </Grid>
    </Grid>
  );

  return (
    <Modal
      open={open}
      onClose={handleClose}
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} variant="h4">
          Queue Firmware
        </MDTypography>
        <Divider />
        <MDBox display="flex">
          <MDBox sx={{maxWidth: '150px', minWidth: '100px'}}>
            {firmwareDetails()}
          </MDBox>

          <Divider sx={{height:'auto'}} orientation="vertical" flexItem/>

          <MDBox sx={{ width:"100%", overflowX: 'hidden'}}>
            <SelectedMeterList selectedMeters={selectedMeters} />
          </MDBox>
        </MDBox>
      </Card>
    </Modal>
  )
}

QueueFirmwareModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  selectedMeters: PropTypes.array.isRequired,
  selectedFirmwareFile: PropTypes.object
}
export default QueueFirmwareModal