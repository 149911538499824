import React, {useEffect, useState} from "react";
import "react-grid-layout/css/styles.css";
import "react-resizable/css/styles.css";

import DashboardLayout from "components/MDComponents/LayoutContainers/DashboardLayout";
import DashboardNavbar from "components/MDComponents/Navbars/DashboardNavbar";
import Footer from "components/MDComponents/Footer";
import EditRegion from "layouts/dashboard/components/EditRegion";
import DashboardRegion from "layouts/dashboard/components/DashboardRegion";
import {getDashboardAlarmCounter, getDashboardLayout, getEndpointWidgetData, postDashboardLayout} from "util/APIHelper";
import {useMaterialUIController} from "context/md";
import {parseObjectList} from "util/UtilHelper";
import {setDashboardLayout, setReset, useDashboardController} from "context/dashboard";
import WebSocketService from "model/WebSocketService";
import {defaultDashboardLayout} from "util/LookupUtil";
import {v4 as uuidv4} from "uuid";
import useCooldown from "util/hooks/useCooldown";
import { useSignalEffect } from "@preact/signals-react";
import { selectedSites } from "signals/group_signals";

function Dashboard() {
  const [, dashboardDispatch] = useDashboardController();
  const [controller,] = useMaterialUIController();
  const {user} = controller;
  const [dashboardData, setDashboardData] = useState({});

  useEffect(() => {
    const handleSignal = (data) => {
      try {
        const message = JSON.parse(data.data)
        if (message.type === "DeviceTable" && message.action === "Refresh") {
          throttledGetData()
        }
      } catch (e) {}
    };

    WebSocketService.addMessageListener(handleSignal);
    return () => {
      WebSocketService.removeMessageListener(handleSignal);
    };
  }, []);

  useEffect(() => {
    if (user) {
      dashboardLayout()
      getDashboardData()
    }
  }, [user]);

  const dashboardLayout = async () => {
    try {
      // try storing the layout in db based on email
      const response = await getDashboardLayout(user.email);
      const parsedLayout = parseObjectList(response.content)

      if (parsedLayout) {
        setDashboardLayout(dashboardDispatch, (parsedLayout.length>0)?parsedLayout:defaultDashboardLayout);
        setReset(dashboardDispatch, uuidv4());
      } else {
        await postDashboardLayout(user.email, JSON.stringify(defaultDashboardLayout));
        setDashboardLayout(dashboardDispatch, defaultDashboardLayout);
        setReset(dashboardDispatch, uuidv4());
      }

    } catch (e) {
      console.error(e)
    }
  }

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      throttledGetData()
    }
  });

  const getDashboardData = async () => {
    try {
      let endpointWidgetData = {
        connected: 0,
        registered: 0,
        unregistered: 0,
      };

      let dashboardAlarmCounter = {
        nonRealtimeBackFlowDetection: 0,
        rebootDetection: 0,
        leakageDetection: 0,
        noFlowDetection: 0,
        burstDetection: 0,
        backFlowDetection: 0,
        batteryLow: 0,
        faultySensor: 0,
        wireCutDetection: 0,
        tiltDetection: 0,
        magnetTamper: 0,
        online: 0,
        offline: 0,
      };

      if (selectedSites.value.length > 0) {
        const sites = selectedSites.value.map(e => e.id);
        const endpointResponse = await getEndpointWidgetData(sites.join(","), sites.includes(-1));
        endpointWidgetData = {
          connected: endpointResponse.content.registered,
          registered: endpointResponse.content.uploaded,
          unregistered: endpointResponse.content.stray
        }


        const alarmCounterResponse = await getDashboardAlarmCounter(sites.join(","), sites.includes(-1));
        dashboardAlarmCounter = {
          nonRealtimeBackFlowDetection: alarmCounterResponse.content.non_realtime_backflow_detection,
          rebootDetection: alarmCounterResponse.content.reboot_detection,
          leakageDetection: alarmCounterResponse.content.leakage_detection,
          noFlowDetection: alarmCounterResponse.content.no_flow_detection,
          burstDetection: alarmCounterResponse.content.burst_detection,
          backFlowDetection: alarmCounterResponse.content.back_flow_detection,
          batteryLow: alarmCounterResponse.content.battery_low,
          faultySensor: alarmCounterResponse.content.faulty_sensor,
          wireCutDetection: alarmCounterResponse.content.wire_cut_detection,
          tiltDetection: alarmCounterResponse.content.tilt_detection,
          magnetTamper: alarmCounterResponse.content.magnet_tamper,
          online: alarmCounterResponse.content.online,
          offline: alarmCounterResponse.content.offline
        }
      }

      setDashboardData(prevState => ({
        ...prevState,
        ...endpointWidgetData
      }));
      setDashboardData(prevState => ({
        ...prevState,
        alarm: dashboardAlarmCounter
      }));

    } catch (e) {
      console.error(e)
    }
    if (!isMounted) {
      setIsMounted(true)
    }
  }
  const throttledGetData = useCooldown(getDashboardData, 1000)

  useEffect(() => {
    getDashboardData();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar title="Dashboard" />
      <EditRegion />
      <DashboardRegion data={dashboardData} />
      <Footer />
    </DashboardLayout>
  );
}

export default Dashboard;
