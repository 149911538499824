import Card from "@mui/material/Card";
import PropTypes from "prop-types";
import React, { useRef, useState } from "react";
import Grid from "@mui/material/Grid2";
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import { downloadChart, formatDate } from "util/UtilHelper";
import { downloadExcel } from "util/ExcelUtil";
import { CircularProgress } from "@mui/material";
import { Line } from "react-chartjs-2";
import { signalBaseData, signalOptions } from "./configs";
import { getMeterSignalAnalysis } from "util/APIHelper";
import Divider from "@mui/material/Divider";

const SignalGraph = React.forwardRef(({ endpoint, startDate, endDate }, ref) => {
  const [isLoading, setLoading] = useState(false);
  const [data, setData] = useState(signalBaseData);
  const [rssi, setRssi] = useState(null);
  const [rsrp, setRsrp] = useState(null);
  const [rsrq, setRsrq] = useState(null);
  const [sinr, setSinr] = useState(null);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getMeterSignalAnalysis(
        formatDate("YYYY-MM-DD",startDate,true),
        formatDate("YYYY-MM-DD",endDate,true),
        endpoint
      );

      if (response.success) {
        const rssiData = [];
        const rsrqData = [];
        const rsrpData = [];
        const sinrData = [];

        response.content.forEach((item) => {
          const time = item["timestamp_utc"];

          rssiData.push({x:time, y:item["rssi"]});
          rsrqData.push({x:time, y:item["rsrq"]});
          rsrpData.push({x:time, y:item["rsrp"]});
          sinrData.push({x:time, y:item["sinr"]});
        });

        let currentData = structuredClone(signalBaseData);
        currentData.datasets[0].data = rsrpData
        currentData.datasets[1].data = rssiData
        currentData.datasets[2].data = rsrqData
        currentData.datasets[3].data = sinrData
        setData(currentData)

        if (response.content.length>0) {
          setRsrq({
            min:Math.min(...rsrqData.map(obj => obj.y)),
            max:Math.max(...rsrqData.map(obj => obj.y)),
            avg: (rsrqData.reduce((acc, obj) => acc + obj.y, 0) / rsrqData.length).toFixed(2)
          });
          setRsrp({
            min:Math.min(...rsrpData.map(obj => obj.y)),
            max:Math.max(...rsrpData.map(obj => obj.y)),
            avg: (rsrpData.reduce((acc, obj) => acc + obj.y, 0) / rsrpData.length).toFixed(2)
          });
          setRssi({
            min:Math.min(...rssiData.map(obj => obj.y)),
            max:Math.max(...rssiData.map(obj => obj.y)),
            avg: (rssiData.reduce((acc, obj) => acc + obj.y, 0) / rssiData.length).toFixed(2)
          });
          setSinr({
            min:Math.min(...sinrData.map(obj => obj.y)),
            max:Math.max(...sinrData.map(obj => obj.y)),
            avg: (sinrData.reduce((acc, obj) => acc + obj.y, 0) / sinrData.length).toFixed(2)
          });
        } else {
          setRssi(null);
          setRsrq(null);
          setRsrp(null);
          setSinr(null);
        }
      } else {
        setData(signalOptions);
        setRssi(null);
        setRsrq(null);
        setRsrp(null);
        setSinr(null);
      }
    } catch (e) {
      console.error(e);
    }
    setLoading(false);
  }

  const options = signalOptions
  const chartRef = useRef(null);

  const [anchorEl, setAnchorEl] = useState(null);
  const handleMenuOpen = (event) => { setAnchorEl(event.currentTarget); }
  const handleMenuClose = () => { setAnchorEl(null); }

  const widgetCard = (title, obj, color, icon, unit) => (
    <Card>
      <MDBox p={3} sx={{display:"flex", flexDirection:"row"}}>
        <MDBox>
          <MDBox
            display="grid"
            justifyContent="center"
            alignItems="center"
            bgColor={color}
            color="white"
            width="3rem"
            height="3rem"
            shadow="md"
            borderRadius="lg"
            variant="gradient"
          >
            <Icon fontSize="default">{icon}</Icon>
          </MDBox>
          <MDBox mt={2.625}>
            <MDTypography variant="h5" fontWeight="medium" textTransform="capitalize">
              {title}
            </MDTypography>
          </MDBox>
        </MDBox>
        <Divider orientation="vertical" sx={{height:'auto'}} />
        <MDBox ml={1} sx={{justifyContent:"space-between", display:"flex", flexDirection:"column"}}>
          <MDTypography variant="h6" fontWeight="bold" textTransform="capitalize">
            Max
          </MDTypography>
          <MDTypography variant="caption" fontWeight="light">
            {(obj!==null)?obj.max:''} {unit}
          </MDTypography>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Min
          </MDTypography>
          <MDTypography variant="caption" fontWeight="light">
            {(obj!==null)?obj.min:''} {unit}
          </MDTypography>
        </MDBox>
        <MDBox sx={{marginLeft:"auto", marginRight:"1rem"}}>
          <MDTypography variant="h6" fontWeight="medium" textTransform="capitalize">
            Avg
          </MDTypography>
          <MDTypography variant="caption" fontWeight="light">
            {(obj!==null)?obj.avg:''} {unit}
          </MDTypography>
        </MDBox>
      </MDBox>
    </Card>
  )

  React.useImperativeHandle(ref, () => ({
    getData
  }));
  return (
    <Grid container spacing={1}>
      <Grid size={{xs:12, lg:8.5, xxl:9.5,}} order={{ xs: 2, lg: 1 }}>
        <Card>
          <MDBox display="flex" px={2} pt={2}>
            <MDBox mr="auto">
              <MDTypography variant="h6">Signal Status</MDTypography>
              <MDBox mb={2}>
                <MDTypography component="div" variant="button" color="text">
                  Analysis Graph
                </MDTypography>
              </MDBox>
            </MDBox>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={getData}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem", marginRight:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">refresh</Icon>
            </IconButton>
            <IconButton
              disabled={isLoading}
              color="info"
              onClick={handleMenuOpen}
              sx={{width:"2rem", height:"2rem", marginTop:"0.5rem"}}
            >
              <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
            </IconButton>
            <Menu
              anchorEl={anchorEl}
              open={Boolean(anchorEl)}
              onClose={handleMenuClose}
            >
              <MenuItem onClick={() => { downloadChart(chartRef, 'Signal Status graph'); handleMenuClose(); }}>Download Image</MenuItem>
              <MenuItem
                onClick={() => {
                  const { datasets } = data
                  const records = [];

                  for (let i=0; i<datasets[0].data.length; i++) {
                    records.push({
                      "Timestamp": datasets[0].data[i].x,
                      "RSRP (dBm)": datasets[0].data[i].y,
                      "RSSI (dBm)": datasets[1].data[i].y,
                      "RSRQ (dB)": datasets[2].data[i].y,
                      "sinr (dB)": datasets[3].data[i].y,
                    })
                  }
                  const contents = {
                    "Data": records
                  }
                  downloadExcel("Signal Status data", contents);
                }}
              >
                Download Excel
              </MenuItem>
            </Menu>
          </MDBox>
          {
            isLoading ? (
              <MDBox height="32.7rem" sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', }}>
                <CircularProgress color="info" />
              </MDBox>
            ):(
              <MDBox height="32.7rem" px={1} pb={1}>
                <Line ref={chartRef} data={data} options={options} redraw />
              </MDBox>
            )
          }
        </Card>
      </Grid>
      <Grid size={{xs:12, lg:3.5, xxl:2.5}} order={{ xs: 1, lg: 2 }}>
        <MDBox>
          <Grid container spacing={1}>
            <Grid size={{ xs:6, lg:12}}>
              {widgetCard("RSRP", rsrp, "warning", "network_cell", "dBm")}
            </Grid>
            <Grid size={{ xs:6, lg:12}}>
              {widgetCard("RSSI", rssi, "info", "signal_cellular_alt", "dBm")}
            </Grid>
            <Grid size={{ xs:6, lg:12}}>
              {widgetCard("RSRQ", rsrq, "success", "signal_cellular_4_bar", "dB")}
            </Grid>
            <Grid size={{ xs:6, lg:12}}>
              {widgetCard("SINR", sinr, "error", "wifi_tethering", "dB")}
            </Grid>
          </Grid>
        </MDBox>
      </Grid>
    </Grid>
  )
});

SignalGraph.propTypes = {
  endpoint: PropTypes.string.isRequired,
  startDate: PropTypes.instanceOf(Date).isRequired,
  endDate: PropTypes.instanceOf(Date).isRequired
}

export default SignalGraph;