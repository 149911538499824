/* eslint-disable react/prop-types */
import React from "react";
import LinkCell from "components/TableCells/General/LinkCell";

export const columnDefs = [
  { header: "Group Name", accessorKey: "group_name", },
  { header: "Total Sites", accessorKey: "total_sites",
    Cell: ({ cell, row }) => (
      <LinkCell href={`/sites?group=${row.original.id}`} text={cell.getValue().toString()}/>
    )
  },
  { header: "Total Meters",  accessorKey: "total_meters",
    Cell: ({ cell, row }) => (
      <LinkCell href={`/meter?group=${row.original.id}`} text={cell.getValue().toString()}/>
    )
  },
]

export const groupTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      id: singular.id,
      group_name: singular.description,
      total_sites: singular.siteCount,
      total_meters: singular.meterCount,
      noAction: (singular.id === 0 || singular.description === "Unassigned")
    }
  });
}