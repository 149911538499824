import React, { useEffect, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import { MapContainer, TileLayer, Marker, Tooltip, useMap } from 'react-leaflet';
import MarkerClusterGroup from 'react-leaflet-cluster';
import L from 'leaflet';
import 'leaflet/dist/leaflet.css';
import { useMaterialUIController } from "context/md";
import { getEndpointWidgetData, getUserPreference, postUserPreference } from "util/APIHelper";
import { Card } from "@mui/material";
import InfoWindow from "layouts/dashboard/components/Widgets/MapWidget/InfoWindow";
import PropTypes from "prop-types";
import { setSBContent, setSBOpen, useSBController } from "context/snackbar";
import Icon from "@mui/material/Icon";
import { selectedSites } from "signals/group_signals";
import { useSignalEffect } from "@preact/signals-react";

// Define icon for markers
const normalIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-blue.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const hasAlarmIcon = new L.Icon({
  iconUrl: 'https://raw.githubusercontent.com/pointhi/leaflet-color-markers/master/img/marker-icon-2x-red.png',
  iconSize: [25, 41],
  iconAnchor: [12, 41],
  popupAnchor: [1, -34],
  shadowSize: [41, 41],
});

const createClusterCustomIcon = (cluster) => {
  const childMarkers = cluster.getAllChildMarkers();
  let clusterColor = 'small'; // Default color is green

  const hasSpecialMarker = childMarkers.some(marker => marker.options && marker.options.icon.options.iconUrl.endsWith('red.png'));

  if (hasSpecialMarker) {
    clusterColor = 'large'; // Turn red if condition is met
  }
  return new L.DivIcon({
    html: `<div>
              <span>${cluster.getChildCount()}</span>
           </div>`,
    className: `leaflet-marker-icon marker-cluster marker-cluster-${clusterColor} leaflet-zoom-animated leaflet-interactive`,
    iconSize: L.point(40, 40),
  });
}

const MapWidget = ({ toRefresh }) => {
  const [key, setKey] = useState(0);
  const [markerData, setMarkerData] = useState([]);
  const [controller] = useMaterialUIController();
  const { user } = controller;
  const [center, setCenter] = useState([2.969524, 101.611410]);
  const [zoom, setZoom] = useState(15);
  const [darkMode, setDarkMode] = useState(false);
  const [openPopupId, setOpenPopupId] = useState(null);

  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const [lightUrl, setLightUrl] = useState(() => {
    try {
      const savedState = sessionStorage.getItem("dashboard_light_url");
      return savedState || "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    } catch (e) {
      return "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png";
    }
  });
  const [darkUrl, setDarkUrl] = useState(() => {
    try {
      const savedState = sessionStorage.getItem("dashboard_dark_url");
      return savedState || "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png";
    } catch (e) {
      return "https://{s}.basemaps.cartocdn.com/dark_all/{z}/{x}/{y}.png";
    }
  });

  const mapRef = React.useRef(null);

  useEffect(() => {
    try {
      setDarkMode(sessionStorage.getItem("dashboard_map_theme") === "dark");
      const dashboard_map_coordinate = JSON.parse(sessionStorage.getItem("dashboard_map_coordinate"));
      if (dashboard_map_coordinate) {
        setZoom(parseInt(dashboard_map_coordinate.zoom)??15)
        setCenter([parseFloat(dashboard_map_coordinate.lat)??center[0], parseFloat(dashboard_map_coordinate.lng)??center[1]])
      }
    } catch (e) {}
  }, []);

  useEffect(() => {
    if (user) {
      getData();
    }
    window.addEventListener('resize', handleRefresh);
    return () => {
      window.removeEventListener('resize', handleRefresh);
    };
  }, [user]);

  useEffect(() => {
    handleRefresh();
  }, [toRefresh]);

  const handleRefresh = () => {
    setKey((prevKey) => prevKey + 1);
  };

  const [isMounted, setIsMounted] = useState(false);
  useSignalEffect(()=>{
    selectedSites.value
    if (isMounted) {
      getData()
    }
  });

  const getData = async () => {
    try {
      const pref = {
        center: center,
        zoom: zoom,
        theme: darkMode
      }

      if (selectedSites.value.length > 0) {
        const sites = selectedSites.value.map(e=>e.id);
        const response = await getEndpointWidgetData(sites.join(","), sites.includes(-1));
        if (response.success) {
          setMarkerData(response.content.uploaded.map((item) => ({
            coordinates: [item.latitude, item.longitude],
            info: item.endpoint,
            hasAlarm: item.hasAlarm,
            item: item
          })));

          if (response.content.uploaded.length > 0) {
            const lastItem = response.content.uploaded[response.content.uploaded.length - 1];
            pref.center = [lastItem.latitude, lastItem.longitude];
          }
        }
      } else {
        setMarkerData([])
      }

      const userPref = await getUserPreference(user.email, "dashboard_map_coordinate,dashboard_map_theme,dashboard_dark_url,dashboard_light_url");
      if (userPref.success) {
        if (userPref.content["dashboard_map_theme"]) {
          pref.theme=(userPref.content["dashboard_map_theme"] === "dark")
          sessionStorage.setItem("dashboard_map_theme", userPref.content["dashboard_map_theme"])
        }

        if (userPref.content["dashboard_map_coordinate"]) {
          try {
            const setting = JSON.parse(userPref.content["dashboard_map_coordinate"])
            pref.zoom = parseInt(setting.zoom)??15;
            pref.center = [parseFloat(setting.lat)??center[0], parseFloat(setting.lng)??center[1]]
            sessionStorage.setItem(
              "dashboard_map_coordinate",
              JSON.stringify(
                {
                  zoom:pref.zoom,
                  lat:parseFloat(setting.lat)??center[0],
                  lng:parseFloat(setting.lng)??center[1]
                }
              )
            );

          } catch (e) {}
        }

        if (userPref.content["dashboard_light_url"]) {
          setLightUrl(userPref.content["dashboard_light_url"])
        }
        if (userPref.content["dashboard_dark_url"]) {
          setDarkUrl(userPref.content["dashboard_dark_url"])
        }

      }

      setCenter(pref.center);
      setZoom(pref.zoom);
      setDarkMode(pref.theme);
    } catch (e) {
      console.error(e);
    }
    handleRefresh();
    if (!isMounted) {
      setIsMounted(true)
    }
  };

  const LocationButton = () => {
    const map = useMap();

    const handleClick = async () => {
      const preference = {
        zoom: map.getZoom(),
        lat: map.getCenter().lat,
        lng: map.getCenter().lng,
      };

      try {
        const response = await postUserPreference(user.email, [
          {
            type: "dashboard_map_coordinate",
            value: JSON.stringify(preference),
          },
        ]);

        if (response.success) {
          handleSB({
            color: "success",
            icon: "check",
            title: "Success",
            content: "Location Saved",
          });
          sessionStorage.setItem("dashboard_map_coordinate", JSON.stringify(preference))
        } else {
          handleSB({
            color: "error",
            icon: "warning",
            title: "Error",
            content: "Error Saving Location",
          });
        }
      } catch (e) {
        handleSB({
          color: "error",
          icon: "warning",
          title: "Error",
          content: "Error Saving Location",
        });
      }
    };

    useEffect(() => {
      const customControl = L.control({ position: "topleft" });

      customControl.onAdd = function () {
        const div = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );

        // Create the button element
        const button = document.createElement("button");
        button.style.backgroundColor = "white";
        button.style.border = "none";
        button.style.cursor = "pointer";
        button.style.padding = "3px";
        button.style.display = "flex";
        button.style.alignItems = "center";
        button.style.justifyContent = "center";
        button.style.color = "black";

        // Create the icon element
        button.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <Icon fontSize="small">save</Icon>
        );

        // Create the text element
        const text = document.createElement("span");
        text.textContent = "Save Location";
        text.style.margin = "0 4px 0 4px"

        button.onmouseover = () => {
          button.appendChild(text);
        };

        button.onmouseout = () => {
          if (button.contains(text)) {
            button.removeChild(text);
          }
        };

        // Attach the handleClick to the entire button
        button.onclick = handleClick;

        div.appendChild(button);
        return div;
      };

      customControl.addTo(map);

      return () => {
        customControl.remove();
      };
    }, [map]);

    return null;
  };

  const ThemeButton = () => {
    const map = useMap();

    const handleClick = async () => {
      const theme = !darkMode
      setDarkMode(theme)
      const response = await postUserPreference(user.email, [
        {
          type: "dashboard_map_theme",
          value: theme?"dark":"light",
        },
      ]);
      if (response.success) {
        sessionStorage.setItem("dashboard_map_theme", theme?"dark":"light")
      }
    };

    useEffect(() => {
      const customControl = L.control({ position: "topleft" });

      customControl.onAdd = function () {
        const div = L.DomUtil.create(
          "div",
          "leaflet-bar leaflet-control leaflet-control-custom"
        );

        // Create the button element
        const button = document.createElement("button");
        button.style.backgroundColor = "white";
        button.style.border = "none";
        button.style.cursor = "pointer";
        button.style.padding = "3px";
        button.style.display = "flex";
        button.style.alignItems = "center";
        button.style.justifyContent = "center";
        button.style.color = "black";

        // Create the icon element
        button.innerHTML = ReactDOMServer.renderToStaticMarkup(
          <Icon fontSize="small">{darkMode ? "dark_mode" : "light_mode"}</Icon>
        );

        // Attach the handleClick to the entire button
        button.onclick = handleClick;

        div.appendChild(button);
        return div;
      };

      customControl.addTo(map);

      return () => {
        customControl.remove();
      };
    }, [map]);

    return null;
  };


  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  return (
    <Card style={{ height: "100%", width: "100%", padding: "16px", borderRadius: "12px", position: "relative", overflow: "hidden" }}>
      <MapContainer ref={mapRef} key={key} center={center} zoom={zoom} style={{ height: '100%', width: '100%' }}>
        <TileLayer
          url={
            (darkMode)?
              darkUrl:
              lightUrl
          }
          attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
        />
        <MarkerClusterGroup maxClusterRadius={20} iconCreateFunction={createClusterCustomIcon}>
          {markerData.map((marker) => (
            <Marker
              key={marker.info}
              position={marker.coordinates}
              icon={marker.hasAlarm?hasAlarmIcon:normalIcon}
              eventHandlers={{
                popupopen: () => setOpenPopupId(marker.info),
                popupclose: () => setOpenPopupId(null)
              }}
            >
              <Tooltip>{marker.item.meterSerialNumber ?? marker.info}</Tooltip>
              <InfoWindow content={marker} isOpen={openPopupId === marker.info} />
            </Marker>
          ))}
        </MarkerClusterGroup>
        <ThemeButton />
        <LocationButton />
      </MapContainer>
    </Card>
  );
};

MapWidget.propTypes = {
  toRefresh: PropTypes.string.isRequired
};

export default MapWidget;
