import Card from "@mui/material/Card";
import {CircularProgress, Modal} from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import {useEffect, useState} from "react";
import { deleteGroup, getAllGroup, postCreateGroup, putUpdateGroup } from "util/APIHelper";
import Grid from "@mui/material/Grid";
import MDInput from "components/MDBase/MDInput";
import {setSBContent, setSBOpen, useSBController} from "context/snackbar";
import { useMaterialUIController } from "context/md";
import { DeleteOutline } from "@mui/icons-material";
import Tooltip from "@mui/material/Tooltip";
import IconButton from "@mui/material/IconButton";

function GroupAddEditModal({open, handleClose, isEdit=false, groupInfo=null}){
  const [sb_controller, sb_dispatch] = useSBController();
  const {content} = sb_controller;

  const [description, setDescription] = useState("");

  const [loading, setLoading] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const [groupNames, setGroupNames] = useState([]);
  const [isError, setIsError] = useState(false);

  const [controller] = useMaterialUIController();
  const { user } = controller;

  const handleDescriptionChange = (event) => {
    setDescription(event.target.value);
    if (event.target.value === "" || groupNames.includes(event.target.value.toLowerCase())) {
      setIsError(true)
    } else {
      setIsError(false)
    }
  };

  useEffect(() => {
    if (open) {
      getData();
      if (isEdit) {
        setDescription(groupInfo.name);
      }
    } else {
      setIsError(false);
      setDescription("");
    }
  }, [open]);

  const getData = async () => {
    setLoading(true);
    try {
      const response = await getAllGroup();
      if (response.success) {
        setGroupNames(response.content.map(item => item.description.toLowerCase()));
        setLoading(false);
      }
    } catch (e) {
      console.error(e)
    }
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  const submit = async () => {
    setSubmitting(true);
    try {
      if (!isError) {
        let response;
        if (isEdit) {
          response = await putUpdateGroup(groupInfo.id, description);
        } else {
          response = await postCreateGroup(description);
        }
        if (response.success) {
          handleSB({
            color:"success",
            icon: "check",
            title: "Success",
            content: response.content
          })
        } else {
          handleSB({
            color:"error",
            icon: "warning",
            title: "Error",
            content: response.message
          })
        }
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: handleHelperText()
        })
      }
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false);
  }

  const handleDelete = async () => {
    setSubmitting(true);
    try {
      let response;
      response = await deleteGroup(groupInfo.id);
      if (response.success) {
        handleSB({
          color:"success",
          icon: "check",
          title: "Success",
          content: response.content
        })
      } else {
        handleSB({
          color:"error",
          icon: "warning",
          title: "Error",
          content: response.message
        })
      }
      handleClose()
    } catch (e) {
      console.error(e)
    }
    setSubmitting(false);
  }

  const handleSB = (newState) => {
    setSBContent(sb_dispatch, {
      ...content,
      ...newState
    })
    setSBOpen(sb_dispatch,true)
  }

  const handleHelperText = () => {
    if (isError) {
      if (description === "") {
        return "Group name cannot be empty";
      } else {
        return `${description} already exists`;
      }
    } else {
      return null
    }
  }
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          {
            (isEdit)?"Edit Group":"Add Group"
          }
        </MDTypography>
        <Divider />
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <MDInput value={description} type="text" label="Group Name" onChange={handleDescriptionChange} fullWidth error={isError} helperText={handleHelperText()} />
          </Grid>
        </Grid>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          {
            (user?.isSuperuserAndAbove() && isEdit)?(
              <Tooltip title="Delete Group">
                <IconButton sx={{marginRight:"auto"}} onClick={handleDelete}>
                  {
                    submitting ? <CircularProgress color="error" size="1rem"/> : <DeleteOutline color="error"/>
                  }
                </IconButton>
              </Tooltip>
            ):null
          }
          <MDButton variant="outlined" color="secondary" sx={{marginRight:"1rem"}} onClick={handleClose}>Cancel</MDButton>
          <MDButton sx={{width:"9rem"}} disabled={submitting || loading} variant="gradient" color="info" onClick={submit}>
            {
              submitting?(
                <CircularProgress color="white" size="1rem"/>
              ):(
                (isEdit)?"Update Group":"Create Group"
              )
            }
          </MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

GroupAddEditModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  isEdit: PropTypes.bool,
  groupInfo: PropTypes.object
}
export default GroupAddEditModal