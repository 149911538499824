// @mui material components
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";

export default styled(Box)(({ theme }) => {
  const { palette, borders } = theme;

  const { inputBorderColor, transparent } = palette;
  const { borderRadius, borderWidth } = borders;

  return {
    display: "flex",
    alignItems: "center",
    border: `${borderWidth[1]} solid ${inputBorderColor} !important`,
    borderRadius: borderRadius.md,

    "&.dropzone": {
      background: `${transparent.main} !important`,
      minHeight: '1rem !important',
      height: '7rem'
    },
  };
});
