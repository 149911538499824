/* eslint-disable react/prop-types */
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import AnomalyMessageCell from "components/TableCells/AnomalyTable/AnomalyMessageCell";
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import React from "react";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";

export const columnDefs = [
  {
    header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row }) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
      />
  },
  { header: "Anomaly Function",  accessorKey: "anomaly_function" },
  { header: "Anomaly Message", accessorKey: "anomaly_message",
    Cell: ({ _, row }) => <AnomalyMessageCell data={row.original} />
  },
  {
    header: "Timestamp", accessorKey: "timestamp",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.createdUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.createdUtc, rowB.original.item.createdUtc),
  }
]

export const anomalyTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      endpoint: singular.endpoint,
      meter_sn: (singular.meterSerialNumber) ? singular.meterSerialNumber : singular.endpoint,
      anomaly_function: singular.anomalyFunction,
      anomaly_message: singular.anomalyMessage,
      timestamp: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.createdUtc))),
    }
  });
}