import PropTypes from "prop-types";
import {Card, Skeleton} from "@mui/material";
import React, {useState} from "react";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import MDTypography from "components/MDBase/MDTypography";
import Grid from "@mui/material/Grid";
import Divider from "@mui/material/Divider";
import Tooltip from "@mui/material/Tooltip";
import MDBox from "components/MDBase/MDBox";

function InfoCard({meterData, loading}) {
  const [tabValue, setTabValue] = useState(0);

  const toggleComponent = () => (
    <Tabs value={tabValue} onChange={handleSetTabValue}>
      <Tab label="Info" />
      <Tab label="Advanced"/>
    </Tabs>
  )
  const handleSetTabValue = (event, newValue) => setTabValue(newValue);

  const handleTabContent = () => {
    switch (tabValue) {
      case 1: return advancedPanel();
      default: return infoPanel();
    }
  }

  const rowContent = (content) => (
    loading ? <Skeleton height="1.6rem" /> : <MDTypography variant="body2">{content}</MDTypography>
  );

  const rowTitle = (title) => (<MDTypography color="info" variant="body2">{title}</MDTypography>);

  const coordinate = (latitude, longitude) => (
    loading ?
      <Skeleton height="1.6rem" /> :
      <MDBox display="flex">
        <Tooltip title="View on Google Map">
          <MDTypography
            color="info"
            variant="body2"
            fontWeight="bold"
            sx={{cursor: "pointer"}}
            onClick={()=>{
              window.open(
                `https://www.google.com/maps/search/?api=1&query=${latitude},${longitude}`,
                '_blank'
              )
            }}
          >
            {(latitude)?`${latitude}, ${longitude}`:''}
          </MDTypography>
        </Tooltip>
      </MDBox>
  );

  const row = (title, content, last = false, custom = null) => {
    return (
      <>
        <Grid item xs={6}>{rowTitle(title)}</Grid>
        <Grid item xs={6}>{custom?custom:rowContent(content)}</Grid>
        {
          !last && (<Grid item xs={12}><Divider sx={{margin:"0"}}/></Grid>)
        }
      </>
    )
  }

  const infoPanel = () => {
    return (
      <Card sx={{padding:"0.5rem 1rem 1rem 1rem"}}>
        <Grid container spacing={0.5}>
          {row("Endpoint", meterData.endpoint)}
          {row("Model Number", "")}
          {row("Meter Serial Number", meterData.meter_sn)}
          {row("MIU Serial Number", meterData.miu_sn)}
          {row("Group", meterData.group_name)}
          {row("Site", meterData.site_name)}
          {row("Serial Number", "")}
          {row("Firmware Version", "")}
          {row("Coordinates", '', true, coordinate(meterData.latitude, meterData.longitude))}
        </Grid>
      </Card>
    )
  }

  const advancedPanel = () => {
    return (
      <Card sx={{padding:"0.5rem 1rem 1rem 1rem"}}>
        <Grid container spacing={0.5}>
          {row("Log Start Mask", meterData.log_start_mask)}
          {row("Log Interval", meterData.log_interval)}
          {row("Transmit Start Mask", meterData.transmit_start_mask)}
          {row("Transmit Interval", meterData.transmit_interval)}
          {row("Custom 1", meterData.custom_1)}
          {row("Custom 2", meterData.custom_2)}
          {row("Custom 3", meterData.custom_3)}
          {row("Custom 4", meterData.custom_4)}
          {row("Custom 5", meterData.custom_5, true)}
        </Grid>
      </Card>
    )
  }

  return (
    <Card>
      {toggleComponent()}
      {handleTabContent()}
    </Card>
  )
}

InfoCard.propTypes = {
  meterData: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired
}
export default InfoCard