import {useMemo, useRef, useState} from "react";

// porp-types is a library for typechecking of props
import PropTypes from "prop-types";

// react-chartjs-2 components
import { Pie } from "react-chartjs-2";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";

// @mui material components
import Card from "@mui/material/Card";
import Icon from "@mui/material/Icon";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// PieChart configurations
import configs from "components/MDComponents/Charts/PieChart/configs";
import {CircularProgress, Skeleton} from "@mui/material";
import {downloadChart} from "util/UtilHelper";
import MDButton from "components/MDBase/MDButton";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import {downloadExcel} from "util/ExcelUtil";

ChartJS.register(ArcElement, Tooltip, Legend);

function PieChart({ icon, title, description, height, chart, loading }) {
  const [anchorEl, setAnchorEl] = useState(null);
  const { data, options } = configs(chart.labels || [], chart.datasets || {});

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const chartRef = useRef(null);
  const renderChart = (
    <MDBox py={2} pr={2} pl={icon.component ? 1 : 2}>
      {title || description ? (
        <MDBox display="flex" px={description ? 1 : 0} pt={description ? 1 : 0}>
          {icon.component && (
            <MDBox
              width="4rem"
              height="4rem"
              bgColor={icon.color || "dark"}
              variant="gradient"
              coloredShadow={icon.color || "dark"}
              borderRadius="xl"
              display="flex"
              justifyContent="center"
              alignItems="center"
              color="white"
              mt={-5}
              mr={2}
            >
              <Icon fontSize="medium">{icon.component}</Icon>
            </MDBox>
          )}
          <MDBox mt={icon.component ? -2 : 0}>
            {title && <MDTypography variant="h6">{title}</MDTypography>}
            <MDBox mb={2}>
              {
                loading?<Skeleton />:
                  <MDTypography component="div" variant="button" color="text">
                    {description}
                  </MDTypography>
              }
            </MDBox>
          </MDBox>
          <IconButton
            disabled={loading}
            color="info"
            onClick={handleMenuOpen}
            sx={{ margin: "-0.5rem -0.5rem 0 auto" }}
          >
            <Icon color="secondary" fontSize="medium">more_vert_icon</Icon>
          </IconButton>
          <Menu
            anchorEl={anchorEl}
            open={Boolean(anchorEl)}
            onClose={handleMenuClose}
          >
            <MenuItem onClick={() => { downloadChart(chartRef, title); handleMenuClose(); }}>Download Image</MenuItem>
            <MenuItem
              onClick={() => {
                const {labels, datasets} = chart;
                const data = datasets.data;

                const items = []
                const item = {}
                for (let i=0; i<labels.length; i++) {
                  item[labels[i]] = data[i]
                }
                items.push(item)
                const contents = {
                  "Data": items
                }

                downloadExcel(title, contents)
              }}
            >
              Download Excel
            </MenuItem>
          </Menu>
        </MDBox>
      ) : null}
      {loading?(
        <MDBox height={height} sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
          <CircularProgress color="info" />
        </MDBox>
      ):(
        <MDBox height={height}>
          <Pie ref={chartRef} data={data} options={options} redraw />
        </MDBox>
      )}
    </MDBox>
  );

  return title || description ? <Card>{renderChart}</Card> : renderChart;
}

// Setting default values for the props of PieChart
PieChart.defaultProps = {
  icon: { color: "info", component: "" },
  title: "",
  description: "",
  height: "19.125rem",
  loading: false
};

// Typechecking props for the PieChart
PieChart.propTypes = {
  icon: PropTypes.shape({
    color: PropTypes.oneOf([
      "primary",
      "secondary",
      "info",
      "success",
      "warning",
      "error",
      "light",
      "dark",
    ]),
    component: PropTypes.node,
  }),
  title: PropTypes.string,
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  chart: PropTypes.objectOf(PropTypes.oneOfType([PropTypes.array, PropTypes.object])).isRequired,
  loading: PropTypes.bool
};

export default PieChart;
