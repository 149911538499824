import MDTypography from "components/MDBase/MDTypography";
import PropTypes from "prop-types";

function DownlinkStatusCell({status}) {
  let color;

  switch (status) {
    case "Success": color = "success"; break;
    case "Queued": color = "warning"; break;
    case "Partially Completed": color = "warning"; break;
    case "Fail": color = "error"; break;
    default: color="text";break;
  }

  return (
    <MDTypography color={color} variant="body">{status}</MDTypography>
  );
}

DownlinkStatusCell.propTypes = {
  status: PropTypes.string.isRequired
}

export default DownlinkStatusCell;