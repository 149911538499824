// auth.js

import {setLogin, setUser, useMaterialUIController} from "context/md";
import User from "../model/User";
import useSignIn from "react-auth-kit/hooks/useSignIn";
import useSignOut from "react-auth-kit/hooks/useSignOut";
import useIsAuthenticated from "react-auth-kit/hooks/useIsAuthenticated";
import useAuthUser from "react-auth-kit/hooks/useAuthUser";
import { resetSites } from "../signals/group_signals";

export function useAuth() {
  const [, dispatch] = useMaterialUIController();
  const signIn = useSignIn()
  const signOut = useSignOut()
  const isAuthenticated = useIsAuthenticated();
  const user = useAuthUser();

  const login = (email, token) => {
    const loggedInUser = new User()
    loggedInUser.login(email, token)

    setUser(dispatch,loggedInUser)
    setLogin(dispatch, true)

    signIn({
      auth: {
        token: token,
        type: 'Bearer',
      },
      userState: loggedInUser.toJson()
    })
    localStorage.setItem("datetime-format", loggedInUser.datetimeFormat);
  };

  const logout = () => {
    resetSites();
    localStorage.removeItem('all_sites')
    localStorage.removeItem('selected_sites')
    localStorage.removeItem('datetime-format')
    sessionStorage.clear();
    setUser(dispatch, null)
    setLogin(dispatch, null)
    signOut()
  };

  const getLoginStatus = () => {
    return localStorage.getItem("user.token") && localStorage.getItem("user.email");
  }

  const sessionLogin = () => {
    if (isAuthenticated()) {
      const loggedInUser = new User()
      loggedInUser.login(user.email, user.token)
      setUser(dispatch, loggedInUser)
    }
  }

  return {
    login,
    logout,
    getLoginStatus,
    sessionLogin
  };
}
