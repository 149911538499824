/* eslint-disable react/prop-types */

export const columnDefs = [
  { header: "Year", accessorKey: "year", },
  { header: "Month", accessorKey: "month", },
  { header: "Consumption (m³)", accessorKey: "consumption", },
]

export const monthlyConsumptionTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      year: singular.year,
      month: singular.month,
      consumption: (singular.consumption / 1000).toFixed(4),
    }
  });
}