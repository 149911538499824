/* eslint-disable react/prop-types */
import ActiveCell from "components/TableCells/General/ActiveCell";
import AccessLevelCell from "components/TableCells/UserTable/AccessLevelCell";
import React from "react";
import { basicSort, epochToTimestamp, formatDate, isBetweenInclusive } from "util/UtilHelper";
import DateRangeFilter from "components/TableCells/Filter/DateRangeFilter";

export const columnDefs = [
  { header: "Email", accessorKey: "email",
    muiEditTextFieldProps: {
      type: 'email',
      required: true,
    },
  },
  { header: "Access Level", accessorKey: "access_level",
    Cell: ({ cell }) => <AccessLevelCell access={cell.getValue()} />,
    filterVariant: "select",
    filterSelectOptions: [
      { text: "Viewer", value: "viewer" },
      { text: "Admin", value: "admin" },
      { text: "Superuser", value: "superuser" },
    ],
  },
  { header: "Date Format", accessorKey: "date_format", },
  { header: "Active", accessorKey: "active",
    Cell: ({ cell }) => <ActiveCell active={cell.getValue()} />,
    filterVariant: "select",
    filterSelectOptions: [
      { text: "Active", value: "active" },
      { text: "Inactive", value: "inactive" },
    ],
  },
  {
    header: "Created", accessorKey: "created",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.createdUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.createdUtc, rowB.original.item.createdUtc),
  },
  {
    header: "Modified", accessorKey: "modified",
    Filter: ({ header }) => <DateRangeFilter header={header} />,
    filterFn: (row, _, [start, end]) =>
      isBetweenInclusive(new Date(epochToTimestamp(row.original.item.modifiedUtc)), start, end),
    sortingFn: (rowA, rowB, _columnId) =>
      basicSort(rowA.original.item.modifiedUtc, rowB.original.item.modifiedUtc),
  },
];

export const userTableProcess = (rawData) => {
  return rawData.map((singular) => {
    return {
      item: singular,
      email: singular.email,
      access_level: singular.roleDescription,
      date_format: singular.dateFormat,
      active: (singular.active)?"active":"inactive",
      created: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.createdUtc))),
      modified: formatDate(localStorage.getItem('datetime-format'), new Date(epochToTimestamp(singular.modifiedUtc))),
    }
  });
}