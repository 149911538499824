/* eslint-disable react/prop-types */
import React from "react";
import MDTypography from "../../components/MDBase/MDTypography";
import MDBox from "../../components/MDBase/MDBox";
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";

function DataTextView({ data, onClickClose }) {

  const handleClick = () => {
    onClickClose();
  };

  console.log(data);
  if (data === null) {
    return <MDBox sx={{ width:'10px', pointerEvents: "none", backgroundColor:"red"}}></MDBox>;
  }
  return (
    <MDBox>
      <MDBox id="infoView" sx={{  display: "flex", flexDirection: "row", justifyContent:"end", alignItems: "center"}}>
        <IconButton sx={{m: 1}} disableRipple size="small" onClick={handleClick}>
          <Icon>
            close
          </Icon>
        </IconButton>
      </MDBox>
      <MDTypography variant="h6">Application Data</MDTypography>
      <ul>
        <li><MDTypography variant="body2">ID: {data.endpointId != null ? data.endpointId : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Node: {data.endpoint != null ? data.endpoint : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Parent Node ID: {data.parentEndpointId != null ? data.parentEndpointId : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Parent Node: {data.parentEndpoint != null ? data.parentEndpoint : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Signal Strength: {data.signalStrength != null ? data.signalStrength : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Timestamp: {data.timestampUtc != null ? new Date(data.timestampUtc * 1000).toLocaleString() : "N/A"}</MDTypography></li>
      </ul>

      <MDTypography variant="h6">Slot Frame Info</MDTypography>
      <ul>
        <li><MDTypography variant="body2">Slot Frame Length: {data.slotFrameInfo != null && data.slotFrameInfo.slotFrameLength != null ? data.slotFrameInfo.slotFrameLength : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Timeslot Duration (us): {data.slotFrameInfo != null && data.slotFrameInfo.timeslotDurationUs != null ? data.slotFrameInfo.timeslotDurationUs : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Channel Hopping
          Sequence: {data.slotFrameInfo != null && data.slotFrameInfo.channelHoppingSequence != null ? data.slotFrameInfo.channelHoppingSequence.join(", ") : "N/A"}</MDTypography></li>
      </ul>

      <MDTypography variant="h6">Synchronization Info</MDTypography>
      <ul>
        <li><MDTypography variant="body2">Join Priority: {data.synchronizationInfo != null && data.synchronizationInfo.joinPriority != null ? data.synchronizationInfo.joinPriority : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Synchronization
          Method: {data.synchronizationInfo != null && data.synchronizationInfo.synchronizationMethod != null ? data.synchronizationInfo.synchronizationMethod : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Slot Frame
          Management: {data.synchronizationInfo != null && data.synchronizationInfo.slotFrameManagement != null ? data.synchronizationInfo.slotFrameManagement : "N/A"}</MDTypography></li>
      </ul>

      <MDTypography variant="h6">Routing Info</MDTypography>
      <ul>
        <li><MDTypography variant="body2">Routing Protocol: {data.routingInfo != null && data.routingInfo.routingProtocol != null ? data.routingInfo.routingProtocol : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Parent Node: {data.routingInfo != null && data.routingInfo.parentNode != null ? data.routingInfo.parentNode : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Preferred Parent: {data.routingInfo != null && data.routingInfo.preferredParent != null ? data.routingInfo.preferredParent : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Route Metrics: {data.routingInfo != null && data.routingInfo.routeMetrics != null ? data.routingInfo.routeMetrics : "N/A"}</MDTypography></li>
      </ul>

      <MDTypography variant="h6">Security Info</MDTypography>
      <ul>
        <li><MDTypography variant="body2">Key Management: {data.securityInfo != null && data.securityInfo.keyManagement != null ? data.securityInfo.keyManagement : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Security Policies: {data.securityInfo != null && data.securityInfo.securityPolicies != null ? data.securityInfo.securityPolicies : "N/A"}</MDTypography></li>
      </ul>

      <MDTypography variant="h6">Node Management Info</MDTypography>
      <ul>
        <li><MDTypography variant="body2">MAC Address: {data.nodeManagementInfo != null && data.nodeManagementInfo.macAddress != null ? data.nodeManagementInfo.macAddress : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Application
          Specifics: {data.nodeManagementInfo != null && data.nodeManagementInfo.applicationSpecifics != null ? data.nodeManagementInfo.applicationSpecifics : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Coexistence
          Management: {data.nodeManagementInfo != null && data.nodeManagementInfo.coexistenceManagement != null ? data.nodeManagementInfo.coexistenceManagement : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Power Sources: {data.nodeManagementInfo != null && data.nodeManagementInfo.powerSources != null ? data.nodeManagementInfo.powerSources : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Power Saving Modes: {data.nodeManagementInfo != null && data.nodeManagementInfo.powerSavingModes != null ? data.nodeManagementInfo.powerSavingModes : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Fault Tolerance: {data.nodeManagementInfo != null && data.nodeManagementInfo.faultTolerance != null ? data.nodeManagementInfo.faultTolerance : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Network Management: {data.nodeManagementInfo != null && data.nodeManagementInfo.networkManagement != null ? data.nodeManagementInfo.networkManagement : "N/A"}</MDTypography></li>
        <li><MDTypography variant="body2">Compliance Standards: {data.nodeManagementInfo != null && data.nodeManagementInfo.complianceStandards != null ? data.nodeManagementInfo.complianceStandards : "N/A"}</MDTypography>
        </li>
        <li><MDTypography variant="body2">Monitoring and
          Debugging: {data.nodeManagementInfo != null && data.nodeManagementInfo.monitoringAndDebugging != null ? data.nodeManagementInfo.monitoringAndDebugging : "N/A"}</MDTypography></li>
      </ul>
    </MDBox>
  );
}

export default DataTextView;
