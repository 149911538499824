/* eslint-disable react/prop-types */
import MeterSerialNumberCell from "components/TableCells/General/MeterSerialNumberCell";
import MeterCoordinateCell from "../../TableCells/MeterTable/MeterCoordinateCell";
import MeterStatusCell from "../../TableCells/MeterTable/MeterStatusCell";
import MeterAlarmCell from "../../TableCells/MeterTable/MeterAlarmCell";
import MeterSignalCell from "../../TableCells/MeterTable/MeterSignalCell";

export const columnDefs = [
  { header: "Endpoint", accessorKey: "endpoint", hide: true },
  { header: "Meter S/N", accessorKey: "meter_sn",
    Cell: ({_, row}) =>
      <MeterSerialNumberCell
        meterSerialNumber={row.original.meter_sn}
        endpoint={row.original.endpoint}
        unClickable={true}
      />
  },
  { header: "MIU S/N", accessorKey: "miu_sn"},
  { header: "Registration Date", accessorKey: "registration_date", hide: true },
  { header: "Last Update", accessorKey: "last_update", hide: true },
  { header: "Coordinate", accessorKey: "coordinate", hide: true,
    Cell: ({ _, row }) =>
      <MeterCoordinateCell data={{
        latitude: row.original.latitude,
        longitude: row.original.longitude
      }} />
  },
  { header: "Status", accessorKey: "status", hide: true,
    Cell: ({ cell }) => <MeterStatusCell data={cell.getValue()} />},
  { header: "Custom 1", accessorKey: "custom_1" },
  { header: "Custom 2", accessorKey: "custom_2", hide: true },
  { header: "Custom 3", accessorKey: "custom_3", hide: true },
  { header: "Custom 4", accessorKey: "custom_4", hide: true, },
  { header: "Custom 5", accessorKey: "custom_5", hide: true, },
  { header: "Reading (m³)", accessorKey: "reading", hide: true },
  { header: "Timestamp", accessorKey: "timestamp", hide: true },
  { header: "Alarms", accessorKey: "alarms", hide: true,
    Cell: ({ _, row }) => <MeterAlarmCell data={row.original.alarmObject} />, },
  { header: "Signal", accessorKey: "signal", hide: true,
    Cell: ({ _, row }) => <MeterSignalCell data={row.original} />, },
  { header: "Temperature °C", accessorKey: "temperature", hide: true, },
  { header: "Battery Level (%)", accessorKey: "batteryLevel", hide: true, },
  { header: "Battery Voltage (V)", accessorKey: "batteryVoltage", hide: true, },
]