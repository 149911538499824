import React from 'react';
import { Pie } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import MDBox from "components/MDBase/MDBox"; // Important for newer Chart.js versions

const ChartJSLine = () => {
  const data = {
    labels: ['Sales Region A', 'Sales Region B', 'Sales Region C'],
    datasets: [
      {
        label: 'Sales Data',
        data: [300, 14, 80],
        backgroundColor: [
          'rgba(255, 99, 132, 0.2)',
          'rgba(54, 162, 235, 0.2)',
          'rgba(255, 206, 86, 0.2)'
        ],
        borderColor: [
          'rgba(255, 99, 132, 1)',
          'rgba(54, 162, 235, 1)',
          'rgba(255, 206, 86, 1)'
        ],
        borderWidth: 2
      }
    ]
  };

  return (
    <Pie data={data} options={{
      scales: {
        y: {
          display: false // Hides the y-axis
        }
      },
      plugins: {
        legend: {
          position: 'right' // Sets the legend position to 'right'
        }
      },
      maintainAspectRatio: false
    }} redraw/>
  );
};

export default ChartJSLine;
