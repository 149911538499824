import Card from "@mui/material/Card";
import { Modal } from "@mui/material";
import PropTypes from "prop-types";
import MDTypography from "components/MDBase/MDTypography";
import Divider from "@mui/material/Divider";
import MDButton from "components/MDBase/MDButton";
import MDBox from "components/MDBase/MDBox";
import { useNavigate } from "react-router-dom";

function LogoutModal({open, handleClose}){
  const navigate = useNavigate()

  const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    outline: "none",
    boxShadow: 24,
    padding: "2rem 1rem 1rem 1rem",
  };

  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Card sx={style}>
        <MDTypography sx={{display:"flex", justifyContent:"center"}} id="modal-modal-title" variant="h4" component="h2">
          Confirm Logout
        </MDTypography>
        <Divider />
        <MDTypography sx={{display:"flex", justifyContent:"center", mt: 1}} id="modal-modal-description" variant="body2">
          Are you sure you want to log out?
        </MDTypography>
        <MDBox mt={3} sx={{display:"flex", justifyContent:"flex-end"}}>
          <MDButton variant="outlined" color="text" sx={{marginRight:"1rem"}} onClick={handleClose}>Cancel</MDButton>
          <MDButton variant="gradient" color="error" onClick={()=>{navigate("/logout")}}>OK</MDButton>
        </MDBox>
      </Card>
    </Modal>
  )
}

LogoutModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
}
export default LogoutModal
