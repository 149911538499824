import { effect, signal } from "@preact/signals-react";
import { getSelfSites } from "util/APIHelper";

export const allSites = signal([]);
export const selectedSites = signal([]);

initAllSites();
initSelectedSites();

export async function initAllSites() {
  const a = JSON.parse(localStorage.getItem("all_sites"));
  if (a) {
    allSites.value = a
  } else {
    const selfSites = await getSelfSites();
    if (selfSites.success) {
      allSites.value = selfSites.content;
    }
  }
}
export function initSelectedSites() {
  const a = JSON.parse(localStorage.getItem("all_sites"));
  const s = JSON.parse(localStorage.getItem("selected_sites"));
  if (s) {
    selectedSites.value = s
  } else {
    if (a) {
      selectedSites.value = a
    } else selectedSites.value = []
  }
}

export const resetSites = () => {
  allSites.value = [];
  selectedSites.value = [];
}
effect(()=>{
  localStorage.setItem('all_sites', JSON.stringify(allSites.value));
})

effect(()=>{
  localStorage.setItem('selected_sites', JSON.stringify(selectedSites.value));
})