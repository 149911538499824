// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// MD React components
import MDBox from "components/MDBase/MDBox";
import MDTypography from "components/MDBase/MDTypography";

// MD React examples
import PageLayout from "components/MDComponents/LayoutContainers/PageLayout";

// MD React context
import { useMaterialUIController } from "context/md";
import illustration from "assets/images/illustrations/sisba.jpg";
import { ReactComponent as Logo } from "assets/images/logo-full.svg";
import { ReactComponent as LogoDark } from "assets/images/logo-full-dark.svg";

function LoginLayout({ header, title, description, children }) {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { background, white } }) =>
            darkMode ? background.default : white.main,
        }}
      >
        <Grid item xs={11} sm={8} md={6} lg={4} xl={3} sx={{ mx: "auto" }}>
          <MDBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            {
              darkMode?(
                <LogoDark style={{padding: "1rem 1rem 3rem 1rem"}} />
              ):(
                <Logo style={{padding:"1rem 1rem 3rem 1rem"}}/>
              )
            }
            <MDBox py={3} px={3} textAlign="center">
              {!header ? (
                <>
                  <MDBox mb={1} textAlign="center">
                    <MDTypography variant="h4" fontWeight="bold">
                      {title}
                    </MDTypography>
                  </MDBox>
                  <MDTypography variant="body2" color="text">
                    {description}
                  </MDTypography>
                </>
              ) : (
                header
              )}
            </MDBox>
            <MDBox p={3}>{children}</MDBox>
          </MDBox>
        </Grid>
        <Grid item xs={12} lg={8}>
          <MDBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${illustration})`,

              backgroundSize: "cover"}}
          />
        </Grid>
      </Grid>
    </PageLayout>
  );
}

// Setting default values for the props of IllustrationLayout
LoginLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
};

// Typechecking props for the IllustrationLayout
LoginLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
};

export default LoginLayout;