import {epochToTimestamp} from "util/UtilHelper";

export const processTimelineData = (data) => {
  return data.map((item, index) => {
    let color = "secondary"
    let icon = "close"

    switch (item.status) {
      case 0: color = "secondary"; icon = "play_circle_outlined"; break;
      case 1: color = "info"; icon = "download"; break;
      case 2: color = "info"; icon = "file_download_done"; break;
      case 4: color = "info"; icon = "system_update_alt"; break;
      case 8: color = "success"; icon = "check"; break;
    }

    if (item.status>=50) {
      color = "error";
      icon = "warning"
      if (item.status === 99) {
        icon = "close"
      }
    }

    return {
      color: color,
      icon: icon,
      title: item.description,
      dateTime: epochToTimestamp(item.createdUtc),
      description: "",
      lastItem: (index === data.length - 1)
    }
  });
}